import React from "react";
import { Typography } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { CommonModal, StyledButton } from "../../../../shared/commonStyles";
import { Project } from "./DecarbonizationProjectList";

interface ProjectDetailModalProps {
  isOpen: boolean;
  onClose: () => void;
  project: Project | null;
}

const ProjectDetailModal: React.FC<ProjectDetailModalProps> = ({
  isOpen,
  onClose,
  project,
}) => {
  if (!project) return null;

  const percentReduction = project.percent_reduction?.toFixed(1) || "0.0";

  const handleModalClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <>
      <style>
        {`
          .drawer-modal .ant-modal {
            position: absolute !important;
            top: 0 !important;
            right: 0 !important;
            margin: 0 !important;
            padding: 0 !important;
            height: 100vh !important;
            width: 600px !important;
          }
          .drawer-modal .ant-modal-content {
            height: 100vh !important;
            border-radius: 0 !important;
            display: flex !important;
            flex-direction: column !important;
          }
          .drawer-modal .ant-modal-body {
            flex: 1 !important;
            overflow-y: auto !important;
            background: #2D273F !important;
            padding: 20px 24px !important;
          }
          .drawer-modal .ant-modal-header {
            padding: 16px 24px !important;
            background: #362E4B !important;
            border-bottom: none !important;
          }
          .drawer-modal .ant-modal-title {
            color: white !important;
            font-size: 18px !important;
          }
          .drawer-modal .ant-modal-close {
            color: white !important;
          }
          .drawer-modal .ant-modal-footer {
            border-top: none !important;
            background: #2D273F !important;
            padding: 16px 24px !important;
          }
          @keyframes slideIn {
            from {
              transform: translateX(100%);
            }
            to {
              transform: translateX(0);
            }
          }
          .drawer-modal .ant-modal-wrap {
            right: 0 !important;
          }
          .drawer-modal .ant-modal {
            animation: slideIn 0.3s forwards !important;
          }
        `}
      </style>
      <CommonModal
        title="View More"
        visible={isOpen}
        onCancel={onClose}
        maskClosable={true}
        onClick={handleModalClick}
        footer={[
          <StyledButton
            key="close"
            type="custom"
            onClick={onClose}
            style={{
              backgroundColor: "#A68DFB",
              color: "white",
              width: "120px",
            }}
          >
            Close
          </StyledButton>,
        ]}
        closeIcon={<CloseOutlined />}
        destroyOnClose
        keyboard={false}
        style={{ position: "absolute", right: 0, top: 0 }}
        wrapClassName="drawer-modal"
      >
        <div>
          <Typography.Title
            level={2}
            style={{
              color: "white",
              marginBottom: "24px",
              fontSize: "28px",
              fontWeight: "normal",
            }}
          >
            {project.id} / {project.project_name}
          </Typography.Title>
          <div style={{ marginBottom: "24px" }}>
            <Typography.Text
              strong
              style={{
                color: "white",
                display: "block",
                fontSize: "18px",
                marginBottom: "8px",
              }}
            >
              Project Description:
            </Typography.Text>
            <Typography.Text style={{ color: "white", fontSize: "16px" }}>
              {project.project_description}
            </Typography.Text>
          </div>
          <div style={{ marginBottom: "24px" }}>
            <Typography.Text
              strong
              style={{
                color: "white",
                display: "block",
                fontSize: "18px",
                marginBottom: "8px",
              }}
            >
              Location:
            </Typography.Text>
            <Typography.Text style={{ color: "white", fontSize: "16px" }}>
              {project.location}
            </Typography.Text>
          </div>
          <div style={{ marginBottom: "24px" }}>
            <Typography.Text
              strong
              style={{
                color: "white",
                display: "block",
                fontSize: "18px",
                marginBottom: "8px",
              }}
            >
              Decarbonization Category:
            </Typography.Text>
            <Typography.Text style={{ color: "white", fontSize: "16px" }}>
              {project.category}
            </Typography.Text>
          </div>
          <div style={{ marginBottom: "24px" }}>
            <Typography.Text
              strong
              style={{
                color: "white",
                display: "block",
                fontSize: "18px",
                marginBottom: "8px",
              }}
            >
              Project Cost (USD):
            </Typography.Text>
            <Typography.Text style={{ color: "white", fontSize: "16px" }}>
              $
              {project.project_cost.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography.Text>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "24px",
              marginBottom: "24px",
            }}
          >
            <div>
              <Typography.Text
                strong
                style={{
                  color: "white",
                  display: "block",
                  fontSize: "18px",
                  marginBottom: "8px",
                }}
              >
                Baseline Year:
              </Typography.Text>
              <Typography.Text style={{ color: "white", fontSize: "16px" }}>
                {project.baseline_year}
              </Typography.Text>
            </div>
            <div>
              <Typography.Text
                strong
                style={{
                  color: "white",
                  display: "block",
                  fontSize: "18px",
                  marginBottom: "8px",
                }}
              >
                Target Year:
              </Typography.Text>
              <Typography.Text style={{ color: "white", fontSize: "16px" }}>
                {project.target_year}
              </Typography.Text>
            </div>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "24px",
              marginBottom: "24px",
            }}
          >
            <div>
              <Typography.Text
                strong
                style={{
                  color: "white",
                  display: "block",
                  fontSize: "18px",
                  marginBottom: "8px",
                }}
              >
                Current Emissions:
              </Typography.Text>
              <Typography.Text style={{ color: "white", fontSize: "16px" }}>
                {project.current_emmisions}
              </Typography.Text>
            </div>
            <div>
              <Typography.Text
                strong
                style={{
                  color: "white",
                  display: "block",
                  fontSize: "18px",
                  marginBottom: "8px",
                }}
              >
                Target Emissions:
              </Typography.Text>
              <Typography.Text style={{ color: "white", fontSize: "16px" }}>
                {project.target_emmisions}
              </Typography.Text>
            </div>
          </div>
          <div>
            <Typography.Text
              strong
              style={{
                color: "#A68DFB",
                display: "block",
                fontSize: "18px",
                marginBottom: "8px",
              }}
            >
              Percent Reduction:
            </Typography.Text>
            <Typography.Text
              style={{
                color: "white",
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              {percentReduction}%
            </Typography.Text>
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default ProjectDetailModal;
