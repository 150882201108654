import React, { useState } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  message,
  InputNumber,
  DatePicker,
  Select,
  Radio,
  Checkbox,
  Button,
} from "antd";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { DecoupledDocumentEditor } from "ckeditor5-build-classic";
import { FormWrapper, CommonModal } from "../../../shared/commonStyles";
import { getMessage } from "../../../pages/dataSheetsPage/dataSheetDocumentsPage/datasheetUtils";
import { YearMonthDayFormat } from "../../../shared/constants";
import {
  insertData,
  doConversion,
  calculateAirDistance,
  updateData,
} from "../../../services/dataSheet";
import { areValidFieldsByRules } from "../../../pages/dataSheetsPage/dataSheetDocumentsPage/DataSheetDocumentsFunctions";

interface InputProps {
  name: string;
  rules: {
    required: boolean;
    message: string;
  }[];
  style: {
    width: string;
  };
  valuePropName?: string;
}

interface CreateRecordModalProps {
  visible: boolean;
  dataSheet: DataSheet;
  metaDataRecords?: MetaDataRecord[];
  identifier?: string;
  identifierConnectedValues: any;
  initialData?: DataSheetRecord;
  onClose: () => void;
  onFinish: () => void;
}

const CreateRecordModal = ({
  visible,
  onClose,
  onFinish,
  dataSheet,
  metaDataRecords,
  identifier,
  identifierConnectedValues,
  initialData,
}: CreateRecordModalProps) => {
  const [submiting, setSubmiting] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isMetadataFieldsDisabled, setIsMetadataFieldsDisabled] =
    useState(false);
  const [disabledFields, setDisabledFields] = useState<string[]>([]);
  const [form] = Form.useForm();
  const getDateValue = (value: any) => {
    let newValue = value;
    if (typeof value === "object") {
      newValue = moment(value.$date);
    } else {
      newValue = value ? moment(value) : undefined;
    }
    return newValue;
  };
  const onChangeSelector = (value: string, item: any) => {
    const entityNamesList = dataSheet.sheet_schema.filter(
      (v) => v.metadata_entity_name
    );
    const metadataEntityName = item.metadata_entity_name;

    const selectedMetadataObject = metaDataRecords?.[0][
      metaDataRecords[0]?.sheet_name
    ].find((v) => v[metadataEntityName] === value);

    let metadataFormItems = {};
    entityNamesList?.forEach((v) => {
      metadataFormItems = {
        ...metadataFormItems,
        [v.entity_name]:
          v.data_type === "date"
            ? getDateValue(selectedMetadataObject?.[v.metadata_entity_name])
            : selectedMetadataObject?.[v.metadata_entity_name],
      };
    });

    form.setFieldsValue({
      [item.entity_name]: value,
      ...metadataFormItems,
    });
    entityNamesList?.length && setIsMetadataFieldsDisabled(true);
  };

  const onClickSaveRecord = async () => {
    try {
      setSubmiting(true);
      setBtnLoading(true);

      const values = await form.validateFields();

      const sheetName = dataSheet.sheet_name;
      const sheetId = dataSheet._id.$oid;
      const sheetSchema = dataSheet.sheet_schema;
      const isValid =
        dataSheet?.validationRules &&
        areValidFieldsByRules(
          dataSheet?.validationRules,
          values,
          form.setFields
        );
      if (!isValid && dataSheet?.validationRules) {
        return;
      }
      await Promise.all(
        sheetSchema?.map(async (item) => {
          if (!item.unit_id) return;
          values[item.entity_name + "_actual_unit_id"] =
            values[item.entity_name + "_unit_id"];
          values[item.entity_name + "_actual_value"] = values[item.entity_name];
          values[item.entity_name] = await doConversion(
            values[item.entity_name],
            values[item.entity_name + "_unit_id"],
            item.unit_id,
            item.customConversions
          );
          values[item.entity_name + "_unit_id"] = item.unit_id;
        })
      );

      if (!initialData) {
        const payload = {
          sheet_id: sheetId,
          sheet_name: sheetName,
          documents: [values],
          source: "Manual Entry",
        };
        const response = await insertData(payload);

        getMessage(response, dataSheet);
      } else if (initialData) {
        const payload = { ...initialData, [sheetName]: values };
        const response = await updateData(payload);
        response.messages && getMessage(response, dataSheet);
      }

      await onFinish();
    } catch (error) {
      message.error("Error while saving record!");
      console.log("Error while saving record!", error);
    } finally {
      setSubmiting(false);
      setBtnLoading(false);
      onClose();
    }
  };

  const isAirDistanceSheet = () => {
    const requiresFields = [
      "destination_city",
      "origination_city",
      "distance",
      "routing",
    ];
    const entityNames =
      dataSheet.sheet_schema &&
      dataSheet.sheet_schema.map((v) => v.entity_name);

    // Ensure that all required fields are present and check for either type_of_journey or journey_type
    const hasRequiredFields = requiresFields.every((field) =>
      entityNames.includes(field)
    );
    const hasJourneyField =
      entityNames.includes("type_of_journey") ||
      entityNames.includes("journey_type");

    return hasRequiredFields && hasJourneyField;
  };

  const handleFormChange = async () => {
    if (!isAirDistanceSheet()) {
      return;
    }
    const fieldsValues = form.getFieldsValue(true);
    const isAllRequiredFiedsFilled =
      !!fieldsValues.destination_city &&
      !!fieldsValues.origination_city &&
      (!!fieldsValues.type_of_journey || !!fieldsValues.journey_type);
    if (isAllRequiredFiedsFilled) {
      const payload: any = {
        origin: fieldsValues.origination_city,
        destination: fieldsValues.destination_city,
        trip_type: fieldsValues.type_of_journey || fieldsValues.journey_type,
      };
      !!fieldsValues.transit_city &&
        (payload.transit = fieldsValues.transit_city);

      calculateAirDistance(payload).then((result) => {
        form.setFieldsValue({
          routing: result.routing,
          distance: result.distance,
          distance_unit_id: "km",
        });
        result.distance
          ? setDisabledFields(["routing", "distance"])
          : setDisabledFields([]);
      });
    } else {
      setDisabledFields([]);
    }
  };
  const dynamicFormInputs = (dataSheet: DataSheet) => {
    try {
      console.log("dataSheet.sheet_schema", dataSheet.sheet_schema);
      return dataSheet.sheet_schema
        .filter((item) => item?.input_type != "expression")
        .map((item, index) => {
          let inputNode = null;
          let inputProps: InputProps = {
            name: item.entity_name,
            rules: [
              { required: item.required, message: `This field in required!` },
            ],
            style: { width: "100%" },
          };
          switch (item?.input_type) {
            case "number":
              inputNode = (
                <InputNumber
                  disabled={
                    (!!item.metadata_entity_name && isMetadataFieldsDisabled) ||
                    disabledFields.some((v) => v == item.entity_name)
                  }
                  lang="en"
                  style={{ width: 150 }}
                />
              );
              break;
            case "date":
              inputNode = (
                <DatePicker
                  disabled={
                    !!item.metadata_entity_name && isMetadataFieldsDisabled
                  }
                  style={{ width: 150 }}
                  format={YearMonthDayFormat}
                />
              );
              break;
            case "string":
              inputNode = (
                <Input
                  disabled={
                    (!!item.metadata_entity_name && isMetadataFieldsDisabled) ||
                    disabledFields.some((v) => v == item.entity_name)
                  }
                />
              );
              break;
            case "dropdown":
              inputNode = (
                <Select
                  disabled={
                    (!!item.metadata_entity_name && isMetadataFieldsDisabled) ||
                    disabledFields.some((v) => v == item.entity_name)
                  }
                  style={{ width: 150 }}
                >
                  {item?.options?.map((item: any) => (
                    <Select.Option key={item?.value} value={item?.value}>
                      {item?.label}
                    </Select.Option>
                  ))}
                </Select>
              );
              break;
            case "radio":
              inputNode = (
                <Radio.Group
                  disabled={
                    !!item.metadata_entity_name && isMetadataFieldsDisabled
                  }
                >
                  {item?.options?.map((item: any) => {
                    return (
                      <Radio key={item?.value} value={item?.value}>
                        {item?.label}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              );
              break;
            case "checkbox":
              if (item?.is_checkbox_group) {
                inputNode = (
                  <Checkbox.Group
                    disabled={
                      !!item.metadata_entity_name && isMetadataFieldsDisabled
                    }
                  >
                    <Row>
                      {item?.options?.map((item: any, index: number) => {
                        return (
                          <Col xs={24} sm={24} md={24} lg={24} key={index}>
                            <Checkbox key={item?.value} value={item?.value}>
                              {item?.label}
                            </Checkbox>
                          </Col>
                        );
                      })}
                    </Row>
                  </Checkbox.Group>
                );
              } else {
                inputProps = { ...inputProps, valuePropName: "checked" };
                inputNode = (
                  <Checkbox
                    disabled={
                      !!item.metadata_entity_name && isMetadataFieldsDisabled
                    }
                  >
                    {item.display_name}
                  </Checkbox>
                );
              }
              break;
            case "long-answer":
              inputNode = (
                <Input.TextArea
                  disabled={
                    !!item.metadata_entity_name && isMetadataFieldsDisabled
                  }
                  style={{ height: 100 }}
                />
              );
              break;
            case "rich-text":
              inputNode = (
                <div className="ckSmallHeight ">
                  <CKEditor
                    editor={DecoupledDocumentEditor}
                    onReady={(editor: any) => {
                      if (editor?.ui) {
                        editor.ui.view.editable.element.parentElement.insertBefore(
                          editor.ui.view.toolbar.element,
                          editor.ui.view.editable.element
                        );
                      }
                    }}
                    onChange={(event: any, editor: any) => {
                      const data = editor.getData();
                      form.setFieldsValue({
                        [item.entity_name]: data,
                      });
                    }}
                    data={
                      initialData?.[dataSheet.sheet_name][item.entity_name] ||
                      ""
                    }
                    disabled={
                      !!item.metadata_entity_name && isMetadataFieldsDisabled
                    }
                    style={{ height: 100 }}
                  />
                </div>
              );
              break;
            default:
              inputNode = null;
              break;
          }
          if (
            !!item.metadata_entity_name &&
            item.metadata_entity_name === identifier
          ) {
            inputNode = (
              <Select
                showSearch
                options={identifierConnectedValues?.map((item: any) => ({
                  value: item,
                  label: item,
                }))}
                onChange={(value) => onChangeSelector(value, item)}
              />
            );
          }

          return (
            <Row gutter={[16, 16]} key={index}>
              <Col span={item.unit_id ? 14 : 24}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  label={item.display_name}
                  {...inputProps}
                >
                  {inputNode}
                </Form.Item>
              </Col>
              {item.unit_id && (
                <Col span={10}>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    label={item.display_name + " Unit"}
                    name={item.entity_name + "_unit_id"}
                    initialValue={item.unit_id}
                    rules={[
                      { required: true, message: "Please select a Unit!" },
                    ]}
                  >
                    <Select
                      placeholder="Unit"
                      disabled={disabledFields.some(
                        (v) => v == item.entity_name
                      )}
                    >
                      {item.convertable_units?.map(
                        (unit: { unit: string; name: string }, i: number) => {
                          return (
                            <Select.Option
                              key={unit.unit + i}
                              value={unit.unit}
                            >
                              {unit.name}
                            </Select.Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Row>
          );
        });
    } catch (error) {
      return null;
    }
  };

  return (
    <CommonModal
      title={initialData ? "Update record" : "Add new record"}
      visible={visible}
      onCancel={onClose}
      width={600}
      maskClosable={false}
      onOk={onClickSaveRecord}
      confirmLoading={submiting}
      destroyOnClose
      centered
      footer={[
        <Button key={"Cancel"} ghost onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key={"Add record"}
          type="primary"
          loading={btnLoading}
          onClick={onClickSaveRecord}
        >
          {initialData ? "Update record" : "Add record"}
        </Button>,
      ]}
    >
      <FormWrapper>
        <Form
          form={form}
          initialValues={initialData?.[dataSheet.sheet_name]}
          onFieldsChange={() => handleFormChange()}
        >
          {dynamicFormInputs(dataSheet)}
        </Form>
      </FormWrapper>
    </CommonModal>
  );
};

export default CreateRecordModal;
