import React, { useEffect, useState } from "react";
import { Input, Form } from "antd";
import { EditOutlined, DownloadOutlined } from "@ant-design/icons";
import {
  FormWrap,
  FormWrapper,
  StyledButton,
  CommonTable,
  CommonModal,
} from "../../../shared/commonStyles";
import moment from "moment";
import { YearMonthDayFormat } from "../../../shared/constants";
import { API } from "aws-amplify";
import * as queries from "../../../graphql/queries";
import { useNavigate } from "react-router-dom";
import {
  fetchRecordData,
  listMetaDataRecords,
  makeQueryMetadata,
  massApproveWithDeduplication,
  modifyMetadataStatus,
  updateData,
} from "../../../services/dataSheet";
import { Row, Col, Typography, message, Spin } from "antd";
import { useCheckRolesPermissions } from "../../../hooks/useCheckRolesPermissions";
import surveyKpiService from "../../../services/SurveyKpiService";
import { encodeUrlName } from "../../../shared/commonFunctions";
import { EditableCell } from "../../dataSheetsPage/dataSheetDocumentsPage/Components/EditableCell";
const { Title, Text } = Typography;

const step4 = ({
  files,
  isMetadata,
  onClickPrevious,
  onClickNext,
  onRestartUploading,
  initialValues,
  dataSheet,
  state,
  downloadFileHandler,
  surveyData,
  uploadStatuses = [],
}) => {
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const navigate = useNavigate();
  const { checkRolesPermission } = useCheckRolesPermissions();
  const [editingKey, setEditingKey] = useState("");
  const [form] = Form.useForm();

  const handleSave = async (record) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex((item) => record.key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setDataSource(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setDataSource(newData);
        setEditingKey("");
      }
      const payload = {
        sheet_id: { $oid: initialValues?.sheet_id },
        sheet_name: dataSheet.sheet_name,
        _id: { $oid: record.key },
        [dataSheet.sheet_name]: { ...newData[index] },
      };

      await updateData(payload);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleEdit = (key) => {
    const record = dataSource.find((item) => item.key === key);
    const formValues = {};

    if (record) {
      dataSheet.sheet_schema.forEach((schema) => {
        const value = record[schema.entity_name];
        if (schema.input_type === "date") {
          if (value?.$date) {
            formValues[schema.entity_name] = moment(value.$date);
          } else if (value) {
            formValues[schema.entity_name] = moment(value);
          }
        } else {
          formValues[schema.entity_name] = value;
          if (schema.unit_id) {
            formValues[schema.entity_name + "_unit_id"] =
              record[schema.entity_name + "_unit_id"] || schema.unit_id;
          }
        }
      });
    }

    form.setFieldsValue(formValues);
    setEditingKey(key);
  };

  const isEditing = (record) => record.key === editingKey;

  const isLessSevenDays = () => {
    const sevenDaysInMillis = 604800000;
    const startDate = new Date(initialValues?.createDate);
    const currentDate = new Date();
    const timeDiff = currentDate - startDate;
    return timeDiff <= sevenDaysInMillis;
  };

  const fetchRecords = async (pag) => {
    const currentPagination = pag || pagination;
    try {
      setLoading(true);
      const requestPayload = {
        sheet_id: initialValues?.sheet_id,
        skip: (currentPagination.current - 1) * currentPagination.pageSize,
        limit: currentPagination.pageSize,
        record_types: {
          include: [],
          exclude: ["archived"],
        },
        filter: { import_id: initialValues?._id?.$oid },
      };
      const sheetRecords = await fetchRecordData(requestPayload);
      const _dataSheetRecords = sheetRecords.data.map((record) => {
        const obj = record[record?.sheet_name];
        obj.key = record._id.$oid;
        return obj;
      });
      setDataSource(_dataSheetRecords);
      setPagination({
        ...currentPagination,
        total: sheetRecords.filteredCount,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchMetadata = async () => {
    try {
      setLoading(true);
      const payload = makeQueryMetadata({
        sheetId: dataSheet?._id?.$oid,
        pagination: { current: 1, pageSize: 10 },
        filter: { import_id: initialValues?._id?.$oid },
        recordTypes: {
          include: [],
          exclude: ["archived"],
        },
      });
      const metaDataDocuments = await listMetaDataRecords(payload);
      const records = metaDataDocuments?.data?.map((record) => {
        const obj = record[record?.sheet_name];
        obj.key = obj.id;
        return obj;
      });
      if (records?.length) {
        setDataSource(records);
        setPagination((prev) => ({
          ...prev,
          total: metaDataDocuments.filteredCount,
        }));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (state?.isOcrUpload && initialValues?.sheet_id) {
      fetchRecords();
    } else if (initialValues?.sheet_id && initialValues?._id) {
      isMetadata ? fetchMetadata() : fetchRecords();
    }
  }, [initialValues?.sheet_id, initialValues?._id, isMetadata]);

  const generateDynamicColumns = () => {
    const columns = [];
    if (dataSheet?.sheet_schema?.length) {
      dataSheet.sheet_schema.forEach((schemaField) => {
        columns.push({
          title: schemaField.display_name,
          dataIndex: schemaField.entity_name,
          editable: true,
          inputType: schemaField.input_type || "string",
          onCell: (record) => ({
            record,
            dataIndex: schemaField.entity_name,
            title: schemaField.display_name,
            editing: isEditing(record),
            inputType: schemaField.input_type || "string",
            schemaField: {
              ...schemaField,
              data_type: schemaField.data_type || "string",
            },
          }),
          render: (text, record) => {
            if (schemaField.input_type === "date" && text) {
              const date = text.$date
                ? moment.utc(text.$date)
                : moment.utc(text);
              return date.isValid() ? date.format(YearMonthDayFormat) : text;
            }
            return record[schemaField.entity_name + "_actual_value"] || text;
          },
        });

        if (schemaField.unit_id) {
          columns.push({
            title: schemaField.display_name + " Unit",
            dataIndex: schemaField.entity_name + "_unit_id",
            width: "100px",
            editable: true,
            onCell: (record) => ({
              record,
              dataIndex: schemaField.entity_name + "_unit_id",
              title: schemaField.display_name + " Unit",
              editing: isEditing(record),
              inputType: "string",
              schemaField: {
                ...schemaField,
                data_type: "string",
              },
            }),
            render: (text, record) => {
              return (
                record[schemaField.entity_name + "_actual_unit_id"] ||
                text ||
                schemaField.unit_id
              );
            },
          });
        }
      });

      columns.push({
        title: "Action",
        dataIndex: "action",
        render: (_, record) => {
          const editable = isEditing(record);
          return editable ? (
            <div style={{ display: "flex", gap: "8px" }}>
              <StyledButton type="default" onClick={() => setEditingKey("")}>
                Cancel
              </StyledButton>
              <StyledButton type="default" onClick={() => handleSave(record)}>
                Update
              </StyledButton>
            </div>
          ) : (
            <StyledButton type="default" onClick={() => handleEdit(record.key)}>
              <EditOutlined />
              Edit
            </StyledButton>
          );
        },
      });
    }
    return columns;
  };

  const mapStatusStyle = (bgColor) => ({
    padding: "15px 15px 0px 15px",
    background: bgColor,
    borderRadius: "4px",
    marginBottom: "30px",
  });

  const mapInfo = {
    success: {
      title: "All the fields were mapped successfully!",
      message: "Review the uploaded data to make sure all the data is correct",
    },
    missing: {
      title: "Some fields are missing!",
      message: "Review the uploaded data and fix the errors",
    },
  };

  const generateMapStatus = (status) => (
    <div style={mapStatusStyle(status === "success" ? "#4F456B" : "#D95050")}>
      <Row>
        <Title level={5}>{mapInfo[status].title}</Title>
      </Row>
      <Row>
        <p className="color-grey">{mapInfo[status].message}</p>
      </Row>
    </div>
  );

  const goToKpis = () => {
    localStorage.setItem(
      "pendingKpiImport",
      JSON.stringify({
        dataSheetId: state?.dataSheet?._id?.$oid,
        actionType: "data_imported",
        kpiId: state?.kpiData?._id?.$oid,
      })
    );
    navigate(`/data/pending-kpis/`);
  };

  const updateStatus = async (status) => {
    try {
      setConfirmLoading(true);
      const payload = {
        ...initialValues,
        import_type: state?.action
          ? initialValues?.import_type
          : files[0]?.type,
        import_status: status,
      };
      const response = await API.graphql({
        query: queries["datalake"],
        variables: {
          request_type: "update_data_import",
          request_payload: JSON.stringify(payload),
        },
      });
      if (response) {
        const payloadGeneral = {
          filters: { import_id: initialValues?._id?.$oid },
          column: "import_status",
          value: status,
        };
        const payloadMetadata = {
          importId: initialValues?._id?.$oid,
          importStatus: status,
          sheetId: initialValues?.sheet_id,
        };
        const resp = isMetadata
          ? await modifyMetadataStatus(payloadMetadata)
          : await massApproveWithDeduplication(payloadGeneral);
        if (initialValues?.surveyKpiId) {
          const kpiPayload = {
            filter: { _id: { $oid: initialValues?.surveyKpiId } },
          };
          const kpi = await surveyKpiService.surveyKpisList(kpiPayload);
          if (kpi?.length) {
            const updatedPayload = {
              _id: kpi[0]?._id,
              files: kpi[0]?.files?.map((f) =>
                f?.importId === initialValues?._id?.$oid
                  ? { ...f, importStatus: status }
                  : f
              ),
            };
            await surveyKpiService.updateSurveyKpi([updatedPayload]);
          }
        }
        if (resp) {
          if (state?.action === "review" && state?.type !== "pendingKpi") {
            message.success(`Data ${status.toLowerCase()} successfully !`);
            navigate("/data/import-data");
          } else {
            if (
              state?.type === "dataSheet" ||
              state?.type === "survey" ||
              state?.type === "pendingKpi"
            ) {
              navigate(
                state?.type === "dataSheet"
                  ? `/data/data-sheets/${
                      initialValues?.sheet_id
                    }/${encodeUrlName(
                      dataSheet?.sheet_name?.replace("/", " ")
                    )}/documents`
                  : state?.type === "pendingKpi"
                  ? goToKpis()
                  : `/management/collect-data/${surveyData?.id}/${encodeUrlName(
                      surveyData?.name.replace("/", " ")
                    )}/view-survey`,
                {
                  state: {
                    messageType: "submitted",
                    actionType: "data_imported",
                    currentStep: state?.currentStep,
                    ...(state?.taskDeploymentId && {
                      taskDeploymentId: state?.taskDeploymentId,
                    }),
                    ...(state?.defaultMetaDataRecord && {
                      metadataRecordId: state?.defaultMetaDataRecord.id,
                    }),
                  },
                }
              );
            } else {
              onClickNext();
            }
          }
        }
      }
    } catch {
      setConfirmLoading(false);
    } finally {
      setConfirmLoading(false);
    }
  };

  const downloadHandler = () => {
    downloadFileHandler();
  };

  const handleStatusUpdate = (status) => {
    let description = "";
    if (status === "APPROVED") {
      description = "Are you sure you want to approve this import?";
    } else if (status === "REJECTED") {
      description = "Are you sure you want to reject this import?";
    }
    setRowData({ status: status, description: description });
    setShowConfirmationModal(true);
  };

  const reviewLater = () => {
    if (
      state?.type === "dataSheet" ||
      state?.type === "survey" ||
      state?.type === "pendingKpi"
    ) {
      navigate(
        state?.type === "dataSheet"
          ? `/data/data-sheets/${initialValues?.sheet_id}/${encodeUrlName(
              dataSheet?.sheet_name?.replace("/", " ")
            )}/documents`
          : state?.type === "pendingKpi"
          ? navigate(`/data/pending-kpis`, {
              state: {
                dataSheetId: state?.dataSheet?._id?.$oid,
                actionType: "data_imported",
                kpiId: state?.kpiData?._id?.$oid,
              },
            })
          : `/management/collect-data/${surveyData?.id}/${encodeUrlName(
              surveyData?.name.replace("/", " ")
            )}/view-survey`,
        {
          state: {
            messageType: "review_later",
            actionType: "data_imported",
            currentStep: state?.currentStep,
            ...(state?.taskDeploymentId && {
              taskDeploymentId: state?.taskDeploymentId,
            }),
            ...(state?.defaultMetaDataRecord && {
              metadataRecordId: state?.defaultMetaDataRecord.id,
            }),
          },
        }
      );
    } else {
      navigate("/data/import-data");
    }
  };

  const handleTableChange = (newPagination) => {
    isMetadata ? setPagination(newPagination) : fetchRecords(newPagination);
  };

  return (
    <>
      {showConfirmationModal && (
        <CommonModal
          title={"Confirmation"}
          visible={showConfirmationModal}
          okText="Yes"
          onOk={() => updateStatus(rowData?.status)}
          onCancel={() => setShowConfirmationModal(false)}
          confirmLoading={confirmLoading}
          centered
        >
          <Text>{rowData?.description}</Text>
        </CommonModal>
      )}
      <FormWrap
        width="100%"
        style={{
          backgroundColor: "#2D273F",
          borderRadius: "4px",
          marginTop: "50px",
        }}
      >
        <FormWrapper style={{ marginTop: "20px" }} className="dataReviewTable">
          <Title level={3} style={{ fontWeight: "600" }}>
            Data Review
          </Title>
          <p className="color-grey font-16">
            Review the uploaded data. Click to edit
          </p>

          {generateMapStatus("success")}

          {uploadStatuses.length > 0 && (
            <div style={{ marginBottom: 20 }}>
              <Title level={5} style={{ color: "#fff" }}>
                Upload Summary
              </Title>
              {uploadStatuses.map((item, idx) => (
                <p
                  key={idx}
                  style={{
                    color: item.status === "Failed" ? "red" : "green",
                    marginBottom: 4,
                  }}
                >
                  {item.fileName} - {item.status}
                  {item.error && ` (${item.error})`}
                </p>
              ))}
            </div>
          )}

          <Spin spinning={loading}>
            <Form form={form} component={false}>
              <CommonTable
                style={{ marginBottom: "30px" }}
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                rowKey={(record) => record.key}
                columns={generateDynamicColumns()}
                dataSource={dataSource}
                pagination={pagination}
                scroll={{ x: 400 }}
                onChange={handleTableChange}
              />
            </Form>
          </Spin>

          {state?.action === "review" && (
            <Row justify="end">
              <Col style={{ textAlign: "end" }}>
                <StyledButton
                  type="custom"
                  hoverbgcolor="transparent"
                  bgcolor="transparent"
                  bordercolor="transparent"
                  color="white"
                  onClick={downloadHandler}
                  style={{ marginRight: "0px" }}
                >
                  <DownloadOutlined />
                  <span>Download file</span>
                </StyledButton>
              </Col>
            </Row>
          )}

          <Row justify="space-between">
            <Col>
              {state?.action === "review" ? (
                <>
                  <StyledButton
                    className="margin-5"
                    type="custom"
                    onClick={() => handleStatusUpdate("REJECTED")}
                    disabled={initialValues?.import_status !== "PENDING_REVIEW"}
                  >
                    <span>Decline</span>
                  </StyledButton>
                  <StyledButton
                    className="margin-5"
                    type="custom"
                    onClick={() => handleStatusUpdate("APPROVED")}
                    disabled={initialValues?.import_status !== "PENDING_REVIEW"}
                  >
                    <span>Approve</span>
                  </StyledButton>
                </>
              ) : (
                <>
                  <StyledButton
                    type="custom"
                    onClick={onClickPrevious}
                    hoverbgcolor="transparent"
                    bgcolor="transparent"
                    bordercolor="transparent"
                    color="white"
                    style={{ padding: "11px 11px 11px 0px" }}
                  >
                    <span>Go back</span>
                  </StyledButton>
                  <StyledButton
                    className="margin-5"
                    type="custom"
                    onClick={() =>
                      state?.type === "survey" &&
                      !checkRolesPermission(["role:admin"])
                        ? reviewLater()
                        : handleStatusUpdate("APPROVED")
                    }
                    disabled={
                      state?.action &&
                      state?.action !== "kpiMapping" &&
                      (initialValues?.import_status !== "PENDING_REVIEW" ||
                        initialValues?.import_status !== "REJECTED")
                    }
                  >
                    <span>Confirm and Save</span>
                  </StyledButton>
                </>
              )}
              {initialValues?.import_status !== "APPROVED" &&
              initialValues?.import_status !== "REJECTED"
                ? !state?.type === "survey" ||
                  (checkRolesPermission(["role:admin"]) && (
                    <StyledButton
                      type="custom"
                      hoverbgcolor="transparent"
                      bgcolor="transparent"
                      bordercolor="transparent"
                      color="white"
                      onClick={reviewLater}
                    >
                      <span>Review Later</span>
                    </StyledButton>
                  ))
                : isLessSevenDays() && (
                    <StyledButton
                      type="custom"
                      hoverbgcolor="transparent"
                      bgcolor="transparent"
                      bordercolor="transparent"
                      color="white"
                      onClick={() => onRestartUploading(true)}
                    >
                      <span>Undo</span>
                    </StyledButton>
                  )}
            </Col>
            {!state?.action && (
              <Col>
                <StyledButton
                  type="custom"
                  hoverbgcolor="transparent"
                  bgcolor="transparent"
                  bordercolor="transparent"
                  color="white"
                  onClick={() => onRestartUploading()}
                >
                  <span>Clear and Start Over</span>
                </StyledButton>
              </Col>
            )}
          </Row>
        </FormWrapper>
      </FormWrap>
    </>
  );
};

export default step4;
