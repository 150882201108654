import { Col, Row, Typography, message } from "antd";
import React, { useState, useEffect, useMemo } from "react";
import {
  SearchOutlined,
  FilterOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { StyledButton, CommonTable } from "../../../../shared/commonStyles";
import ProjectDetailModal from "./ProjectDetailModal";

const { Title } = Typography;

// TypeScript interfaces
export interface Location {
  id: string;
  name: string;
}

export interface ProjectMetadata {
  total: number;
  page: number;
  limit: number;
  total_pages: number;
}

export interface Project {
  id: string;
  project_name: string;
  project_description: string;
  location: string;
  category: string;
  emmisions_factor: number;
  project_cost: number;
  baseline_year: number;
  target_year: number;
  current_emmisions: number;
  target_emmisions: number;
  percent_reduction: number;
  is_active?: boolean;
}

interface ProjectResponse {
  metadata: ProjectMetadata;
  data: Project[];
}

interface ApiRequest {
  arguments: {
    request_type: string;
    request_payload: any;
  };
}

interface ColumnConfig {
  key: keyof Project;
  title: string;
  width: string;
  formatter?: (value: any) => React.ReactNode;
}

const ProjectList: React.FC = () => {
  // State declarations remain the same
  const [loading, setLoading] = useState<boolean>(false);
  const [projectList, setProjectList] = useState<Project[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filterLocation, setFilterLocation] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalProjects, setTotalProjects] = useState<number>(0);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState<boolean>(false);

  // Column configuration
  const columnConfigs: ColumnConfig[] = useMemo(() => [
    {
      key: 'project_name',
      title: 'PROJECT NAME',
      width: '20%'
    },
    {
      key: 'location',
      title: 'LOCATION',
      width: '20%',
      formatter: (locationId: string) => {
        const location = locations.find(loc => loc.id === locationId);
        return location?.name || locationId;
      }
    },
    {
      key: 'category',
      title: 'CATEGORY',
      width: '15%'
    },
    {
      key: 'emmisions_factor',
      title: 'EMISSIONS FACTOR',
      width: '15%',
      formatter: (value: number) => value.toString()
    }
  ], [locations]);

  // Dynamic column generation
  const generateColumns = () => {
    const dynamicColumns = columnConfigs.map(config => ({
      title: config.title,
      dataIndex: config.key,
      key: config.key,
      width: config.width,
      render: config.formatter ? 
        (value: any) => config.formatter!(value) : 
        undefined
    }));

    // Add static columns for activation and actions
    return [
      ...dynamicColumns,
      {
        title: "PROJECT ACTIVATION",
        dataIndex: "is_active",
        key: "is_active",
        width: "15%",
        render: (isActive: boolean, record: Project) => (
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
            <div
              style={{
                width: "48px",
                height: "24px",
                backgroundColor: isActive ? "#A68DFB" : "#2D273F",
                borderRadius: "12px",
                position: "relative",
                cursor: "pointer",
                transition: "background-color 0.2s",
              }}
              onClick={() => handleActivationToggle(record.id, isActive)}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  position: "absolute",
                  top: "2px",
                  left: isActive ? "26px" : "2px",
                  transition: "left 0.2s",
                }}
              />
            </div>
          </div>
        ),
      },
      {
        title: "Actions",
        key: "actions",
        width: "20%",
        className: "actions-column",
        render: (_: any, record: Project) => (
          <div style={{ display: "flex", gap: "8px", justifyContent: "flex-start" }}>
            <StyledButton
              type="custom"
              style={{
                backgroundColor: "#A68DFB",
                margin: 0,
                padding: "4px 16px",
                height: "32px",
                borderRadius: "4px",
              }}
              onClick={() => {
                setSelectedProject(record);
                setIsViewModalOpen(true);
              }}
            >
              View More
            </StyledButton>
            <StyledButton
              type="custom"
              style={{
                backgroundColor: "#2D273F",
                margin: 0,
                padding: "4px 16px",
                height: "32px",
                borderRadius: "4px",
              }}
              onClick={() => {
                setSelectedProject(record);
                setIsEditModalVisible(true);
              }}
            >
              Edit
            </StyledButton>
            <StyledButton
              type="custom"
              style={{
                backgroundColor: "#2D273F",
                margin: 0,
                padding: "4px 16px",
                height: "32px",
                borderRadius: "4px",
              }}
              onClick={() => {
                setSelectedProject(record);
                setIsDeleteModalVisible(true);
              }}
            >
              Delete
            </StyledButton>
          </div>
        ),
      },
    ];
  };

  const fetchLocations = async (): Promise<void> => {
    try {
      const request: ApiRequest = {
        arguments: {
          request_type: "get_decarb_location",
          request_payload: {}
        }
      };
      
      // Simulated API call
      const response = [{
        id: "a90e1c48-6a14-4906-a8e5-554bb9c9a088",
        name: "London"
      }];
      
      setLocations(response);
    } catch (error) {
      message.error("Error loading locations");
    }
  };

  const loadData = async (): Promise<void> => {
    try {
      setLoading(true);
      
      const request: ApiRequest = {
        arguments: {
          request_type: "get_projects",
          request_payload: {
            page: currentPage,
            limit: pageSize,
            ...(searchQuery && { project_name: searchQuery }),
            ...(filterLocation && { location_ids: [filterLocation] })
          }
        }
      };

      // Simulated API response
      const response: ProjectResponse = {
        metadata: {
          total: 1,
          page: currentPage,
          limit: pageSize,
          total_pages: 1
        },
        data: [{
          id: "6772aec8b04063825e7b40a7",
          project_name: "Decarbonization Initiative 3",
          project_description: "A project to reduce emissions using optimized fuel and electricity consumption.",
          location: "609b0d4d-c57f-4017-aca7-ad0ede384f04",
          category: "fd2cbfb0-b141-473f-a79a-47c211eaaaad",
          emmisions_factor: 0.0,
          project_cost: 1000000.0,
          baseline_year: 2022,
          target_year: 2025,
          current_emmisions: 0.0,
          target_emmisions: 0.0,
          percent_reduction: 0.0,
          is_active: true
        }]
      };

      setProjectList(response.data);
      setTotalProjects(response.metadata.total);
    } catch (error) {
      message.error("Error loading projects");
    } finally {
      setLoading(false);
    }
  };

  // Rest of the component implementation remains the same
  const handleModalClose = () => {
    setIsViewModalOpen(false);
    setSelectedProject(null);
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  useEffect(() => {
    loadData();
  }, [currentPage, pageSize, searchQuery, filterLocation]);

  const handleActivationToggle = async (
    projectId: string,
    currentStatus: boolean
  ): Promise<void> => {
    try {
      setLoading(true);
      const updatedProjects = projectList.map((project: Project) =>
        project.id === projectId
          ? { ...project, is_active: !currentStatus }
          : project
      );
      setProjectList(updatedProjects);
      message.success("Project status updated successfully");
    } catch (error) {
      message.error("Failed to update project status");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (projectId: string): Promise<void> => {
    try {
      setLoading(true);
      const updatedProjects = projectList.filter(
        (project: Project) => project.id !== projectId
      );
      setProjectList(updatedProjects);
      setTotalProjects(updatedProjects.length);
      message.success("Project deleted successfully");
    } catch (error) {
      message.error("Failed to delete project");
    } finally {
      setLoading(false);
      setIsDeleteModalVisible(false);
    }
  };

  return (
    <div>
      <Title level={2} style={{ color: "white", marginBottom: "4px" }}>
        Project Dashboard
      </Title>
      <div style={{ marginBottom: "24px" }}>
        <Typography.Text style={{ color: "#666" }}>
          Create a new project, or manage ongoing projects here.
        </Typography.Text>
      </div>
      <div style={{ display: "flex", gap: "16px", marginBottom: "24px" }}>
        <div style={{ display: "flex", gap: "16px", flex: 1 }}>
          <div style={{
            display: "flex",
            alignItems: "center",
            padding: "8px 16px",
            border: "1px solid #3A3C5A",
            borderRadius: "4px",
            backgroundColor: "transparent",
            color: "white",
            width: "300px",
          }}>
            <SearchOutlined style={{ marginRight: "8px" }} />
            <input
              placeholder="Search Project by Name"
              style={{
                background: "transparent",
                border: "none",
                color: "white",
                width: "100%",
              }}
              value={searchQuery}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchQuery(e.target.value)
              }
            />
          </div>
          <select
            style={{
              display: "flex",
              alignItems: "center",
              padding: "8px 16px",
              border: "1px solid #3A3C5A",
              borderRadius: "4px",
              backgroundColor: "transparent",
              color: "white",
              cursor: "pointer",
            }}
            value={filterLocation}
            onChange={(e) => setFilterLocation(e.target.value)}
          >
            <option value="">All Locations</option>
            {locations.map((location) => (
              <option key={location.id} value={location.id}>
                {location.name}
              </option>
            ))}
          </select>
        </div>
        <StyledButton
          type="custom"
          style={{
            backgroundColor: "#A68DFB",
            margin: 0,
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
          onClick={() => {
            setSelectedProject(null);
            setIsEditModalVisible(true);
          }}
        >
          <PlusOutlined />
          Add New Project
        </StyledButton>
      </div>
      <style>
        {`
          .actions-column {
            background-color: rgba(26, 22, 37, 0.9) !important;
            padding-left: 12px !important;
            position: relative;
          }
          .actions-column::before {
            content: '';
            position: absolute;
            left: -8px;
            top: 0;
            bottom: 0;
            width: 8px;
            background-color: #1A1625;
          }
          .ant-table-cell {
            background-color: #2D273F !important;
          }
          .ant-table-thead > tr > th {
            background-color: #2D273F !important;
            border-bottom: none !important;
          }
          .ant-table-tbody > tr > td {
            border-bottom: 1px solid #1A1625 !important;
          }
          .ant-table-tbody > tr:hover > td:not(.actions-column) {
            background-color: #3A334D !important;
          }
        `}
      </style>
      <CommonTable
        rowKey={(record: Project) => record.id}
        loading={loading}
        columns={generateColumns()}
        dataSource={projectList}
        pagination={{
          total: totalProjects,
          pageSize: pageSize,
          current: currentPage,
          onChange: (page: number, size: number) => {
            setCurrentPage(page);
            setPageSize(size);
          },
          style: {
            marginTop: "24px",
            textAlign: "right",
          },
        }}
      />

      <ProjectDetailModal
        isOpen={isViewModalOpen}
        onClose={handleModalClose}
        project={selectedProject}
      />
    </div>
  );
};

export default ProjectList;