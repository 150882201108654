import React, { useState, useEffect } from "react";
import moment from "moment";
import { Upload, message, Spin, Row, Col, Button, Typography } from "antd";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  downloadDataSheetFileHandler,
  downloadDataSheetFileHandlerNew,
} from "../../../shared/commonFunctions";
import {
  fileUploadSize,
  fileUploadTypes,
  YearMonthDayFormat,
} from "../../../shared/constants";
import {
  StyledButton,
  CommonModal,
  CommonTable,
} from "../../../shared/commonStyles";
import {
  listDataSheetFilesNew,
  uploadDataSheetFileNew,
  removeDatasheetFile,
  updateData,
} from "../../../services/dataSheet";

const { Link } = Typography;
const { Dragger } = Upload;

const SheetFilesModal = ({
  visible,
  onClose,
  rowData,
  uploadEnabled = true,
  handleSave,
}) => {
  const [submiting, setSubmiting] = useState(false);
  const [files, setFiles] = useState(rowData.files || []);
  const [uploadedFilesList, setUploadedFilesList] = useState([]);
  const [loaderUploadedFilesList, setLoaderUploadedFilesList] = useState(false);
  const [refreshUploadedFiles, setRefreshUploadedFiles] = useState(1);
  const [recordData, setRecordData] = useState(rowData);

  const loadDataLakeFiles = async (fileIds) => {
    setLoaderUploadedFilesList(true);
    try {
      // Call listDataSheetFilesNew for each file ID individually
      const filePromises = fileIds.map(async (fileId) => {
        try {
          const response = await listDataSheetFilesNew([fileId]);
          const filePaths = JSON.parse(response);
          // If we got a valid path back, return the file info
          if (filePaths && filePaths.length > 0) {
            return {
              path: filePaths[0],
              id: fileId
            };
          }
          return null;
        } catch (error) {
          console.log(`Error fetching file ${fileId}:`, error);
          return null;
        }
      });

      // Wait for all requests to complete and filter out nulls (deleted files)
      const results = await Promise.all(filePromises);
      const validFiles = results.filter(file => file !== null);
      setUploadedFilesList(validFiles);
    } catch (error) {
      console.log("Error while fetching data sheet files", error);
    } finally {
      setLoaderUploadedFilesList(false);
    }
  };

  useEffect(() => {
    if (recordData?.filesList?.length) {
      loadDataLakeFiles(recordData.filesList);
    }
  }, [recordData]);

  const onClickSaveBtn = async () => {
    setSubmiting(true);
    try {
      if (files && files.length) {
        recordData.files = files;
      }
      handleSave(recordData, "files");
      onClose();
    } catch (error) {
      console.log("Something went wrong while uploading files!", error);
    } finally {
      setSubmiting(false);
    }
  };

  const uploadFile = async (file) => {
    try {
      const CurrentDate = moment().unix();
      const fileName =
        CurrentDate + "-" + file.name?.replace(/[/\\$#@?%*:|"<>]/g, "");
      setLoaderUploadedFilesList(true);
      const signedURLNew = await uploadDataSheetFileNew(fileName);
      const parsedsignedURLNew = JSON.parse(signedURLNew);

      if (parsedsignedURLNew?.url) {
        const arrayBufferData = await file.arrayBuffer();
        if (arrayBufferData) {
          const blob = new Blob([new Uint8Array(arrayBufferData)], {
            type: file.type,
          });
          const result = await fetch(parsedsignedURLNew?.url, {
            method: "PUT",
            body: blob,
          });
          if (result?.status === 200) {
            message.success("File uploaded Successfully!");
            setRefreshUploadedFiles((prev) => prev + 1);
            const newRecord = {
              ...recordData,
              filesList: [
                ...(recordData?.filesList ?? []),
                parsedsignedURLNew?.file_id,
              ],
              operation: "File Upload",
            };
            setRecordData(newRecord);
            newRecord?._id?.$oid && (await updateData(newRecord));
          }
        }
      }
    } catch (error) {
      console.log("Something went wrong while uploading file", error);
      message.error("Something went wrong while uploading file.");
    } finally {
      setLoaderUploadedFilesList(false);
    }
  };

  const beforeUpload = async (file) => {
    await uploadFile(file);
    const filename = file?.name;
    const fileSize = file?.size;
    if (fileSize > fileUploadSize) {
      message.error(`File upload size is larger than 25MB`);
      return;
    }

    const ext = filename && filename.split(".")?.pop()?.toLowerCase();
    if (fileUploadTypes.includes(ext)) {
      setFiles((prev) => [...prev, file]);
    } else {
      message.error(`${file?.name} is not a valid file type`);
    }

    return false;
  };

  const onRemoveFileFromListHandler = (file) => {
    const filesList = files.filter((v) => v.uid !== file.uid);
    setFiles(filesList);
  };

  const downloadFileHandler = async (filePath, dataId) => {
    try {
      setLoaderUploadedFilesList(true);
      await downloadDataSheetFileHandlerNew(filePath, dataId);
    } catch (error) {
      console.log("Error while downloading file", error);
    } finally {
      setLoaderUploadedFilesList(false);
    }
  };

  const handleDeleteFile = async (fileInfo) => {
    setLoaderUploadedFilesList(true);
    try {
      const response = await removeDatasheetFile(fileInfo.id);
      
      // API returns "1" as string for successful deletion
      if (response === "1") {
        message.success("File deleted successfully");
        
        // Update recordData filesList
        const newFilesList = recordData.filesList.filter(id => id !== fileInfo.id);
        const newRecord = {
          ...recordData,
          filesList: newFilesList,
          operation: "File Delete"
        };
        setRecordData(newRecord);
        
        // Update uploadedFilesList
        setUploadedFilesList(prev => prev.filter(file => file.id !== fileInfo.id));

        // Update record in database if needed
        if (newRecord?._id?.$oid) {
          await updateData(newRecord);
        }
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error("Error deleting file:", error);
      message.error(error instanceof Error ? error.message : "Failed to delete file");
    } finally {
      setLoaderUploadedFilesList(false);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <>
          {record.path && record.path?.split("/").pop().split(".").slice(0, -1).join(".")}
        </>
      ),
    },   
    {
      title: "Date Uploaded",
      dataIndex: "date",
      key: "date",
      render: (_, record) => {
        const dateUpload = parseInt(record.path?.split("/").pop().split("-")[0]);
        return <>{moment.unix(dateUpload).format(YearMonthDayFormat)} </>;
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <>
          <VerticalAlignBottomOutlined
            style={{ marginRight: "5px" }}
            onClick={() =>
              downloadFileHandler(record.path, rowData?.["_id"]?.["$oid"])
            }
          />
          <Link
            onClick={() =>
              downloadFileHandler(record.path, rowData?.["_id"]?.["$oid"])
            }
          >
            Download File
          </Link>
        </>
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      render: (_, record) => (
        <>
          <Button
            type="link"
            onClick={() => handleDeleteFile(record)}
            disabled={!uploadEnabled || rowData.isLocked}
          >
             <DeleteOutlined /> 
          </Button>
        </>
      ),
    },
  ];

  return (
    <CommonModal
      title={"Data Sheet Files"}
      visible={visible}
      onCancel={onClose}
      width={1000}
      maskClosable={false}
      onOk={onClickSaveBtn}
      okText="Save"
      confirmLoading={submiting}
      destroyOnClose
      centered
      footer={
        <>
          <Button ghost onClick={() => onClose()}>
            Cancel
          </Button>
          {uploadEnabled && (
            <Button
              type="primary"
              loading={submiting}
              onClick={() => onClickSaveBtn()}
            >
              Submit
            </Button>
          )}
        </>
      }
    >
      <div className="file-upload" style={{ padding: 10 }}>
        <Spin spinning={loaderUploadedFilesList}>
          {uploadedFilesList && uploadedFilesList?.length > 0 ? (
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              style={{ height: "fit-content" }}
            >
              <CommonTable dataSource={uploadedFilesList} columns={columns} />
              {uploadEnabled && (
                <Upload
                  customRequest={({ file, onSuccess }) => {
                    onSuccess("ok");
                  }}
                  beforeUpload={beforeUpload}
                  fileList={[]}
                  multiple={false}
                  onRemove={onRemoveFileFromListHandler}
                >
                  <StyledButton
                    style={{ marginTop: 10 }}
                    type="custom"
                    icon={<UploadOutlined />}
                  >
                    Upload
                  </StyledButton>
                </Upload>
              )}
            </Col>
          ) : (
            "No files were found for the selected record"
          )}
          {!uploadedFilesList?.length &&
            !loaderUploadedFilesList &&
            uploadEnabled && (
              <Row>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  style={{ height: "fit-content" }}
                >
                  <Dragger
                    customRequest={({ file, onSuccess }) => {
                      onSuccess("ok");
                    }}
                    beforeUpload={beforeUpload}
                    fileList={[]}
                    className="upload-list-inline"
                    multiple={false}
                    onRemove={onRemoveFileFromListHandler}
                    style={{ background: "#625D75", width: "100%" }}
                  >
                    <>
                      <p className="ant-upload-text color-white">
                        Drag & Drop a file into this area
                      </p>
                      <p className="ant-upload-hint color-white">
                        Supports: XLSX, XLS, CSV, PDF, JPEG, PNG, GIF, TXT, PPT,
                        PPTX
                      </p>
                      <p className="color-white">
                        or{" "}
                        <StyledButton
                          style={{ marginTop: 10 }}
                          type="custom"
                          icon={<UploadOutlined />}
                        >
                          Upload
                        </StyledButton>
                      </p>
                    </>
                  </Dragger>
                </Col>
              </Row>
            )}
        </Spin>
      </div>
    </CommonModal>
  );
};

export default SheetFilesModal;