import React, { useState } from "react";
import { Button, Typography, List } from "antd";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { downloadDataSheetFileHandlerNew } from "../../../shared/commonFunctions";
import { CommonModal } from "../../../shared/commonStyles";

const { Text } = Typography;

const SheetAttachedModal = ({ visible, onClose, rowData, uploadEnabled = true }) => {
  const [submitting, setSubmitting] = useState(false);

  const onClickSaveBtn = () => {
    console.log("Save button clicked");
  };

  return (
    <CommonModal
      title={"User Entered Notes"}
      visible={visible}
      onCancel={onClose}
      width={1000}
      maskClosable={false}
      onOk={onClickSaveBtn}
      okText="Save"
      confirmLoading={submitting}
      destroyOnClose
      centered
      footer={null}
    >
      <div>
        <h3 style={{color: "#fff"}}>Notes</h3>
        {rowData?.notes && rowData.notes.length > 0 ? (
          <List
            dataSource={rowData.notes}
            renderItem={(note) => (
              <List.Item>
                <Text>{note.text}</Text>
                <div style={{ marginLeft: "auto" }}>
                  <Text type="secondary">{note.date}</Text>
                </div>
              </List.Item>
            )}
          />
        ) : (
          <p>No notes available.</p>
        )}
      </div>
    </CommonModal>
  );
};

export default SheetAttachedModal;
