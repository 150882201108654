import { Menu } from "antd";
import React, { useState } from "react";
import styles from "../../../components/Header.module.scss";
import ProjectList from "./project-list/DecarbonizationProjectList";
import { useNavigate } from "react-router";

export const DecarbonizationPage = () => {
  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = useState("charts");
  const handleClick = (e: any) => {
    if (e.key == "charts") {
      setSelectedKey("charts");
    }
    if (e.key == "projectlist") {
      setSelectedKey("projectlist");
    }
    if (e.key == "settings") {
      navigate("/carbon/decarbonization-tool/settings");
      setSelectedKey("settings");
    }
    // You can handle the click event here, e.g., navigate to a different page or update the state
  };
  // Define the navigation items based on the environment variable
  const navs = [
    {
      key: "charts",
      label: "Charts",
    },
    {
      key: "projectlist",
      label: "Project List",
    },
    {
      key: "settings",
      label: "Settings",
    },
  ];
  return (
    <>
      <Menu
        style={{ flex: "auto", minWidth: 0, marginBottom: "20px" }}
        mode="horizontal"
        theme="dark"
        items={navs}
        selectedKeys={[selectedKey]}
        className={styles.headerNav}
        onClick={handleClick}
      />
      {selectedKey === "charts" && <>charts</>}
      {selectedKey === "projectlist" && <ProjectList/>}
      {selectedKey === "settings" && <>settings</>}
    </>
  );
};
