import "./amplify.css";
import "antd/dist/antd.css";
import "./App.css";
import "./App.less";

import * as moment from "moment-timezone";
import * as queries from "./graphql/queries";

import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import Amplify, { API, Auth, Hub } from "aws-amplify";
import { Image, notification } from "antd";
import React, { useEffect, useState } from "react";

import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import { ApolloProvider } from "react-apollo";
import { AuthProvider } from "../src/contexts/AuthContext";
import { Common } from "./components/CustomSignIn/Common";
import { InMemoryCache } from "apollo-cache-inmemory";
import { Login } from "./components/CustomSignIn/Login";
import { PasswordlessSignin } from "./components/CustomSignIn/PasswordlessSignin";
import { PasswordlessSignup } from "./components/CustomSignIn/PasswordlessSignup";
import { PasswordlessVerify } from "./components/CustomSignIn/PasswordlessVerify";
import { Rehydrated } from "aws-appsync-react";
import { SiteLayout } from "./components/Layout/SiteLayout";
import { applyFormatters } from "mobx-log";
import awsmobile from "./aws-exports";
import { createBrowserHistory } from "history";
import loginLogo from "./hydrus_login_logo.png";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useLocation } from "react-router-dom";

Amplify.configure(awsmobile);

const OAUTH_DOMAIN_ENV =
  process.env.REACT_APP_OAUTH_DOMAIN_ENV ||
  "hydrus-dev.auth.us-east-1.amazoncognito.com";
const REDIRECT_SIGNIN_ENV =
  process.env.REACT_APP_REDIRECT_SIGNIN_ENV ||
  "2892878ef6a146f6afc1f8b3443454ad.vfs.cloud9.us-east-1.amazonaws.com/home";
const REDIRECT_SIGNOUT_ENV =
  process.env.REACT_APP_REDIRECT_SIGNOUT_ENV ||
  "2892878ef6a146f6afc1f8b3443454ad.vfs.cloud9.us-east-1.amazonaws.com/";

const search = window.location.search;
const params = new URLSearchParams(search);
const provider = params.get("auth-provider");

// We're going to sign the user up
const email_id = params.get("email_id");
const group_id = params.get("group_id");
const action = params.get("action");
const code_answer = params.get("code");

const isPasswordlessSignIn =
  (action == "sign_up" && email_id !== null && group_id !== null) ||
  (action == "sign_in" && email_id !== null) ||
  (code_answer !== null && action == "verify");

const client = new AWSAppSyncClient(
  {
    disableOffline: true,
    url: awsmobile.aws_appsync_graphqlEndpoint,
    region: awsmobile.aws_appsync_region,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () =>
        (await Auth.currentSession()).getIdToken().getJwtToken(),
    },
  },
  { cache: new InMemoryCache() }
);

Hub.listen("auth", (data) => {
  if (data.payload.event === "signOut") {
    client.resetStore();
  }
});

moment.tz.setDefault("Etc/UTC");

applyFormatters();

const AppLayout = ({ location, children }) => (
  <AuthProvider>
    <SiteLayout location={location}>{children}</SiteLayout>
  </AuthProvider>
);

const App = ({ children }) => {
  const { authStatus, toSignUp, toSignIn } = useAuthenticator((context) => [
    context.authStatus,
  ]);
  const [formType, setFormType] = useState(null);
  const [loginRoute, setLoginRoute] = useState(null);
  const location = useLocation();
  const [isAuthConfigured, setIsAuthConfigured] = useState(false);

  async function configureAuth() {
    try {
      let signOutUrl = await fetchUrl("sso_signout_url"); // 'sso_signout_url' could be the attribute name in DynamoDB
      let signInUrl = await fetchUrl("sso_callback_url"); // 'sso_callback_url' could be the attribute name in DynamoDB
      if (!signOutUrl) {
        signOutUrl = REDIRECT_SIGNOUT_ENV;
      }
      if (!signInUrl) {
        signInUrl = REDIRECT_SIGNIN_ENV;
      }

      // Helper function to ensure URLs begin with "https://"
      const ensureHttps = (url) => {
        if (url && !(url.startsWith("http://") || url.startsWith("https://"))) {
          return "https://" + url;
        }
        return url;
      };
      const oauth = {
        domain: OAUTH_DOMAIN_ENV,
        scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
        redirectSignIn: ensureHttps(signInUrl),
        redirectSignOut: ensureHttps(signOutUrl),
        responseType: "code",
      };
      Auth.configure({
        oauth: oauth,
        region: awsmobile.aws_cognito_region,
        userPoolId: awsmobile.aws_user_pools_id,
        userPoolWebClientId: awsmobile.aws_user_pools_web_client_id,
      });

      setIsAuthConfigured(true);
    } catch (error) {
      console.error("Error configuring Auth module: ", error);
    }
  }

  async function fetchUrl(attributeName) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (!user) {
        console.error("No authenticated user");
        return;
      }

      const data = await Auth.currentSession();
      const group = data
        .getAccessToken()
        ["payload"]["cognito:groups"].find((element) =>
          element.includes("org:")
        );

      if (!group) {
        console.error("Group not found in token payload");
        return;
      }

      const response = await API.graphql({
        query: queries.getClientSettings,
        variables: { group },
      });
      return response?.data?.getClientSettings[attributeName];
    } catch (error) {
      console.error(`Error while fetching ${attributeName}`, error);
    }
  }

  // Single Sign On - Auto Redirect
  const onSingleSignIn = (provider) => {
    try {
      Auth.federatedSignIn({ provider });
    } catch (error) {
      console.log("Error while calling federatedSignIn");
    }
  };

  useEffect(() => {
    async function checkAndHandleAuth() {
      if (isAuthConfigured) {
        try {
          // Check if the user is already signed in
          await Auth.currentAuthenticatedUser();
  
          // User is authenticated, do nothing more
          console.log('User is already authenticated');
        } catch (e) {
          // User is not authenticated
          const search = window.location.search;
          const params = new URLSearchParams(search);
          const provider = params.get('auth-provider');
          if (provider !== null) {
            onSingleSignIn(provider);
          }
        }
      }
    }
  
    checkAndHandleAuth();
  }, [isAuthConfigured]);


  useEffect(() => {
    localStorage.getItem("isAuthenticated") &&
      JSON.parse(localStorage.getItem("isAuthenticated")) &&
      setLoginRoute("signIn");

    Hub.listen("auth", (data) => {
      if (data.payload.event === "signOut") {
        client.resetStore();
      } else if (
        data.payload.event === "signIn" &&
        authStatus !== "authenticated"
      ) {
        window.location.reload();
        localStorage.setItem("isAuthenticated", true);
      }
    });
  }, []);

  useEffect(() => {
    /*loadIntercom();*/
    handleLogin();
    configureAuth();
  }, []);

  const history = createBrowserHistory();
/*
  history.listen(() => {
    // Calls Intercom('update') on every page change
    updateIntercom();
  });
*/
  const handleFormType = (type) => {
    setFormType(type);
    if (type === "signIn") {
      toSignIn();
    }
    if (type === "signUp") {
      toSignUp();
    }
  };

  const handleLogin = async () => {
    Auth.currentUserInfo()
      .then((userInfo) => {
        const user = {
          ...(authStatus === "authenticated" && {
            app_id: process.env.REACT_APP_APP_ID_ENV || "",
            user_id: userInfo.attributes.sub,
            email: userInfo.attributes.email,
            name: userInfo.attributes.name,
          }),
          created_at: new Date().getTime() / 1000,
        };
        /*bootIntercom(user);*/
      })
      .catch((reason) => {
        /*bootIntercom({
          app_id: process.env.REACT_APP_APP_ID_ENV || "",
        });*/
      });
  };

  async function showLoginNotification() {
    const userInfo = await Auth.currentUserInfo();
    if (userInfo) {
      notification.success({
        message: `Logged In as ${userInfo.attributes.name}`,
      });
      localStorage.setItem("loggedIn", false);
    } else {
      localStorage.setItem("loggedIn", false);
    }
  }

  useEffect(() => {
    if (
      authStatus === "authenticated" &&
      (loginRoute === "signIn" || loginRoute === "autoSignIn")
    ) {
      localStorage.setItem("loggedIn", true);
      localStorage.setItem("showBanner", true);
      handleLogin();
      showLoginNotification();
      localStorage.removeItem("isAuthenticated");
    }
  }, [authStatus, loginRoute]);

  return (
    <>
      {(provider || (params.get('code') && params.get('state'))) && authStatus !== "authenticated" ? (
        <div></div>
      ) : (
        <>
          {authStatus !== "authenticated" ? (
            <div className="container">
              {isPasswordlessSignIn ? (
                <>
                  {action === "sign_up" &&
                    email_id !== null &&
                    group_id !== null && (
                      <div className="loginBox">
                        <Image src={loginLogo} preview={false} />
                        <PasswordlessSignup
                          email_id={email_id}
                          group_id={group_id}
                          awsmobile={awsmobile}
                        />
                      </div>
                    )}
                  {action === "sign_in" && email_id !== null && (
                    <div className="loginBox">
                      <Image src={loginLogo} preview={false} />
                      <PasswordlessSignin
                        email_id={email_id}
                        awsmobile={awsmobile}
                      />
                    </div>
                  )}
                  {code_answer !== null &&
                    action === "verify" &&
                    email_id !== null && (
                      <div className="loginBox">
                        <Image src={loginLogo} preview={false} />
                        <PasswordlessVerify
                          code_answer={code_answer}
                          awsmobile={awsmobile}
                        />
                      </div>
                    )}
                </>
              ) : (
                <div className="loginBox">
                  <Image src={loginLogo} preview={false} />
                  {formType === null ? (
                    <Common handleFormType={handleFormType} provider={provider} />
                  ) : (
                    <Login formType={formType} setLoginRoute={setLoginRoute} />
                  )}
                </div>
              )}
            </div>
          ) : (
            <ApolloProvider client={client}>
              <ApolloHooksProvider client={client}>
                <Rehydrated>
                  <AppLayout location={location}>{children}</AppLayout>
                </Rehydrated>
              </ApolloHooksProvider>
            </ApolloProvider>
          )}
        </>
      )}
    </>
  );
};
export default App;
