import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useBoolean } from "ahooks";
import {
  Spin,
  message,
  Row,
  Col,
  Form,
  Alert,
  Collapse,
  Typography,
  Table,
} from "antd";
import {
  calculateAirDistance,
  doConversion,
  getMetaDataRecords,
  getSheetById,
  insertData,
  makeQuery,
  massUpdateRecords,
  sendUserDenyReminder,
  sendUserApprovalNotification,
  uploadDataSheetFile,
  massApproveWithDeduplication,
  partialUpdateRecord,
  convertFiltersList,
  dataSheetFileWithAuditExport,
} from "../../../../services/dataSheet";
import { userInfoStore } from "../../../Carbon/UserInfoStore";
import SheetSchemaDatatable from "../Components/SheetSchemaDatatable";
import DataSheetAggregateAnalytics from "../Components/DataSheetAggregateAnalytics";
import { EditableCell } from "../Components/EditableCell";
import {
  areValidFieldsByRules,
  generateEditableCell,
  isAirDistanceSheet,
} from "../DataSheetDocumentsFunctions";
import { YearMonthDayFormat } from "../../../../shared/constants";
import { useLocation, useNavigate } from "react-router-dom";
import NewSheetFilesModal from "../../../viewSurveyForAdmin/Components/SheetFilesModal";
import SheetFilesModal from "../../../../components/DataSheetDocuments/SheetFilesModal/SheetFilesModal";
import SheetAttachedModal from "../../../viewSurveyForAdmin/Components/SheetAttachedModal";
import {
  fetchRecordData,
  updateData,
  addRecordNote,
  dataSheetFileExport,
} from "../../../../services/dataSheet";
import { fetchDataWithRetry } from "../../../../services/importData";
import {
  CommonTable,
  PageWrap,
  TableWrap,
  FormWrapper,
  StyledButton,
} from "../../../../shared/commonStyles";
import { ImportedDataModal } from "../Components/ImportedDataModal";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { AskPeriodModal } from "../Components/AskPeriodModal";
import { getUsersGroupList, getUsersList } from "../../../../services/users";
import { observer } from "mobx-react-lite";
import { useUserSettings } from "../../../../hooks/useUserSettings";
import { arrayMove } from "react-sortable-hoc";
import { getCompanyInfo } from "../../../../services/companyInfo";
import { checkPermission } from "../../../../services/permissions";
import { TableHeader } from "../Components/TableHeader";
import { TableColumns } from "../Components/TableColumns";
import { TableFooter } from "../Components/TableFooter";
import {
  FileOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { NotesDrawer } from "../Components/NotesDrawer";
import styles from "./DataSheetDocuments.module.scss";

import { getSurveyById } from "../../../../services/surveys";
import { getMessage } from "../datasheetUtils";
import { encodeUrlName } from "../../../../shared/commonFunctions";
import { useCheckRolesPermissions } from "../../../../hooks/useCheckRolesPermissions";
import { RichTextModal } from "../Components/RichTextModal";
import CreateRecordModal from "../../../../components/DataSheetDocuments/CreateRecordModal/CreateRecordModal";
import { ResizableHeaderCell } from "../Components/ResizableHeaderCell";

import { Auth } from "aws-amplify";

import surveyKpiService from "../../../../services/SurveyKpiService";
import { getDataSheetFields } from "../../../../services/dataSheet";

interface DataSheetDocumentsProps {
  dataSheet: DataSheet;
  loadingDataSheet: boolean;
  loadingDataSheetsList: boolean;
  loadDataSheet: (id: string) => void;
  setDataSheet: (dataSheet: DataSheet) => void;
  isDeleted: boolean;
  isPending: boolean;
  isDenied: boolean;
  isGeneral: boolean;
  dataSheetsList: DataSheet[];
}

interface SettingValue {
  dataSheetId: string;
  columns: ManageColumnData[];
  filters?: [];
  operatorType?: string;
  sorter?: any;
  rowSize?: number;
  conditionalFilters?: any[];
}

export const DataSheetDocuments = observer(function DataSheetDocuments({
  dataSheet,
  loadingDataSheet,
  loadingDataSheetsList,
  loadDataSheet,
  isDeleted,
  isPending,
  isDenied,
  isGeneral,
  dataSheetsList,
  setDataSheet,
}: DataSheetDocumentsProps) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { createUserSettings, updateUserSettings, getUserSettings } =
    useUserSettings();
  const [userSettings, setUserSettings] = useState<any>(null);
  const [dataSheetSettings, setDataSheetSettings] = useState<any>(null);
  const [pagination, setPagination] = useState<Pagination>({
    current: 1,
    pageSize: 10,
  });
  const [paginationExpand, setPaginationExpand] = useState<Pagination>({
    current: 1,
    pageSize: 10,
  });
  const [collapsedPanel, setCollapsedPanel] = useState<string | undefined>();
  const [selectedRows, setSelectedRows] = useState<DataSheetRecord[]>([]);
  const rowSelection = {
    selectedRowKeys: selectedRows.map((v) => v.key),
    hideSelectAll: false,
    onChange: (
      _selectedRowKeys: any,
      selectedItems: Array<DataSheetRecord>
    ) => {
      setSelectedRows(selectedItems);
    },
  };
  const [loadingSheetRecords, setLoadingSheetRecords] = useState(true);
  const { checkRolesPermission } = useCheckRolesPermissions();
  const [dataSheetRecords, setDataSheetRecords] = useState<DataSheetRecord[]>(
    []
  );
  const [refreshDataSheetRecords, setRefreshDataSheetRecords] = useState(1);
  const [editingKey, setEditingKey] = useState("");
  const [lastRevisionData, setLastRevisionData] = useState<any>(null);
  const [submitting, setSubmitting] = useState(false);
  const [showDataSheetFilesModal, setShowDataSheetFilesModal] = useState(false);
  const [showDataSheetAttachedModal, setShowDataSheetAttachedModal] =
    useState(false);
  const [showNewRecordFilesModal, setShowNewRecordFilesModal] = useState(false);
  const [rowData, setRowData] = useState<DataSheetRecord | null>(null);
  const [form] = Form.useForm();
  const [sorter, setSorter] = useState(null);
  const [isLatestRevision, setIsLatestRevision] = useState(false);
  const [editReason, setEditReason] = useState("");
  const [metaDataRecords, setMetaDataRecords] = useState<MetaDataRecord[]>();
  const [existingLocationsNumber, setExistingLocationsNumber] = useState(0);
  const [exportLoader, setExportLoader] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [showImportDataModal, setShowImportDataModal] = useState(false);
  const [newRecordKey, setNewRecordKey] = useState("");
  const [manageColumnData, setManageColumnData] = useState<ManageColumnData[]>(
    []
  );
  const [loadingFields, setLoadingFields] = useState<string[]>([]);
  const [disabledFields, setDisabledFields] = useState<string[]>([]);
  const [identifierConnectedValues, setIdentifierConnectedValues] =
    useState<(string | number)[]>();
  const [isRichTextModalVisible, setIsRichTextModalVisible] = useState(false);
  const [metadataSheet, setMetadataSheet] = useState<DataSheet>();
  const [countRecords, setCountRecords] = useState({
    archive: 0,
    pending: 0,
    denied: 0,
  });
  const [companyDetails, setCompanyDetails] = useState<CompanyDetails>(
    {} as CompanyDetails
  );
  interface CurrentFieldState {
    currentField: string;
    currentFieldName: string;
    value: string;
    recordId: string;
    currentTab: "comment" | "warning" | "estimate";
  }

  const [currentField, setCurrentField] = useState<CurrentFieldState>({
    currentField: "",
    currentFieldName: "",
    value: "",
    recordId: "",
    currentTab: "comment",
  });

  const [showUpdateRecordModal, setShowUpdateRecordModal] = useState(false);
  const [
    isFieldNotesDrawerShown,
    { setTrue: showFieldNotesDrawer, setFalse: hideFieldNotesDrawer },
  ] = useBoolean(false);

  const filtersLocalStorage =
    localStorage.getItem("filters")?.length &&
    JSON.parse(localStorage.getItem("filters") as string);

  const conditionalFiltersLocalStorage =
    localStorage.getItem("conditional_filters")?.length &&
    JSON.parse(localStorage.getItem("conditional_filters") as string);

  const identifierValuesLocalStorage =
    localStorage.getItem("identifiersValues")?.length &&
    JSON.parse(localStorage.getItem("identifiersValues") as string);

  const [advancedFilters] = useState(filtersLocalStorage);

  const [selectedConditionalFilters] = useState(conditionalFiltersLocalStorage);

  const [selectedIdentifiers] = useState(identifierValuesLocalStorage);

  filtersLocalStorage && localStorage.removeItem("filters");

  conditionalFiltersLocalStorage &&
    localStorage.removeItem("conditional_filters");

  identifierValuesLocalStorage && localStorage.removeItem("identifiersValues");

  const [search, setSearch] = useState<Search>({
    filters: filtersLocalStorage,
  } as Search);

  const [
    isShownCheckingModal,
    { setTrue: showCheckingModal, setFalse: hideCheckingModal },
  ] = useBoolean(false);

  const [
    isMissingDataSheetsLoading,
    {
      setTrue: hideMissingDataSheetsLoading,
      setFalse: showMissingDataSheetsLoading,
    },
  ] = useBoolean(false);

  const [isDataQualityScoreShown, { toggle: toggleDataQualityScore }] =
    useBoolean(
      localStorage?.getItem("isDataQualityScoreShown")
        ? JSON.parse(localStorage.getItem("isDataQualityScoreShown") as string)
        : false
    );

  const [sortRecords, setSortRecords] = useState<SortRecords>({
    type: "key",
    sortAlphabetically: true,
  });

  const [usersList, setUsersList] = useState<User[]>([]);
  const [groupsList, setGroupsList] = useState<UserGroup[]>([]);
  const [rowSize, setRowSize] = useState(75);
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [loadingExpandData, setLoadingExpandData] = useState(false);
  const [expandData, setExpandData] = useState<any>(null);
  const [allFields, setAllFields] = useState([]);

  const getUsers = async () => {
    const users = await getUsersList();
    setUsersList(users);
  };
  const getGroups = async () => {
    const groups = await getUsersGroupList();
    setGroupsList(groups);
  };

  const loadUserData = async () => {
    try {
      const username = userInfoStore.userID;
      const payload = {
        owner: username,
        settingName: "data_sheet_records_settings",
      };
      const response = await getUserSettings(payload);

      if (response) {
        const settingsData: SettingValue[] = JSON.parse(response.settingValue);
        const dataSheetSettings = settingsData?.find(
          (item) => item.dataSheetId === dataSheet?._id?.$oid
        );
        if (dataSheetSettings) {
          const columns = dataSheetSettings?.columns || [];
          const newColumns: ManageColumnData[] = dataSheet?.sheet_schema
            .map((item) => {
              const col = columns.find((v) => v.dataIndex == item.entity_name);
              const resultColumn = [];
              if (col) {
                resultColumn.push({ ...col });
              } else {
                resultColumn.push({
                  dataIndex: item.entity_name,
                  hide: false,
                  name: item.display_name,
                });
              }
              if (item.unit_id) {
                const colUnit = columns.find(
                  (v) => v.dataIndex == item.entity_name + "_unit_id"
                );
                if (colUnit) {
                  resultColumn.push({ ...colUnit });
                } else {
                  resultColumn.push({
                    dataIndex: item.entity_name + "_unit_id",
                    hide: false,
                    name: item.display_name + " Unit",
                  });
                }
              }
              return resultColumn;
            })
            ?.flat()
            .sort((a, b) => a.index! - b.index!);

          newColumns?.push({
            dataIndex: "surveyName",
            hide: true,
            name: "Survey Name",
          });
          newColumns?.push({
            dataIndex: "userName",
            hide: true,
            name: "Username",
          });
          newColumns?.push({
            dataIndex: "reviewStatus",
            hide: true,
            name: "Review Status",
          });
          
          if(newColumns){
            const hideUserKPI = (
              dataSheet.is_kpi_data === null || 
              dataSheet.is_kpi_data === undefined || 
              dataSheet.is_kpi_data === false
            )
            if(!hideUserKPI){
              let userEnteredKPIColumn = {
                dataIndex: "userEnteredKPIValue",
                hide: hideUserKPI,
                name: "User-entered KPI Value",
                tooltip: "This column displays KPI values entered by the user.", // Tooltip for more context
              };
              newColumns.push(userEnteredKPIColumn);
            }
          }
          const conditionalFilters = dataSheetSettings?.conditionalFilters
            ?.length
            ? dataSheetSettings?.conditionalFilters
            : dataSheetSettings?.filters?.length
            ? convertFiltersList(
                dataSheetSettings?.filters,
                dataSheet,
                dataSheetSettings?.operatorType || "$and"
              )
            : [];
          const sorter = dataSheetSettings?.sorter;
          const rowSize = dataSheetSettings?.rowSize;
          rowSize && setRowSize(rowSize);
          const newColumnsData = calculateProportionalWidthTable(newColumns);
          setManageColumnData(newColumnsData);
          setSearch({ conditionalFilters });
          setSorter(sorter);
          const newSorter: any = {
            sortAlphabetically: sorter?.order === "ascend",
            type: sorter?.field,
          };
          setSortRecords(newSorter);
          setDataSheetSettings({
            conditionalFilters,
            sorter,
            rowSize,
            columns,
          });
        }
      }
      setUserSettings(response);
    } catch (error) {
      console.log("Error while loading default metadata information!", error);
    }
  };

  useEffect(() => {
    getUsers();
    getGroups();
    dataSheet?.metadata_sheet_id && getMetadataSheet();
    userInfoStore.fetchUserInfo();
    loadCompanyInfo();
  }, []);

  const getMetadataSheet = async () => {
    const metadataSheetData: DataSheet = await getSheetById(
      dataSheet?.metadata_sheet_id
    );

    setMetadataSheet(metadataSheetData);
    const identifier = metadataSheetData?.sheet_schema.find(
      (value) => value.isIdentifier
    )?.entity_name;

    const metaData: MetaDataRecord[] = await getMetaDataRecords({
      filter: { sheet_id: dataSheet?.metadata_sheet_id },
    });

    const payload = {
      permissionType: "REFERENCE_DATA",
      itemId: dataSheet?.metadata_sheet_id,
    };

    const permissions = await checkPermission(payload);
    const filteredMeta = metaData?.[0]?.[metaData[0]?.sheet_name]?.filter(
      (v) =>
        permissions.find((item: string) => item === v.id) &&
        !v.archive &&
        v?.import_status !== "PENDING_REVIEW"
    );

    if (identifier && metaData) {
      const identifierConnectedValues = permissions.length
        ? filteredMeta.map((v) => v[identifier])
        : metaData?.[0]?.[metaData[0]?.sheet_name]
            .filter((v) => !v.archive && v?.import_status !== "PENDING_REVIEW")
            .map((v) => v[identifier]);
      setIdentifierConnectedValues(identifierConnectedValues);
    }
  };
  useEffect(() => {
    if (dataSheet) {
      userInfoStore.userID && loadUserData();
    }
  }, [userInfoStore.userID, dataSheet]);

  const loadCompanyInfo = async () => {
    try {
      const companyInfo = await getCompanyInfo();
      if (companyInfo) {
        const companyData = companyInfo["data"]
          ? JSON.parse(companyInfo["data"])
          : "";

        setCompanyDetails(companyData);
      }
    } catch (error) {
      console.log("Error while load company details", error);
      message.error("Error while load company details!");
    }
  };

  const calculateColumnWidth = (column: ManageColumnData) => {
    const values = dataSheetRecords
      ?.map((record) => record?.[dataSheet.sheet_name]?.[column.dataIndex])
      ?.filter((v) => v !== null && v !== undefined);
    if (values?.length) {
      const maxLength = Math.max(
        ...values?.map((value) =>
          value
            ? value.toString().length > 200
              ? 70
              : value.toString().length > 70
              ? 50
              : value.toString().length
            : 0
        )
      );
      const maxWidth = +(maxLength * 8).toFixed(0);
      return maxWidth;
    }
    return 0;
  };
  const calculateProportionalWidthTable = (columns: ManageColumnData[]) => {
    const newColumns = columns?.map((column) => {
      const newColumn = { ...column };
      newColumn?.dataIndex === "topic" && console.log("newColumn", newColumn);
      const maxWidth = !!newColumn.width
        ? newColumn.width
        : calculateColumnWidth(newColumn);
      newColumn?.dataIndex === "topic" && console.log("maxWidth", maxWidth);
      delete newColumn.width;
      return maxWidth && typeof maxWidth === "number"
        ? { ...newColumn, width: maxWidth }
        : newColumn;
    });
    return newColumns;
  };

  const handleSort = () => {
    const newSorter: any = {
      order: sortRecords.sortAlphabetically ? "ascend" : "descend",
      field: sortRecords.type,
    };
    setSorter(newSorter);
    if (!isPending && !isDenied && !isDeleted) {
      setDataSheetSettings((prev: any) => ({ ...prev, sorter: newSorter }));
      handleSaveSettings({
        columns: manageColumnData,
        conditionalFilters: search.conditionalFilters,
        sorter: newSorter,
        rowSize,
      });
    }
    loadDataSheetRecords({
      pagination,
      conditionalFilters: search.conditionalFilters,
      sorter: {
        ...sortRecords,
        ...newSorter,
      },
    });
  };

  const getFilteredData = () => {
    const identifiersFilters = selectedIdentifiers?.length
      ? [
          {
            column: identifierColumn,
            op: "$in",
            column_value: selectedIdentifiers,
          },
        ]
      : [];

    const allFilters = [
      ...(advancedFilters ?? []),
      ...(identifiersFilters ?? []),
    ];
    const allFiltersConditional = convertFiltersList(allFilters, dataSheet);
    loadDataSheetRecords({
      pagination,
      conditionalFilters: selectedConditionalFilters?.length
        ? selectedConditionalFilters
        : allFiltersConditional,
      sorter,
    });
    setSearch({
      conditionalFilters: selectedConditionalFilters?.length
        ? selectedConditionalFilters
        : allFiltersConditional,
    });
  };

  const importedDataAction = (data: any) => {
    if (data?.actionType === "data_imported") {
      setShowImportDataModal(true);
    }
  };

  useEffect(() => {
    importedDataAction(state);
  }, [state]);

  const getRecordTypes = () => {
    const includeTypes: RecordTypes[] = [];
    const excludeTypes: RecordTypes[] = [];
    if (isDeleted) {
      includeTypes.splice(0, 3, RecordTypes.ARCHIVED);
    } else {
      excludeTypes.push(RecordTypes.ARCHIVED, RecordTypes.IMPORTED_PENDING);
    }
    return {
      include: includeTypes,
      exclude: excludeTypes,
    };
  };
  const getRecordsFilter = () => {
    let recordsFilter = {};
    let sortFilter = {};

    if (isPending && isDenied) {
      // Case: Both Pending and Denied are true
      recordsFilter = {
        $or: [
          { review_status: RecordStatus.PENDING },
          { review_status: RecordStatus.DENIED },
          {
            review_status: RecordStatus.IN_PROGRESS,
            statusReasons: { $exists: true },
          }, // Include IN_PROGRESS only when statusReason exists
        ],
      };

      // Sort: Pending first, then Denied, then In Progress
      // sortFilter = { review_status: -1 }; // "PENDING" will come before "DENIED" and "IN_PROGRESS" alphabetically
    } else if (isPending) {
      // Case: Only Pending
      recordsFilter = {
        $or: [
          { data_approval_status: false },
          { review_status: RecordStatus.PENDING },
        ],
      };
    } else if (isDenied) {
      // Case: Only Denied
      recordsFilter = { review_status: RecordStatus.DENIED };
    } else {
      // Case: All flags are false - Show records where review_status does NOT exist or is APPROVED
      recordsFilter = {
        $or: [
          { review_status: { $exists: false } },
          { review_status: RecordStatus.PENDING },
          { review_status: RecordStatus.APPROVED },
          { review_status: RecordStatus.IN_PROGRESS },
          { review_status: RecordStatus.DENIED },
        ],
      };
    }

    return { filter: recordsFilter, sort: sortFilter };
  };

  const loadDataSheetRecords = async (params: SearchParams) => {
    try {
      setLoadingSheetRecords(true);
      const requestPayload = makeQuery(
        params,
        dataSheet,
        getRecordTypes(),
        getRecordsFilter()
      );
      const { pagination: _pagination, ...search } = params;
      setFilterData({
        filter: requestPayload.filter,
        conditional_filters: requestPayload.conditional_filters || [],
        record_types: requestPayload.record_types,
      });
      const sheetRecords = await fetchRecordData(requestPayload);
      const _dataSheetRecords = await Promise.all(
        sheetRecords.data.map(async (record: DataSheetRecord) => {
          const surveyData =
            record.data_survey_id &&
            (await getSurveyById(record.data_survey_id));

          return {
            ...record,
            key: record?._id?.$oid,
            owner_users: record.owner_users || [record.username],
            userName:
              usersList?.find((v) => v.Username === record.username)?.name ||
              "",
            reviewStatus: record.review_status, // Add this line to map review_status to reviewStatus
            ...(record.data_survey_id && {
              surveyName: surveyData?.name || "",
            }),
          };
        })
      );
      const _dataSheetRecordsWithSurveyData = await loadKPIsSurveyData(
        _dataSheetRecords
      );
      setDataSheetRecords(_dataSheetRecordsWithSurveyData);
      setPagination({
        ..._pagination,
        total: sheetRecords.filteredCount,
        showSizeChanger: sheetRecords.filteredCount > 10,
      });
      setCountRecords({
        pending: sheetRecords.pendingCount,
        archive: sheetRecords.deletedCount,
        denied: sheetRecords.deniedCount,
      });
    } catch (error) {
      message.error("Something went wrong while loading data sheet records!");
    } finally {
      setLoadingSheetRecords(false);
    }
  };

  useEffect(() => {
    if (dataSheet?._id?.$oid && usersList?.length) {
      setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
      if (isDeleted || isPending || isDenied) {
        setSearch({ conditionalFilters: [] });
        setSorter(null);
        setSortRecords({
          type: "key",
          sortAlphabetically: true,
        });
        loadDataSheetRecords({
          pagination: { ...pagination, current: 1 },
          conditionalFilters: [],
          sorter: null,
        });
      } else {
        if (
          advancedFilters ||
          selectedConditionalFilters ||
          selectedIdentifiers
        ) {
          getFilteredData();
        } else {
          if (dataSheetSettings) {
            setSearch({
              conditionalFilters: dataSheetSettings?.conditionalFilters,
            });
            setSorter(dataSheetSettings?.sorter);
            const newSorter: any = {
              sortAlphabetically: dataSheetSettings?.sorter?.order === "ascend",
              type: dataSheetSettings?.sorter?.field,
            };
            setSortRecords(newSorter);
          }
          loadDataSheetRecords({
            pagination: { ...pagination, current: 1 },
            conditionalFilters: dataSheetSettings?.conditionalFilters?.length
              ? dataSheetSettings?.conditionalFilters
              : search.conditionalFilters,
            sorter: dataSheetSettings?.sorter || sorter,
          });
        }
      }
      dataSheet?.metadata_sheet_id &&
        getMetaDataRecords({
          filter: { sheet_id: dataSheet?.metadata_sheet_id },
        }).then((metaData) => setMetaDataRecords(metaData));
    }
  }, [
    dataSheet,
    advancedFilters,
    localStorage?.getItem("identifiersValues"),
    localStorage?.getItem("conditional_filters"),
    refreshDataSheetRecords,
    isDeleted,
    isPending,
    isDenied,
    userSettings,
    usersList,
  ]);

  useEffect(() => {
    if (dataSheetRecords?.length) {
      const newColumns = calculateProportionalWidthTable(manageColumnData);
      setManageColumnData(newColumns);
    }
  }, [dataSheetRecords]);

  const handleSaveSettings = async (params: any) => {
    try {
      const { columns, conditionalFilters, sorter, rowSize } = params;
      const username = userInfoStore.userID;
      const dataSheetId = dataSheet?._id?.$oid;
      const newValue = {
        dataSheetId,
        columns,
        conditionalFilters,
        sorter,
        rowSize,
      };
      if (userSettings) {
        const settingValue = JSON.parse(userSettings.settingValue);
        let newSettingValue = [];
        if (
          settingValue.find((item: any) => item?.dataSheetId === dataSheetId)
        ) {
          newSettingValue = settingValue.map((item: any) =>
            item?.dataSheetId === dataSheetId ? newValue : item
          );
        } else {
          newSettingValue = [...settingValue, newValue];
        }
        const payload = {
          owner: username,
          settingName: "data_sheet_records_settings",
          settingValue: JSON.stringify(newSettingValue),
        };

        await updateUserSettings(payload);
      } else {
        const payload = {
          owner: username,
          settingName: "data_sheet_records_settings",
          settingValue: JSON.stringify([newValue]),
        };
        await createUserSettings(payload);
      }
    } catch (error) {
      console.log("Something went wrong!", error);
    }
  };

  const onCancelHandler = () => {
    setEditingKey("");
    setEditReason("");
    setLastRevisionData(null);
    form.resetFields();
  };

  const onClickUpdateRecord = async (record: DataSheetRecord, newNote = "") => {
    const notes = lastRevisionData?.notes || record.notes || [];
    if (newNote && newNote !== "") {
      const formattedNote = {
        user: userInfoStore.userName,
        date: moment().format("YYYY-MM-DD HH:mm:ss"),
        text: newNote,
      };
      notes.push(formattedNote);
    }

    try {
      setSubmitting(true);
      await form.validateFields();
      const values = form.getFieldsValue(true);
      const isValid =
        dataSheet?.validationRules &&
        editingKey?.length &&
        areValidFieldsByRules(
          dataSheet?.validationRules,
          values,
          form.setFields
        );
      if (!isValid && dataSheet?.validationRules && editingKey?.length) {
        return;
      }
      const sheetName = record.sheet_name;
      const payload = {
        ...record,
        operation: "Edit",
        editReason: editReason || "",
        notes: notes,
        [sheetName]: lastRevisionData
          ? lastRevisionData[lastRevisionData.sheet_name]
          : values,
      };
      const response = await updateData(payload);

      response.messages && getMessage(response, dataSheet);
      if (response.updated_document) {
        setDataSheetRecords((prev) =>
          prev.map((rec) =>
            rec._id?.$oid === response?.updated_document?._id?.$oid
              ? { ...rec, ...response.updated_document }
              : { ...rec }
          )
        );
        onFinishCreateNewData();
        setEditingKey("");
        setEditReason("");
        form.resetFields();
      } else {
        message.error("Error while updating record!");
      }
    } catch (error) {
      message.error("Error while updating record!");
      console.log("Error while updating record!", error);
    } finally {
      setSubmitting(false);
    }
  };

  const onUpdateNotes = async (notes: Note[], record: DataSheetRecord) => {
    setLoadingSheetRecords(true);

    try {
      const result = await updateData({ ...record, notes, operation: "Note" });

      if (result.updated_document?.sheet_id) {
        setDataSheetRecords((prev) =>
          prev.map((rec) =>
            rec._id?.$oid === result.updated_document?._id?.$oid
              ? { ...rec, ...result.updated_document }
              : rec
          )
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSheetRecords(false);
    }
  };

  const onClickUpdateRecordNotes = (record: DataSheetRecord, note: string) => {
    const formattedNote = {
      user: userInfoStore.userName,
      date: moment().format("YYYY-MM-DD HH:mm:ss"),
      text: note,
    };
    try {
      addRecordNote(record?._id?.$oid, formattedNote).then(() =>
        loadDataSheetRecords({
          pagination,
          conditionalFilters: search.conditionalFilters,
          sorter,
        })
      );
    } catch (error) {
      message.error("Error while updating note!");
    }
  };

  const onClickEditRegularRecord = (record: DataSheetRecord) => {
    try {
      const getValues = record[record?.sheet_name];

      const updatedValues: any = {};

      for (const [key, value] of Object.entries(getValues)) {
        const getSchemaOfField = dataSheet?.sheet_schema.find(
          (schema) => schema.entity_name === key
        );
        if (getSchemaOfField && getSchemaOfField?.data_type === "date") {
          if (typeof value === "object" && value !== null) {
            updatedValues[key] = value
              ? moment(value.$date, YearMonthDayFormat)
              : moment(new Date(), YearMonthDayFormat);
          } else {
            updatedValues[key] = value
              ? moment(value, YearMonthDayFormat)
              : moment(new Date(), YearMonthDayFormat);
          }
        } else {
          updatedValues[key] = value;
        }
      }

      getValues && form.setFieldsValue(updatedValues);
      setLastRevisionData(null);
      setEditingKey(record?.["_id"]?.["$oid"]);
    } catch (error) {
      console.log("Error when request for edit", error);
    }
  };

  const onClickEditStandardRecord = (record: DataSheetRecord) => {
    setRowData(record);
    setShowUpdateRecordModal(true);
  };
  const onClickEditRecord = (record: DataSheetRecord) => {
    dataSheet?.is_standard_data
      ? onClickEditStandardRecord(record)
      : onClickEditRegularRecord(record);
  };
  const onClickRecordLockBtn = (record: DataSheetRecord) => {
    try {
      const payload = {
        fields: {
          isLocked: !record.isLocked,
          operation: record.isLocked ? "Unlock" : "Lock",
        },
        _id: {
          $oid: record._id.$oid,
        },
      };

      partialUpdateRecord(payload).then(() =>
        loadDataSheetRecords({ pagination, sorter, ...search })
      );
    } catch (error) {
      message.error("Error while updating record!");
    }
  };

  const onClickDeleteRestoreRecord = (
    record: DataSheetRecord,
    isArchived: boolean
  ) => {
    try {
      if (isArchived) {
        const payload = {
          fields: {
            operation: isArchived ? "Delete" : "Restore",
            archive: isArchived,
          },
          _id: {
            $oid: record._id.$oid,
          },
        };
        partialUpdateRecord(payload).then(() =>
          loadDataSheetRecords({
            pagination,
            conditionalFilters: search.conditionalFilters,
            sorter,
          })
        );
      } else {
        const payload = {
          ...record,
          operation: "Restore",
          archive: isArchived,
        };
        updateData(payload).then((response) => {
          response.messages && getMessage(response, dataSheet);
          loadDataSheetRecords({
            pagination,
            conditionalFilters: search.conditionalFilters,
            sorter,
          });
        });
      }
    } catch (error) {
      message.error(
        `Error while ${!isArchived ? "restore" : "archive"} record!`
      );
    }
  };
  const onClickChangeApprovalStatusRecord = async (
    record: any,
    status: RecordStatus,
    reason?: string,
    files?: File[]
  ) => {
    try {
      const reasonKey = uuidv4();
      if (status === RecordStatus.APPROVED) {
        delete record.alerts;
      }
      delete record.data_approval_status;
      if (!!files?.length) {
        await uploadFileForRecord(files, reasonKey);
      }
      const newStatusReason = {
        review_status: status,
        key: reasonKey,
        comments: reason,
        ...(!!files?.length && { files: files?.map((item) => item.name) }),
        timestamp: moment().format("YYYY-MM-DD HH:mm").toLocaleString(),
        user_full_name: userInfoStore.userName,
        username: userInfoStore.userID,
      };
      const payload = {
        ...record,
        review_status: status,
        isAdminReview: true,
        ...(status !== RecordStatus.APPROVED && {
          statusReasons: [...(record?.statusReasons || []), newStatusReason],
        }),
      };
      await updateData(payload).then(async (result) => {
        if (result.messages) {
          getMessage(result, dataSheet);
        }
        if (result.updated_document.review_status === "DENIED") {
          await sendUserDenyReminder(result.updated_document.key);
        } else if (result.updated_document.review_status === "APPROVED") {
          await sendUserApprovalNotification(result.updated_document.key);
        }
      });
    } catch (error) {
      message.error("Error while updating record!");
    } finally {
      loadDataSheetRecords({
        pagination,
        conditionalFilters: search.conditionalFilters,
        sorter,
      });
    }
  };

  const onChangeHistoryHandler = (
    record: DataSheetRecord,
    historyData: any
  ) => {
    try {
      setLastRevisionData(historyData);
      if (record.revision === historyData.revision) {
        setIsLatestRevision(true);
      } else {
        setIsLatestRevision(false);
      }
      setEditingKey(record?.["_id"]?.["$oid"]);
    } catch (error) {
      console.log("Error when request for edit", error);
    }
  };

  const onSubmitOwners = (record: any, users: string[], groups: string[]) => {
    try {
      const payload = {
        fields: {
          owner_users: users,
          owner_groups: groups,
        },
        _id: {
          $oid: record._id.$oid,
        },
      };
      partialUpdateRecord(payload).then(() =>
        loadDataSheetRecords({ pagination, sorter })
      );
    } catch (error) {
      message.error("Error while updating record!");
    }
  };

  const onClickDataSheetFileBtn = (record: DataSheetRecord) => {
    setRowData(record);
    setShowDataSheetFilesModal(true);
  };

  const onClickAttachedFileBtn = (record: DataSheetRecord) => {
    setRowData(record);
    setShowDataSheetAttachedModal(true);
  };

  const onAddNewRecordFiles = (record: DataSheetRecord) => {
    setRowData(record);
    setShowNewRecordFilesModal(true);
  };

  const handleEditRichText = (newValue: string) => {
    form.setFieldsValue({ [currentField.currentField]: newValue });
    setCurrentField({
      currentField: "",
      currentFieldName: "",
      value: "",
      recordId: "",
      currentTab: "comment",
    });
  };

  const handleViewRichText = (record: DataSheetRecord, fieldName: string) => {
    const value = form.getFieldValue(fieldName);
    setCurrentField({
      recordId: record?._id?.$oid || record.key || "",
      currentField: fieldName,
      value: editingKey
        ? value || ""
        : record[dataSheet.sheet_name][fieldName] || "",
      currentFieldName:
        dataSheet.sheet_schema?.find((v) => v.entity_name === fieldName)
          ?.display_name || "",
      currentTab: "comment",
    });
    setIsRichTextModalVisible(true);
    setRowData(record);
  };
  const getDateValue = (value: any) => {
    let newValue = value;
    if (typeof value === "object") {
      newValue = moment(value.$date);
    } else {
      newValue = value ? moment(value) : undefined;
    }
    return newValue;
  };

  const handleChangeIdentifierField = (value: string, entityName: any) => {
    const entityNamesList = dataSheet?.sheet_schema.filter(
      (item) => item.metadata_entity_name
    );
    const metadataEntityName =
      dataSheet?.sheet_schema.find((v) => v.entity_name === entityName)
        ?.metadata_entity_name || "";

    const selectedMetadataObject = metaDataRecords?.[0][
      metaDataRecords[0]?.sheet_name
    ].find((v) => v[metadataEntityName] === form.getFieldValue(entityName));

    let metadataFormItems = {};
    entityNamesList?.forEach((v) => {
      metadataFormItems = {
        ...metadataFormItems,
        [v.entity_name]:
          v.data_type === "date"
            ? getDateValue(selectedMetadataObject?.[v.metadata_entity_name])
            : selectedMetadataObject?.[v.metadata_entity_name],
      };
    });

    form.setFieldsValue({
      [entityName]: value,
      ...metadataFormItems,
    });
    const disableFields = entityNamesList!
      .filter(
        (v) =>
          v.metadata_entity_name &&
          v.metadata_entity_name !== metadataEntityName
      )
      .map((item) => item.entity_name);
    setDisabledFields(disableFields);
  };

  const onFinishUploadInsertDataFile = () => {
    setRefreshDataSheetRecords((pre) => pre + 1);
  };

  const onFinishCreateNewData = () => {
    setRefreshDataSheetRecords((pre) => pre + 1);
  };

  const onFinishUpdateData = async () => {
    await loadDataSheetRecords({ pagination, sorter, ...search });
  };

  const onClickExportExcel = async () => {
    try {
      setExportLoader(true);
      const job_id = await dataSheetFileExport(
        "DOWNLOAD_FILE",
        dataSheet?._id?.$oid,
        dataSheet?.sheet_name,
        filterData
      );
      const result = await fetchDataWithRetry(job_id);
      const resultSummary = result?.resultSummary || "";
      if (resultSummary) {
        const isError = resultSummary?.statusCode >= 400;
        if (isError) {
          message.error(resultSummary?.body);
          return Promise.reject();
        }
        result?.message && message.success(resultSummary?.message);
      } else {
        return Promise.reject();
      }
      if (result === "Export Failed. Please contact support.") {
        message.error(job_id);
      } else {
        window.location.href = resultSummary;
      }
    } catch (error) {
      message.error("Error fetching data");
    } finally {
      setExportLoader(false);
    }
  };

  const onClickExportWithAudit = async () => {
    try {
      setExportLoader(true);
      const job_id = await dataSheetFileWithAuditExport(
        "DOWNLOAD_FILE_WITH_AUDIT",
        dataSheet?._id?.$oid,
        dataSheet?.sheet_name,
        filterData
      );
      const result = await fetchDataWithRetry(job_id);
      const resultSummary = result?.resultSummary || "";
      if (resultSummary) {
        const isError = resultSummary?.statusCode >= 400;
        if (isError) {
          message.error(resultSummary?.body);
          return Promise.reject();
        }
        result?.message && message.success(resultSummary?.message);
        console.log(result.message);
      } else {
        return Promise.reject();
      }
      if (result === "Export Failed. Please contact support.") {
        message.error(job_id);
      } else {
        window.location.href = resultSummary;
      }
    } catch (error) {
      message.error("Error fetching data");
    } finally {
      setExportLoader(false);
    }
  };

  const handleTableChange = (
    pagination: Pagination,
    _filters: any,
    sorter: any
  ) => {
    const newSorter = Object.keys(sorter).length
      ? sorter
      : {
          order: sortRecords.sortAlphabetically ? "ascend" : "descend",
          field: sortRecords.type,
        };
    setSorter(newSorter);
    setSelectedRows([]);
    loadDataSheetRecords({ pagination, ...search, sorter: newSorter });
  };

  const onApplyFilters = (params: SearchParams) => {
    const { conditionalFilters } = params;
    const newPagination = { ...pagination, current: 1 };
    setPagination(newPagination);
    loadDataSheetRecords({
      pagination: { ...pagination, current: 1 },
      conditionalFilters,
      sorter,
    });
    setSearch(params as Search);
    if (!isPending && !isDenied && !isDeleted) {
      setDataSheetSettings((prev: any) => ({
        ...prev,
        conditionalFilters,
      }));
      handleSaveSettings({
        columns: manageColumnData,
        conditionalFilters,
        sorter,
        rowSize,
      });
    }
  };

  const onResetFilters = () => {
    localStorage.removeItem("identifiersValues");
    const newPagination = { ...pagination, current: 1 };
    setPagination(newPagination);
    loadDataSheetRecords({
      pagination: newPagination,
      conditionalFilters: [],
      sorter,
    });
    setSearch({} as Search);
    if (!isPending && !isDenied && !isDeleted) {
      setDataSheetSettings((prev: any) => ({
        ...prev,
        conditionalFilters: [],
      }));
      handleSaveSettings({
        columns: manageColumnData,
        conditionalFilters: [],
        sorter,
        rowSize,
      });
    }
  };

  const handleAddMissingLocations = () => {
    hideCheckingModal();
    showMissingDataSheetsLoading();
    loadDataSheetRecords({ pagination }).finally(() =>
      hideMissingDataSheetsLoading()
    );
  };

  const makeMissingLocationsQuery = (filters: any) => {
    const defaultDataSheetRecordsFilter = {
      $and: [
        {
          $or: [
            { data_approval_status: true },
            { data_approval_status: { $exists: false } },
          ],
        },
        {
          review_status: {
            $nin: ["DENIED", "PENDING"],
          },
        },
      ],
    };
    const newFilters = filters?.map(
      (item: any) =>
        (item.op === "isEmpty" && { ...item, column_value: true }) ||
        (item.op === "isNotEmpty" && {
          ...item,
          op: "isEmpty",
          column_value: false,
        }) ||
        item
    );

    return {
      skip: 0,
      limit: 1000,
      filter: defaultDataSheetRecordsFilter,
      filters_list: newFilters,
      sheet_id: dataSheet?._id?.$oid,
    };
  };

  const handleCountMissingLocations = async (filters: any[]) => {
    showMissingDataSheetsLoading();
    const requestPayload = makeMissingLocationsQuery(filters);
    await fetchRecordData(requestPayload).then((res) => {
      setExistingLocationsNumber(
        res.data.filter((v: any) => !v.archive).length
      );
    });
    hideMissingDataSheetsLoading();
  };

  useEffect(() => {
    if (dataSheet) {
      if (dataSheet?.sheet_schema.length > 0) {
        const manageColumns: any = [];
        for (let i = 0; i < dataSheet.sheet_schema.length; i++) {
          const dataIndex = dataSheet.sheet_schema[i].entity_name;
          const name = dataSheet.sheet_schema[i].display_name;
          manageColumns.push({ hide: false, name, dataIndex });
          if (dataSheet.sheet_schema[i].unit_id) {
            manageColumns.push({
              hide: false,
              name: name + " Unit",
              dataIndex: dataIndex + "_unit_id",
            });
          }
        }
        manageColumns?.push({
          dataIndex: "surveyName",
          hide: true,
          name: "Survey Name",
        });
        manageColumns?.push({
          dataIndex: "userName",
          hide: true,
          name: "Username",
        });
        manageColumns?.push({
          dataIndex: "reviewStatus",
          hide: true,
          name: "Review Status",
        }); 
        if(manageColumns){
          const hideUserKpi = (
            dataSheet.is_kpi_data === null || 
            dataSheet.is_kpi_data === undefined || 
            dataSheet.is_kpi_data === false
          )
          let userEnteredKPIColumn = {
            dataIndex: "userEnteredKPIValue",
            hide: hideUserKpi,
            name: "User-entered KPI Value",
            tooltip: "This column displays KPI values entered by the user.", // Tooltip for more context
          };
          if(!hideUserKpi){
            manageColumns.push(userEnteredKPIColumn);
          }
          
        }
        
        const manageColumnsWidth =
          calculateProportionalWidthTable(manageColumns);
        setManageColumnData(manageColumnsWidth);
      }
      setCollapsedPanel(dataSheet?._id.$oid);
      dataSheet?.metadata_sheet_id && getMetadataSheet();
      setSelectedRows([]);
    }
  }, [dataSheet]);

  const loadKPIsSurveyData = async (_dataSheetRecords: any) => {
    try {
      const data: any = await Auth.currentSession();
      const username = data.accessToken.payload.username;

      let recordsQuery = {
        skip: 0,
        sheet_id: dataSheet?._id.$oid,
      };

      const fetchPromise = await fetchRecordData(recordsQuery);

      const surveyPayload = {
        filter: {
          mainKpiId: {
            $in: [fetchPromise["data"][0]._id.$oid],
          },
        },
        username: username,
      };

      const surveyKpis: SurveyKPI[] = await surveyKpiService.surveyKpisList(
        surveyPayload
      );

      _dataSheetRecords.forEach((record: any) => {
        const sheetName = record?.sheet_name;
        const recordId = record?._id?.$oid;

        const matchingSurveyKpi = surveyKpis.find(
          (surveyKpi) => surveyKpi.mainKpiId === recordId
        );

        if (matchingSurveyKpi && record[sheetName]) {
          record[sheetName]["userEnteredKPIValue"] =
            matchingSurveyKpi.enteredValue;
          record[sheetName]["files"] = matchingSurveyKpi.files;
          record[sheetName]["notes"] = matchingSurveyKpi.notes;
        }
      });
    } catch (error) {
      console.log("error", error);
    } finally {
      return _dataSheetRecords;
    }
  };
  useEffect(() => {
    const response =
      dataSheet?.metadata_sheet_id && getDataSheetFields(dataSheet);
    Promise.resolve(response).then((data) => {
      if (data) {
        setAllFields(data?.fields);
      }
    });
  }, [dataSheet]);

  const onCheckedItem = (value: any) => {
    const dataIndex = value.target.value;
    const hideStatus = !value.target.checked;

    const newManageColumnData = manageColumnData.map((item) => {
      if (item.dataIndex === dataIndex) {
        return { ...item, hide: hideStatus };
      }
      return item;
    });

    setManageColumnData(newManageColumnData);
    setDataSheetSettings((prev: any) => ({
      ...prev,
      columns: newManageColumnData,
    }));
    handleSaveSettings({
      columns: newManageColumnData,
      conditionalFilters:
        isPending || isDenied || isDeleted
          ? dataSheetSettings?.conditionalFilters || null
          : search.conditionalFilters,
      sorter:
        isPending || isDenied || isDeleted
          ? dataSheetSettings?.sorter || null
          : sorter,
      rowSize,
    });
  };

  const handleSwitch = (checked: boolean) => {
    const metaSchema = dataSheet?.sheet_schema.filter(
      (schema) => schema.metadata_entity_name
    );
    const newManageColumnData = manageColumnData.map((item) =>
      metaSchema?.find((schema) => schema.entity_name == item.dataIndex)
        ? { ...item, hide: !checked }
        : { ...item }
    );
    setManageColumnData(newManageColumnData);
    setDataSheetSettings((prev: any) => ({
      ...prev,
      columns: newManageColumnData,
    }));
    handleSaveSettings({
      columns: newManageColumnData,
      conditionalFilters:
        isPending || isDenied || isDeleted
          ? dataSheetSettings.conditionalFilters || null
          : search.conditionalFilters,
      sorter:
        isPending || isDenied || isDeleted
          ? dataSheetSettings.sorter || null
          : sorter,
      rowSize,
    });
  };

  const handleMassCheck = (checked: boolean) => {
    const newManageColumnData = manageColumnData.map((item) => {
      return { ...item, hide: !checked };
    });
    setManageColumnData(newManageColumnData);
    setDataSheetSettings((prev: any) => ({
      ...prev,
      columns: newManageColumnData,
    }));
    handleSaveSettings({
      columns: newManageColumnData,
      conditionalFilters:
        isPending || isDenied || isDeleted
          ? dataSheetSettings.conditionalFilters || null
          : search.conditionalFilters,
      sorter:
        isPending || isDenied || isDeleted
          ? dataSheetSettings.sorter || null
          : sorter,
      rowSize,
    });
  };

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }): void => {
    if (oldIndex !== newIndex) {
      // look for all items that have ' Unit' at the end
      // Filter items with ' Unit' at the end of name and have corresponding base name
      const itemsWithUnit = manageColumnData.filter((item) => {
        // Check if item.name ends with ' Unit'
        if (!item.name.endsWith(" Unit")) {
          return false; // Filter out items that don't end with ' Unit'
        }

        // Check if there exists an item with the same base name (without ' Unit')
        const baseName = item.name.replace(" Unit", "");
        const correspondingItem = manageColumnData.find(
          (obj) => obj.name === baseName
        );

        return correspondingItem !== undefined;
      });

      // look for all items that have ' Unit' at then and also the ones that do have unit but don't have a corresponding basename
      // find items that either do not end with " Unit" or end with " Unit" but do not have a corresponding base name without " Unit" in the same array
      const itemsWithoutUnit = manageColumnData.filter((item) => {
        // Check if item.name ends with ' Unit'
        if (!item.name.endsWith(" Unit")) {
          return true; // get items without unit at the end
        } else {
          // search if it has a corresponding base name
          const baseName = item.name.replace(" Unit", "");
          const correspondingItem = manageColumnData.find(
            (obj) => obj.name === baseName
          );

          // If no corresponding base name found, include this item
          return correspondingItem === undefined;
        }
        return false;
      });

      // now do the move
      const newData = arrayMove(itemsWithoutUnit, oldIndex, newIndex);
      // now add back in the list of unit columns to array to the end of the same name without item
      // Iterate through items with ' Unit' and insert into arr_temp
      itemsWithUnit.forEach((item) => {
        const baseName = item.name.replace(" Unit", "");
        const index = newData.findIndex((obj) => obj.name === baseName);

        if (index !== -1) {
          // Insert item after the found index
          newData.splice(index + 1, 0, item);
        } else {
          // Append item to the end if base name not found
          newData.push(item);
        }
      });

      // then reindex everything so its normalized.
      const newKey = newData.map((item, index) => {
        return {
          ...item,
          index,
        };
      });
      setManageColumnData(newKey);
      setDataSheetSettings((prev: any) => ({
        ...prev,
        columns: newKey,
      }));
      handleSaveSettings({
        columns: newKey,
        conditionalFilters:
          isPending || isDenied || isDeleted
            ? dataSheetSettings.conditionalFilters || null
            : search.conditionalFilters,
        sorter:
          isPending || isDenied || isDeleted
            ? dataSheetSettings.sorter || null
            : sorter,
        rowSize,
      });
    }
  };

  const handleConfirmCreateExpression = () => {
    loadDataSheet(dataSheet?._id?.$oid!);
  };

  const onClickAddEmptyLine = () => {
    if (!dataSheet) {
      return;
    }
    setPagination({
      ...pagination,
      pageSize: pagination.pageSize + 1,
      total: (pagination?.total || 0) + 1,
    });
    const schemas = {} as SheetSchema;
    dataSheet.sheet_schema.forEach((schema) => {
      schemas[schema.entity_name] = "";
      if (schema.unit_id) {
        schemas[schema.entity_name + "_unit_id"] = schema.unit_id;
      }
    });
    const sheetName = dataSheet.sheet_name;
    const sheetId = dataSheet._id.$oid;
    const newObject: DataSheetRecord = {} as DataSheetRecord;
    newObject[sheetName] = schemas;
    newObject.key = uuidv4();
    newObject.sheet_id = { $oid: sheetId };
    newObject.sheet_name = sheetName;
    const newArray = [...dataSheetRecords, newObject];
    setDataSheetRecords(newArray);
    setEditingKey(newObject?.key);
    setNewRecordKey(newObject?.key);
    form.setFieldsValue(schemas);
  };

  const uploadSingleDataSheetFile = async (
    file: File,
    dataId: string,
    name?: string
  ) => {
    try {
      const CurrentDate = moment().unix();
      const fileName =
        name || CurrentDate + "-" + file.name?.replace(/[/\\$#@?%*:|"<>]/g, "");

      const signedURL = await uploadDataSheetFile(dataId, fileName); //calling api function

      if (signedURL) {
        const arrayBufferData = await file.arrayBuffer();
        if (arrayBufferData) {
          const blob = new Blob([new Uint8Array(arrayBufferData)], {
            type: file.type,
          });
          const result = await fetch(signedURL, {
            method: "PUT",
            body: blob,
          });
          if (result?.status === 200) {
            message.success("File uploaded Successfully!");
          }
        }
      }
    } catch (error) {
      console.log("Something went wrong while uploading file", error);
      message.error("Something went wrong while uploading file.");
    }
  };
  const uploadFileForRecord = async (
    files: File[],
    data_id: string,
    fileName?: boolean
  ) => {
    if (files && files.length) {
      try {
        for (const file of files) {
          const name = fileName ? file.name : "";
          await uploadSingleDataSheetFile(file, data_id, name);
        }
      } catch (error) {
        console.log("Something went wrong while uploading files!", error);
      }
    }
  };

  const handleConfirmCreateRecord = async (record: DataSheetRecord) => {
    try {
      setSubmitting(true);
      const values = await form.validateFields();
      const filteredSchema = dataSheet?.sheet_schema?.filter((item) =>
        manageColumnData.find(
          (v) => v.dataIndex === item.entity_name && !v.hide
        )
      );
      const isValid =
        dataSheet?.validationRules &&
        areValidFieldsByRules(
          dataSheet?.validationRules,
          values,
          form.setFields
        );
      if (!isValid && dataSheet?.validationRules) {
        return;
      }
      await Promise.all(
        filteredSchema!.map(async (item) => {
          if (!item.unit_id) return;
          values[item.entity_name + "_actual_unit_id"] =
            values[item.entity_name + "_unit_id"];
          values[item.entity_name + "_actual_value"] = values[item.entity_name];
          values[item.entity_name] = await doConversion(
            values[item.entity_name],
            values[item.entity_name + "_unit_id"],
            item.unit_id,
            item.customConversions
          );
          values[item.entity_name + "_unit_id"] = item.unit_id;
        })
      );
      const files = record?.files;
      const newRecordsPayload = {
        sheet_id: dataSheet?._id.$oid,
        sheet_name: record.sheet_name,
        documents: [values],
        source: "Manual Entry",
      };

      const response = await insertData(newRecordsPayload);

      if (response?.ids?.length && files?.length) {
        await uploadFileForRecord(files, response.ids[0]?.$oid);
      }

      setEditingKey("");
      setNewRecordKey("");
      response.messages && getMessage(response, dataSheet);

      loadDataSheetRecords({
        pagination,
        conditionalFilters: search.conditionalFilters,
        sorter,
      });
    } catch (error) {
      console.log("Something went wrong while creating a record!", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancelCreatingRecord = (record: DataSheetRecord) => {
    const newArray = dataSheetRecords.filter((item) => item.key !== record.key);
    setDataSheetRecords(newArray);
    setEditingKey("");
    setNewRecordKey("");
  };

  const handleAddFiles = (record: DataSheetRecord) => {
    const newRecords = dataSheetRecords.map((item) =>
      item.key === record.key ? record : item
    );
    setDataSheetRecords(newRecords);
    setShowNewRecordFilesModal(false);
  };

  const handleChangeLockRecords = (isLocked: boolean) => {
    try {
      setSubmitting(true);
      const selectedIds = selectedRows.map((v) => v._id.$oid);
      const payload = {
        sheet_id: dataSheet?._id.$oid,
        filters: { _id: { $in: selectedIds } },
        column: "isLocked",
        value: isLocked,
        operation: isLocked ? "Lock" : "Unlock",
      };
      massUpdateRecords(payload).then(() =>
        loadDataSheetRecords({
          pagination,
          sorter,
          conditionalFilters: search.conditionalFilters,
        })
      );
      setSelectedRows([]);
    } catch (error) {
      message.error("Error while updating record!");
    } finally {
      setSubmitting(true);
    }
  };

  const handleChangeAllStatus = async () => {
    const payload = {
      sheet_id: dataSheet?._id?.$oid,
      value: RecordStatus.APPROVED,
      filters: {
        review_status: RecordStatus.PENDING,
      },
      conditional_filters: search.conditionalFilters,
      column: "review_status",
    };
    await massApproveWithDeduplication(payload).then(() =>
      loadDataSheetRecords({
        pagination,
        sorter,
        conditionalFilters: search.conditionalFilters,
      })
    );
  };

  const handleMassChangeStatus = async (
    status: RecordStatus,
    reason?: string,
    files?: File[]
  ) => {
    try {
      const reasonKey = uuidv4();
      await Promise.all(
        selectedRows.map(async (item) => {
          if (status === RecordStatus.APPROVED) {
            delete item.alerts;
          }
          delete item.data_approval_status;
          if (!!files?.length) {
            await uploadFileForRecord(files, reasonKey, true);
          }
          const newStatusReason = {
            review_status: status,
            key: reasonKey,
            comments: reason,
            ...(!!files?.length && { files: files?.map((item) => item.name) }),
            timestamp: moment().format("YYYY-MM-DD HH:mm").toLocaleString(),
            user_full_name: userInfoStore.userName,
            username: userInfoStore.userID,
          };
          const payload = {
            ...item,
            review_status: status,
            isAdminReview: true,
            ...(status !== RecordStatus.APPROVED && {
              statusReasons: [...(item?.statusReasons || []), newStatusReason],
            }),
          };
          await updateData(payload);
        })
      );
      setSelectedRows([]);
    } catch (error) {
      message.error("Error while updating records!");
    } finally {
      loadDataSheetRecords({
        pagination,
        conditionalFilters: search.conditionalFilters,
        sorter,
      });
    }
  };
  const handleFormChange = () => {
    if (!isAirDistanceSheet(dataSheet)) {
      return;
    }
    const fieldsValues = form.getFieldsValue(true);
    const isAllRequiredFiedsFilled =
      !!fieldsValues.destination_city &&
      !!fieldsValues.origination_city &&
      (!!fieldsValues.type_of_journey || !!fieldsValues.journey_type);
    if (isAllRequiredFiedsFilled) {
      let payload: any = {
        origin: fieldsValues.origination_city,
        destination: fieldsValues.destination_city,
        trip_type: fieldsValues.type_of_journey || fieldsValues.journey_type,
      };
      !!fieldsValues.transit_city &&
        (payload.transit = fieldsValues.transit_city);
      setLoadingFields(["routing", "distance"]);
      calculateAirDistance(payload).then((result) => {
        form.setFieldsValue({
          routing: result.routing,
          distance: result.distance,
          distance_unit_id: "km",
        });
        result.distance
          ? setDisabledFields(["routing", "distance", "distance_unit_id"])
          : setDisabledFields([]);
        setLoadingFields([]);
      });
    } else {
      setDisabledFields([]);
    }
  };

  const handleChangeSize = (value: number) => {
    setRowSize(value);
    setDataSheetSettings((prev: any) => ({
      ...prev,
      rowSize: value,
    }));
    handleSaveSettings({
      columns: manageColumnData,
      conditionalFilters:
        isPending || isDenied || isDeleted
          ? dataSheetSettings.conditionalFilters || null
          : search.conditionalFilters,
      sorter:
        isPending || isDenied || isDeleted
          ? dataSheetSettings.sorter || null
          : sorter,
      rowSize: value,
    });
  };

  const handleCloseDrawerNotes = () => {
    hideFieldNotesDrawer();
    setCurrentField({
      currentField: "",
      currentFieldName: "",
      value: "",
      recordId: "",
      currentTab: "comment",
    });
  };

  const handleAddFieldNote = (
    currentField: string,
    recordId: string,
    text: string,
    noteType: "comment" | "warning" | "estimate"
  ) => {
    const newNote = {
      key: uuidv4(),
      user: userInfoStore.userName,
      date: moment().format("YYYY-MM-DD HH:mm:ss"),
      text: text,
      isAddedByAdmin: true,
      field: currentField,
      username: userInfoStore.userID,
      isAcknowledged: false,
      noteType,
    };
    try {
      addRecordNote(recordId, newNote, true).then(() =>
        loadDataSheetRecords({
          pagination,
          conditionalFilters: search.conditionalFilters,
          sorter,
        })
      );
    } catch (error) {
      message.error("Error while updating note!");
    }
  };

  const handleChangeFieldNotes = async (fieldNotes: Note[]) => {
    const record = dataSheetRecords.find(
      (item) => item._id.$oid === currentField.recordId
    );
    setLoadingSheetRecords(true);
    try {
      const prevNotes =
        record?.field_notes?.filter(
          (item) => item.field !== currentField.currentField
        ) || [];

      const result = await updateData({
        ...record,
        field_notes: [...prevNotes, ...fieldNotes],
        operation: "Notes",
      });

      if (result.updated_document?.sheet_id) {
        setDataSheetRecords((prev) =>
          prev.map((rec) =>
            rec._id?.$oid === result?.updated_document?._id?.$oid
              ? { ...rec, ...result.updated_document }
              : rec
          )
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSheetRecords(false);
    }
  };

  const handleMarkAsEstimated = async (recordId: string) => {
    const record = dataSheetRecords.find(
      (item) => item._id.$oid === currentField.recordId
    );

    let markAsEstimated: FieldNote[] =
      record?.field_notes?.filter(
        (item) =>
          item.field === currentField.currentField &&
          item.noteType === "estimatedBoolean"
      ) || [];

    if (markAsEstimated.length) {
      let note = {
        ...markAsEstimated[0],
        markedAsEstimated: !Boolean(markAsEstimated[0].markedAsEstimated),
      };

      try {
        const prevNotes =
          record?.field_notes?.filter(
            (item) =>
              !(
                item.field === currentField.currentField &&
                item.noteType === "estimatedBoolean"
              )
          ) || [];

        const result = await updateData({
          ...record,
          field_notes: [...prevNotes, note],
          operation: "Notes",
        });

        if (result.updated_document?.sheet_id) {
          setDataSheetRecords((prev) =>
            prev.map((rec) =>
              rec._id?.$oid === result?.updated_document?._id?.$oid
                ? { ...rec, ...result.updated_document }
                : rec
            )
          );
        }
      } catch (error) {
        message.error("Error while updating note!");
      }
    } else {
      let newNote = {
        key: uuidv4(),
        user: userInfoStore.userName,
        date: moment().format("YYYY-MM-DD HH:mm:ss"),
        text: "markedAsEstimated",
        isAddedByAdmin: true,
        field: currentField.currentField,
        username: userInfoStore.userID,
        isAcknowledged: false,
        noteType: "estimatedBoolean",
        markedAsEstimated: true,
      };

      try {
        addRecordNote(recordId, newNote, true).then((res) => {
          loadDataSheetRecords({
            pagination,
            conditionalFilters: search.conditionalFilters,
            sorter,
          });
        });
      } catch (error) {
        message.error("Error while updating note!");
      }
    }
  };
  const handleOpenNotesDrawer = (
    currentField: string,
    currentFieldName: string,
    value: any,
    recordId: string,
    currentTab: "comment" | "warning" | "estimate"
  ) => {
    if (!currentField.endsWith("_unit_id")) {
      setCurrentField({
        currentField,
        currentFieldName,
        value,
        recordId,
        currentTab,
      });
      showFieldNotesDrawer();
    }
  };

  const handleCloseUpload = () => {
    setShowDataSheetFilesModal(false);
    loadDataSheetRecords({
      pagination,
      conditionalFilters: search.conditionalFilters,
      sorter,
    });
  };

  const handleCloseUploadDataSheetAttached = () => {
    setShowDataSheetAttachedModal(false);
  };

  const handleResizeColumn =
    (column: any) =>
    (e: any, { size }: { size: any }) => {
      const newManageColumnData = manageColumnData?.map((data) =>
        data?.dataIndex === column?.dataIndex
          ? { ...data, width: size?.width }
          : data
      );
      setManageColumnData(newManageColumnData);
      setDataSheetSettings((prev: any) => ({
        ...prev,
        columns: newManageColumnData,
      }));
      handleSaveSettings({
        columns: newManageColumnData,
        conditionalFilters:
          isPending || isDenied || isDeleted
            ? dataSheetSettings?.conditionalFilters || null
            : search.conditionalFilters,
        sorter:
          isPending || isDenied || isDeleted
            ? dataSheetSettings?.sorter || null
            : sorter,
        rowSize,
      });
    };

  const generateDynamicColumns = (
    dataSheetSchema: SheetSchema[],
    expandedData?: DataSheetRecord[],
    parentRecord?: DataSheetRecord
  ) =>
    TableColumns({
      dataSheet,
      dataSheetSchema,
      manageColumnData,
      loadingFields,
      disabledFields,
      identifierConnectedValues,
      metadataSheet,
      handleChangeIdentifierField,
      companyDetails,
      newRecordKey,
      isDataQualityScoreShown,
      editingKey,
      onAddNewRecordFiles,
      submitting,
      handleConfirmCreateRecord,
      handleCancelCreatingRecord,
      lastRevisionData,
      isLatestRevision,
      onClickUpdateRecord,
      onCancelHandler,
      editReason,
      setEditReason,
      onClickDataSheetFileBtn,
      onClickAttachedFileBtn,
      onChangeHistoryHandler,
      onClickEditRecord,
      onClickRecordLockBtn,
      onClickDeleteRestoreRecord,
      onUpdateNotes,
      onClickUpdateRecordNotes,
      isDeleted,
      isPending,
      isDenied,
      onClickChangeApprovalStatusRecord,
      onSubmitOwners,
      usersList,
      groupsList,
      loadingSheetRecords,
      handleOpenNotesDrawer,
      expandedData,
      parentRecord,
      handleAddFieldNote,
      handleViewRichText,
      handleResizeColumn,
    });

  const generateColumns = (
    expandedData?: DataSheetRecord[],
    parentRecord?: DataSheetRecord
  ): any[] =>
    generateDynamicColumns(
      dataSheet?.sheet_schema!,
      expandedData,
      parentRecord
    );

  const getColumnsSorted = (colForSort: any[], manageColumnData: any) => {
    const strAscending = [...colForSort].sort((a, b) => {
      const keys = (manageColumnData || []).map((item: any) => item.dataIndex);
      keys.push("dataQualityScore");
      keys.push("action");

      return keys.indexOf(a.dataIndex) - keys.indexOf(b.dataIndex);
    });
    return strAscending;
  };

  const colSorted = (
    expandedData?: DataSheetRecord[],
    parentRecord?: DataSheetRecord
  ) =>
    getColumnsSorted(
      generateColumns(expandedData, parentRecord),
      manageColumnData
    )
      .map((col: any) => (col.children ? col.children : col))
      .flat();

  const mergedColumns = (
    expandedData?: DataSheetRecord[],
    parentRecord?: DataSheetRecord
  ) => 
    generateEditableCell(
      colSorted(expandedData, parentRecord),
      editingKey,
      lastRevisionData
    )
      ?.filter((item: any) => !item.hidden )
      ?.filter((item:any) => !(!dataSheet.is_kpi_data && item.dataIndex == "userEnteredKPIValue") )
      ?.map((v: any) => ({
        ...v,
        width:
          manageColumnData?.find((col) => col.dataIndex === v.dataIndex)
            ?.width || v.width,
      }));
    
  const handleCollapse = (key: string | string[]) => {
    if (isGeneral) {
      setCollapsedPanel(key as string);
      const dataSheet = dataSheetsList?.find(
        (item) => item?._id?.$oid === (key as string)
      );
      setDataSheet(dataSheet!);
    }
  };

  const identifierColumn = useMemo(() => {
    return colSorted()?.find((column: any) => column.isIdentifierConnected)
      ?.schemaField?.entity_name;
  }, [colSorted()]);
  const recordsTypes = {
    include: [],
    exclude: [
      RecordTypes.ARCHIVED,
      RecordTypes.IMPORTED_PENDING,
      RecordTypes.PENDING,
    ],
  };

  mergedColumns().splice(0, 0, Table.SELECTION_COLUMN);
  !!isPending &&
    !!identifierColumn &&
    mergedColumns().splice(1, 0, Table.EXPAND_COLUMN);

  const getExpandedData = async (
    record: DataSheetRecord,
    pagination?: Pagination,
    sorter?: any
  ) => {
    try {
      setLoadingExpandData(true);
      const sorterColumn = dataSheet?.sheet_schema?.find(
        (item) => item.input_type === "date"
      )?.entity_name;
      const defaultSorter = sorterColumn && {
        order: "descend",
        field: sorterColumn,
      };
      const requestPayload = makeQuery(
        {
          pagination: pagination || paginationExpand,
          filters: [
            {
              column: identifierColumn,
              op: "$eq",
              column_value: record[dataSheet?.sheet_name!][identifierColumn],
            },
          ],
          sorter: sorter || defaultSorter,
        },
        dataSheet,
        recordsTypes
      );
      const sheetRecords = await fetchRecordData(requestPayload);
      const records = sheetRecords.data;
      setExpandData(records);
      setPaginationExpand({
        ...(pagination || paginationExpand),
        total: sheetRecords.filteredCount,
        showSizeChanger: sheetRecords.filteredCount > 10,
      });
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingExpandData(false);
    }
  }; 
  const handleExpandRow = (expanded: boolean, record: DataSheetRecord) => {
    setExpandedRows([record._id.$oid]);
    if (expanded) {
      dataSheet && record && getExpandedData(record);
    } else {
      setExpandData(null);
      setExpandedRows([]);
    }
  };
  const handleExpandedTableChange = (
    pagination: Pagination,
    _filters: any,
    sorter: any,
    record: DataSheetRecord
  ) => {
    getExpandedData(record, pagination, sorter);
  };

  const expandedTable = dataSheet
    ? {
        onExpand: (expanded: boolean, record: DataSheetRecord) =>
          handleExpandRow(expanded, record),
        showExpandColumn: !!isPending && !!identifierColumn,
        defaultExpandedRowKeys: expandedRows,
        expandedRowKeys: expandedRows,
        expandedRowRender: (record: DataSheetRecord) =>
          record &&
          record?._id.$oid && (
            <CommonTable
              columns={mergedColumns(expandData, record)}
              dataSource={expandData}
              rowHeight={rowSize}
              headerHeight={rowSize}
              loading={loadingExpandData}
              onChange={(pagination: Pagination, _filters: any, sorter: any) =>
                handleExpandedTableChange(pagination, _filters, sorter, record)
              }
              pagination={paginationExpand}
              scroll={{ x: "max-content" }}
            />
          ),
        rowExpandable: (_: any) => !!isPending && !!identifierColumn,
      }
    : null;
    
  return (
    <>
      <Spin spinning={isGeneral ? loadingDataSheetsList : loadingDataSheet}>
        {showImportDataModal && (
          <ImportedDataModal
            isSuccess={(state as any).messageType === "submitted"}
            visible={showImportDataModal}
            onClose={() => {
              setShowImportDataModal(false);
              navigate(
                `/data/data-sheets/${dataSheet?._id?.$oid}/${encodeUrlName(
                  dataSheet?.sheet_name.replace("/", "")
                )}/documents`,
                { state: null }
              );
            }}
          />
        )}
        {showUpdateRecordModal && (
          <CreateRecordModal
            identifier={
              metadataSheet?.sheet_schema.find((value) => value.isIdentifier)
                ?.entity_name
            }
            metaDataRecords={metaDataRecords}
            identifierConnectedValues={identifierConnectedValues}
            visible={showUpdateRecordModal}
            initialData={rowData!}
            onClose={() => setShowUpdateRecordModal(false)}
            onFinish={onFinishUpdateData}
            dataSheet={dataSheet!}
          />
        )}
        {isRichTextModalVisible && (
          <RichTextModal
            fieldName={currentField.currentField}
            fieldDisplayName={currentField.currentFieldName}
            data={currentField.value}
            visible={isRichTextModalVisible}
            onVisibleChange={setIsRichTextModalVisible}
            isViewOnly={!editingKey && !newRecordKey}
            onConfirm={handleEditRichText}
          />
        )}
        {showDataSheetAttachedModal && (
          <SheetAttachedModal
            visible={showDataSheetAttachedModal}
            rowData={rowData}
            onClose={handleCloseUploadDataSheetAttached}
            uploadEnabled={!checkRolesPermission(["role:auditor"])}
          />
        )}
        {showDataSheetFilesModal && (
          <SheetFilesModal
            visible={showDataSheetFilesModal}
            rowData={rowData}
            onClose={handleCloseUpload}
            uploadEnabled={!checkRolesPermission(["role:auditor"])}
          />
        )}
        {showNewRecordFilesModal && (
          <NewSheetFilesModal
            visible={showNewRecordFilesModal}
            rowData={rowData}
            onClose={() => setShowNewRecordFilesModal(false)}
            handleSave={handleAddFiles}
          />
        )}

        <PageWrap>
          <>
            {dataSheet && dataSheet?.archive ? (
              <Alert
                message="Sorry, you cannot access this datasheet as this is archived!!"
                type="info"
                showIcon
              />
            ) : (
              <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <TableWrap>
                    {dataSheet && !isDeleted && !isDenied && !isGeneral && (
                      <SheetSchemaDatatable
                        onClickExportWithAudit={onClickExportWithAudit}
                        onClickExportExcel={onClickExportExcel}
                        exportLoader={exportLoader}
                        setExportLoader={setExportLoader}
                        dataSheet={dataSheet}
                        isDisabled={!dataSheetRecords.length}
                        onFinishUploadInsertDataFile={
                          onFinishUploadInsertDataFile
                        }
                        showCheckingModal={showCheckingModal}
                        metaDataRecords={metaDataRecords}
                        deletedCount={countRecords.archive}
                        pendingCount={countRecords.pending}
                        deniedCount={countRecords.denied}
                        totalCount={pagination.total}
                        isPending={isPending}
                        identifierColumn={identifierColumn}
                        filters={{ ...search, selectedRows }}
                        allFields={allFields}
                      />
                    )}
                  </TableWrap>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <FormWrapper>
                    <Form
                      form={form}
                      component={false}
                      onFieldsChange={() => handleFormChange()}
                    >
                      <TableWrap className="dataSheet">
                        <Collapse
                          onChange={handleCollapse}
                          activeKey={collapsedPanel}
                          accordion
                          expandIcon={({ isActive }) =>
                            isGeneral ? (
                              isActive ? (
                                <MinusSquareOutlined />
                              ) : (
                                <PlusSquareOutlined />
                              )
                            ) : null
                          }
                          bordered={false}
                          collapsible="header"
                          ghost
                        >
                          {dataSheetsList?.map((item: DataSheet) => {
                            return (
                              <Collapse.Panel
                                key={item._id.$oid}
                                extra={
                                  isGeneral &&
                                  dataSheet?._id.$oid === item._id.$oid && (
                                    <StyledButton
                                      type="custom"
                                      className="margin-5"
                                      icon={<FileOutlined />}
                                      onClick={onClickExportExcel}
                                      disabled={!dataSheetRecords.length}
                                      loading={exportLoader}
                                    >
                                      Export Excel
                                    </StyledButton>
                                  )
                                }
                                header={
                                  <div className={styles.title}>
                                    <Typography.Title
                                      className={styles.titleDescription}
                                      level={4}
                                    >
                                      {isGeneral && item?.sheet_name}
                                    </Typography.Title>
                                  </div>
                                }
                              >
                                {dataSheet &&
                                collapsedPanel === dataSheet?._id.$oid ? (
                                  <CommonTable
                                    title={() => (
                                      <TableHeader
                                        dataSheet={dataSheet}
                                        pagination={pagination}
                                        isDataQualityScoreShown={
                                          isDataQualityScoreShown
                                        }
                                        toggleDataQualityScore={
                                          toggleDataQualityScore
                                        }
                                        rowSize={rowSize}
                                        handleChangeSize={handleChangeSize}
                                        isPending={isPending}
                                        isDenied={isDenied}
                                        isDeleted={isDeleted}
                                        isGeneral={isGeneral}
                                        selectedRows={selectedRows}
                                        handleMassChangeStatus={
                                          handleMassChangeStatus
                                        }
                                        handleChangeAllStatus={
                                          handleChangeAllStatus
                                        }
                                        handleChangeLockRecords={
                                          handleChangeLockRecords
                                        }
                                        onConfirmCreateExpression={
                                          handleConfirmCreateExpression
                                        }
                                        sortRecords={sortRecords}
                                        setSortRecords={setSortRecords}
                                        onSort={handleSort}
                                        search={search}
                                        onApplyFilters={onApplyFilters}
                                        onResetFilters={onResetFilters}
                                        manageColumnData={manageColumnData}
                                        handleSwitch={handleSwitch}
                                        onSortEnd={onSortEnd}
                                        handleMassCheck={handleMassCheck}
                                        onCheckedItem={onCheckedItem}
                                      />
                                    )}
                                    rowSelection={rowSelection}
                                    loading={loadingSheetRecords}
                                    rowKey={(data: any) => {
                                      const id =
                                        data?.["_id"]?.["$oid"] || data?.key;
                                      return id;
                                    }}
                                    components={{
                                      header: {
                                        cell: ResizableHeaderCell,
                                      },
                                      body: {
                                        cell: EditableCell,
                                      },
                                    }}
                                    dataSource={dataSheetRecords}
                                    columns={mergedColumns()}
                                    rowClassName={(record: DataSheetRecord) =>
                                      `${
                                        record?.isLocked && styles.bgLocked
                                      } dataSheetsTable ${
                                        isDataQualityScoreShown
                                          ? "actionsSecondRightBorderRow"
                                          : "actionsRightBorderRow"
                                      }  ${styles.editIconRow}`
                                    }
                                    scroll={{ x: "max-content" }}
                                    sticky
                                    pagination={pagination}
                                    rowHeight={rowSize}
                                    headerHeight={rowSize}
                                    onChange={handleTableChange}
                                    className={`tableContiner ${styles.borderedHeaderTable} tableFixedHeaderContainer`}
                                    expandable={expandedTable}
                                    footer={() =>
                                      !!dataSheet &&
                                      !isDeleted &&
                                      !isDenied &&
                                      !isPending &&
                                      !checkRolesPermission([
                                        "role:auditor",
                                      ]) && (
                                        <TableFooter
                                          onClickAddEmptyLine={
                                            onClickAddEmptyLine
                                          }
                                          newRecordKey={newRecordKey}
                                          metadataSheet={metadataSheet}
                                          metaDataRecords={metaDataRecords}
                                          identifierConnectedValues={
                                            identifierConnectedValues
                                          }
                                          onFinishCreateNewData={
                                            onFinishCreateNewData
                                          }
                                          dataSheet={dataSheet}
                                        />
                                      )
                                    }
                                  />
                                ) : null}
                              </Collapse.Panel>
                            );
                          })}
                        </Collapse>
                      </TableWrap>
                    </Form>
                  </FormWrapper>
                  {dataSheet &&
                    !!dataSheetRecords.length &&
                    !isDeleted &&
                    !isPending &&
                    !isDenied &&
                    !isGeneral &&
                    !checkRolesPermission(["role:auditor"]) && (
                      <DataSheetAggregateAnalytics
                        dataSheet={dataSheet}
                        filteringMatch={filterData}
                        refreshDataSheetRecords={refreshDataSheetRecords}
                        decimalPlacesNumber={
                          companyDetails.decimal_places_number
                        }
                      />
                    )}
                </Col>
              </Row>
            )}
          </>
          {!!metaDataRecords?.length && (
            <AskPeriodModal
              visible={isShownCheckingModal}
              loading={isMissingDataSheetsLoading}
              dataSheet={dataSheet!}
              existingLocations={existingLocationsNumber}
              onCount={handleCountMissingLocations}
              onClose={hideCheckingModal}
              onConfirm={handleAddMissingLocations}
            />
          )}
          {isFieldNotesDrawerShown && (
            <NotesDrawer
              visible={isFieldNotesDrawerShown}
              data={[currentField.currentFieldName, currentField.value]}
              notes={
                dataSheetRecords
                  ?.find((item) => item._id.$oid === currentField.recordId)
                  ?.field_notes?.filter(
                    (note) => note.field === currentField.currentField
                  ) || []
              }
              handleMarkAsEstimatedChange={() => {
                let item: any = dataSheetRecords?.find(
                  (item) => item._id.$oid === currentField.recordId
                );
                handleMarkAsEstimated(item?._id.$oid);
              }}
              loading={loadingSheetRecords}
              onClose={handleCloseDrawerNotes}
              onSubmit={(
                note: string,
                noteType: "comment" | "warning" | "estimate"
              ) => {
                let item: any = dataSheetRecords?.find(
                  (item) => item._id.$oid === currentField.recordId
                );

                // handleCloseDrawerNotes();
                handleAddFieldNote(
                  currentField.currentField,
                  item?._id.$oid,
                  note,
                  noteType
                );
              }}
              onUpdateNotes={handleChangeFieldNotes}
              isFieldNote={true}
              incomingCurrentTab={currentField.currentTab}
            />
          )}
        </PageWrap>
      </Spin>
    </>
  );
});
