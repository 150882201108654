import { Auth, API } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";

export const getSurveys = async (payload) => {
  const response = await API.graphql({
    query: queries["listSurveyManagements"],
    variables: payload,
  });
  let items = response.data.listSurveyManagements.items;
  let nextToken = response.data.listSurveyManagements.nextToken;

  while (nextToken) {
    const nextPage = await API.graphql({
      query: queries["listSurveyManagements"],
      variables: {
        nextToken: nextToken,
        filter: payload?.filter,
      },
    });

    items = items.concat(nextPage.data.listSurveyManagements.items);
    nextToken = nextPage.data.listSurveyManagements.nextToken;
  }
  return items;
};

export const getSurveyById = async (surveyId) => {
  const response = await API.graphql({
    query: queries["getSurveyManagement"],
    variables: {
      id: surveyId,
    },
  });

  return response["data"]["getSurveyManagement"];
};

export const addSurveys = async (payload) => {
  const data = await Auth.currentSession();
  const group = data["accessToken"]["payload"]["cognito:groups"].filter(
    (element) => element.includes("org:")
  )[0];
  const username = data["accessToken"]["payload"]["username"];
  const newPayload = { ...payload, group: group, owner: username };

  const response = await API.graphql({
    query: mutations["createSurveyManagement"],
    variables: {
      input: newPayload,
    },
  });
  return response;
};

export const updateSurveys = async (payload) => {
  const data = await Auth.currentSession();
  const group = data["accessToken"]["payload"]["cognito:groups"].filter(
    (element) => element.includes("org:")
  )[0];
  const username = data["accessToken"]["payload"]["username"];
  const newPayload = { ...payload, group: group, owner: username };

  const response = await API.graphql({
    query: mutations["updateSurveyManagement"],
    variables: {
      input: newPayload,
    },
  });

  return response;
};

export const removeSurveys = async (payload) => {
  const data = await Auth.currentSession();
  const group = data["accessToken"]["payload"]["cognito:groups"].filter(
    (element) => element.includes("org:")
  )[0];
  const username = data["accessToken"]["payload"]["username"];
  const newPayload = { ...payload, group: group, owner: username, status: "DELETED" };

  const response = await API.graphql({
    query: mutations["updateSurveyManagement"],
    variables: {
      input: newPayload,
    },
  });

  return response;
};

export const getSurveysList = async () => {
  const response = await API.graphql({
    query: queries["listSurveyManagements"],
  });

  return response["data"]["listSurveyManagements"];
};

export const getSurveyMetrics = async () => {
  const response = await API.graphql({
    query: queries["getSurveyMetrics"],
    variables: {
      request_type: "SURVEY_METRICS",
    },
  });

  return response["data"]["getSurveyMetrics"];
};
