import React, { useEffect, useState } from "react";
import {
  FormWrap,
  PageWrap,
  StyledButton,
  StyledSteps,
} from "../../shared/commonStyles";
import { Col, Form, Row, Space, Spin } from "antd";
import { useNavigate } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { GeneralInfo } from "./KPISheetComponents/GeneralInfo";
import styles from "./CreateStandardsDataSheet.module.scss";
import { Auth } from "aws-amplify";
import {
  createDataSheet,
  getDatasheetFilters,
  insertData,
} from "../../services/dataSheet";
import { isValidJSONString } from "../../shared/commonFunctions";
import Success from "./components/Success";
export const CreateKPIDataSheet = () => {
  const [loadDataSheets, setLoadDataSheets] = useState(false);
  const [sheetNames, setSheetNames] = useState([]);
  const [initialValues, setInitialValues] = useState<DataSheet>({
    is_reference_data: false,
    is_standard_data: false,
    is_kpi_data: true,
    sheet_name: "",
    description: "",
    enable_comments: true,
    enable_change_history: true,
    sheet_schema: [
      {
        input_type: "string",
        display_name: "KPI Name",
        entity_name: "kpi_name",
        data_type: "string",
        added: true,
      },
      {
        input_type: "long-answer",
        display_name: "KPI Description",
        entity_name: "kpi_description",
        data_type: "long-answer",
        added: true,
      },
      {
        input_type: "number",
        display_name: "KPI Value",
        entity_name: "kpi_value",
        data_type: "number",
        added: true,
      },
    ],

    archive: false,
  } as DataSheet);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [sheetCreationSuccess, setSheetCreationSuccess] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const getSheetNames = async () => {
    setLoadDataSheets(true);
    const dataSheetsList = await getDatasheetFilters();
    if (dataSheetsList?.sheet_name?.length) {
      setSheetNames(dataSheetsList?.sheet_name);
    }
    setLoadDataSheets(false);
  };

  useEffect(() => {
    getSheetNames();
  }, []);

  const loadCompanyInfo = async () => {
    try {
      const data = await Auth.currentSession();
      const group = data
        .getAccessToken()
        ["payload"]["cognito:groups"]?.filter((element: any) =>
          element.includes("org:")
        )[0]
        .replace("org:", "");

      setInitialValues((prevValues) => ({
        ...prevValues,
        sheet_name: `${group} - ${new Date().getFullYear()} KPIs`,
      }));
      setDataLoaded(true);
    } catch (error) {
      console.log("Error while load company details", error);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoadDataSheets(true);
      const payload = { ...initialValues };
      const response = await createDataSheet(payload);
      const datalake = isValidJSONString(response["data"]["datalake"]);
      if (datalake["_id"]["$oid"]) {
        setInitialValues((prev) => ({ ...prev, _id: datalake["_id"] }));
        // const records = getRecordsForTemplate(initialValues.sheet_type);
        const recordsPayload = {
          sheet_id: datalake["_id"]["$oid"],
          sheet_name: initialValues.sheet_name,
          // documents: records,
        };
        await insertData(recordsPayload).then(() =>
          setSheetCreationSuccess(true)
        );
      }

      setLoadDataSheets(false);
    } catch (e) {
      setLoadDataSheets(false);

      console.log("e", e);
    }
  };

  useEffect(() => {
    loadCompanyInfo();
  }, []);

  const onChangeFieldsData = () => {
    const formValues = form.getFieldsValue(true);
    setInitialValues(formValues);
  };

  return (
    <PageWrap>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Spin spinning={loadDataSheets}>
          <Row gutter={[16, 16]} className="exitAlign">
            <Col lg={1} md={24} sm={24} xs={24}>
              <StyledButton
                style={{ zIndex: "1" }}
                type="custom"
                onClick={() => navigate("/data/data-sheets")}
                hovercolor="#A68DFB"
                hoverbgcolor="transparent"
                bgcolor="transparent"
                bordercolor="transparent"
                color="#fff"
              >
                <ArrowLeftOutlined /> <span>Exit</span>
              </StyledButton>
            </Col>

            {!sheetCreationSuccess && (
              <Col
                className="user-flow-steps-wrap color-white"
                lg={23}
                md={24}
                sm={24}
                xs={24}
              >
                <StyledSteps current={0}>
                  <>
                    <StyledSteps.Step
                      className="stepper-wrapper"
                      key={1}
                      title={<span>General information</span>}
                    />
                  </>
                </StyledSteps>
              </Col>
            )}
          </Row>
          <Row>
            <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: 20 }}>
              <div className="form-wrap">
                {dataLoaded && (
                  <Form
                    form={form}
                    initialValues={initialValues}
                    onFieldsChange={onChangeFieldsData}
                  >
                    {sheetCreationSuccess ? (
                      <Success
                        key="success"
                        sheetId={initialValues._id?.$oid}
                        sheetName={initialValues.sheet_name}
                      />
                    ) : (
                      <FormWrap width={"50%"} className={styles.wrapper}>
                        <GeneralInfo
                          key="general-info"
                          initialValues={initialValues}
                          sheetNames={sheetNames}
                        />
                        <Row justify="start" style={{ marginTop: "20px" }}>
                          <StyledButton
                            className="margin-5"
                            disabled={false}
                            onClick={handleSubmit}
                            type="custom"
                          >
                            Create KPI Data Sheet
                          </StyledButton>
                        </Row>
                      </FormWrap>
                    )}
                  </Form>
                )}
              </div>
            </Col>
          </Row>
        </Spin>
      </Space>
    </PageWrap>
  );
};
