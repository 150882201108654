import { Col, Form, Row, Switch, Typography } from "antd";
import React, { useState } from "react";
import { CommonTable, StyledButton } from "../../../shared/commonStyles";
import { MenuOutlined } from "@ant-design/icons";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { siteThemeColors } from "../../../shared/colors";
import { KPIsFormPreview } from "./KPIsFormPreview";

interface SurveyOrderProps {
  onSortSurveyOrder: (data: { oldIndex: number; newIndex: number }) => void;
  selectedKPIs: any[];
  surveyData: any;
  onRequiredChange: (kpiName: string, checked: boolean) => void;
}

export const SurveyOrder = ({
  onSortSurveyOrder,
  selectedKPIs,
  surveyData,
  onRequiredChange,
}: SurveyOrderProps) => {
  selectedKPIs = selectedKPIs.map((item, index) => {
    item.kpi_name = item[item.sheet_name].kpi_name;
    return item;
  });

  const [showFormViewModal, setShowFormViewModal] = useState(false);
  const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
  ));

  const SortableItem = SortableElement((props: any) => <tr {...props} />);
  const SortableContain = SortableContainer((props: any) => (
    <tbody {...props} />
  ));

  const DraggableContainer = (props: any) => (
    <SortableContain
      useDragHandle
      disableAutoscroll
      helperClass="rowDrag"
      onSortEnd={onSortSurveyOrder}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
    const index = selectedKPIs.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return (
      <SortableItem
        index={index}
        {...restProps}
        style={{ color: siteThemeColors.TableColor.color }}
      />
    );
  };

  const columns = [
    {
      title: "",
      dataIndex: "sort",
      width: "5%",
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "KPI Name",
      dataIndex: "kpi_name",
      width: "25%",
      className: "drag-visible",
    },
    {
      title: "Required",
      dataIndex: "required",
      width: "5%",
      className: "drag-visible",
      render: (_: any, record: any) => {
        return (
          <Switch
            checked={record.required === true}
            onChange={(checked) => {
              onRequiredChange(record[record.sheet_name].kpi_name, checked);
            }}
          />
        );
      },
    },

    {
      title: "Order",
      dataIndex: "index",
      width: "5%",
      className: "drag-visible",
      render: (record: any) => {
        return record + 1;
      },
    },
  ];
  return (
    <>
      <KPIsFormPreview
        visible={showFormViewModal}
        onClose={() => setShowFormViewModal(false)}
        surveyData={surveyData}
        selectedKPIs={selectedKPIs}
        onRequiredChange={onRequiredChange}
      />
      <Row justify="space-between">
        <Col lg={24} md={24} sm={24} xs={24}>
          <Form.Item>
            <Typography.Title level={3} className="color-white">
              Survey order
            </Typography.Title>
            <Row>
              <Col span={12}>
                <Typography.Title level={4}>
                  Change order of the surveys inside the flow if needed
                </Typography.Title>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <StyledButton
                  type="custom"
                  onClick={() => setShowFormViewModal(true)}
                >
                  <span>Open preview</span>
                </StyledButton>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item>
            <CommonTable
              pagination={false}
              dataSource={selectedKPIs}
              columns={columns}
              rowKey="index"
              scroll={{ x: true }}
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
