import React from "react";
import {
  CommonModal,
  FormWrapper,
  StyledButton,
  StyledDivider,
} from "../../../shared/commonStyles";
import { Checkbox, Col, Form, InputNumber, Row, Space, Typography } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import styles from "./SurveyPreview.module.scss";

interface KPIsFormPreviewProps {
  visible: boolean;
  onClose: () => void;
  surveyData: any;
  selectedKPIs: any[];
  onRequiredChange: (kpiName: string, checked: boolean) => void;
}

export const KPIsFormPreview = ({
  visible,
  onClose,
  surveyData,
  selectedKPIs,
  onRequiredChange,
}: KPIsFormPreviewProps) => {
  return (
    <CommonModal
      title="Survey Preview"
      visible={visible}
      onCancel={onClose}
      width="70%"
      maskClosable={false}
      destroyOnClose
      footer={null}
      centered
    >
      <FormWrapper>
        <Row>
          <Col
            span={16}
            style={{ padding: 10, backgroundColor: "#3C3453" }}
            className={`preview-left-part ${styles.previewForm}`}
          >
            <div>
              <Typography.Title className="color-white" level={3}>
                {surveyData?.name || ""}{" "}
                <span className="color-grey">(View Only)</span>
              </Typography.Title>
              <Form layout="vertical">
                {selectedKPIs?.map((kpi) => (
                  <Row key={kpi._id.$oiod}>
                    <Col span={24}>
                      <Form.Item
                        labelCol={{ span: 24 }}
                        label={
                          <Col span={24} style={{ width: "100%" }}>
                            <Row
                              justify="space-between"
                              align="middle"
                              style={{
                                width: "100%",
                                flexWrap: "nowrap",
                              }}
                            >
                              <Col style={{ flex: "1" }}>
                                {kpi[kpi.sheet_name].kpi_name}
                              </Col>
                              <Col>
                                <Checkbox
                                  checked={kpi.required === true}
                                  onChange={(e) => {
                                    if (onRequiredChange) {
                                      onRequiredChange(
                                        kpi[kpi.sheet_name].kpi_name,
                                        e.target.checked
                                      );
                                    }
                                  }}
                                >
                                  Required
                                </Checkbox>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={24}>
                                <StyledDivider />
                              </Col>
                            </Row>
                          </Col>
                        }
                      >
                        <InputNumber placeholder="0" />
                      </Form.Item>
                      <Row>
                        <Col span={24}>
                          <span className="color-grey font-14">
                            Attach files that are related to this KPI
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <StyledButton>
                            <UploadOutlined /> Upload
                          </StyledButton>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ))}
              </Form>
            </div>
          </Col>
          <Col span={8} className="preview-right-part">
            <Space
              direction="vertical"
              style={{
                justifyContent: "space-between",
                height: "100%",
                width: "100%",
              }}
            >
              <div>
                <Typography.Title className="color-white " level={4}>
                  Overview
                </Typography.Title>
                <div
                  className="color-white"
                  style={{
                    lineHeight: "1.5",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxHeight: "30%",
                    wordWrap: "normal",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: surveyData?.description,
                  }}
                ></div>
              </div>

              <div>
                <StyledButton key="1" type="custom" onClick={() => onClose()}>
                  Close Preview
                </StyledButton>
              </div>
            </Space>
          </Col>
        </Row>
      </FormWrapper>
    </CommonModal>
  );
};
