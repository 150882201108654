import React, { useState } from "react";
import { StyledButton, TableCommonMenu } from "../../../../shared/commonStyles";
import { FileOutlined, UploadOutlined, DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import styles from "../../../viewSurveyForAdmin/Components/SurveyView.module.scss";
import { useCheckRolesPermissions } from "../../../../hooks/useCheckRolesPermissions";
import { toJS } from "mobx";
import { downloadSingleSheetTemplate } from "../../../../services/importData";
import { message, Badge, Dropdown, Menu } from "antd";
// import atob from "atob";

interface MassUploadDataSheetProps {
  dataSheet: any;
  onFinishUploadInsertDataFile: () => void;
  showUploadExcelButton: boolean;
  showSampleExcelButton: boolean;
  disabledUpload: boolean;
  taskDeploymentId?: string;
  defaultMetaDataRecord: any;
  surveyId: string;
  currentStep: number;
  type: string;
  rowData: any;
  setLoaderButton?: (loading: boolean) => void;
}

const MassUploadDataSheet: React.FC<MassUploadDataSheetProps> = ({
  dataSheet,
  onFinishUploadInsertDataFile,
  showUploadExcelButton,
  showSampleExcelButton,
  disabledUpload,
  taskDeploymentId = "",
  defaultMetaDataRecord,
  surveyId,
  currentStep,
  type,
  rowData,
  setLoaderButton = null,
}) => {
  const [loaderUploadFile, setLoaderUploadFile] = useState<boolean>(false);
  const { checkRolesPermission } = useCheckRolesPermissions();

  const navigate = useNavigate();

  const onClickExportExcel = async () => {
  let exclude_fields: String[] = [];
    const baseRequiredFields = [
      "origination_city",
      "destination_city",
      "routing",
      "distance",
    ];

    // Check for either journey_type OR type_of_journey
    const journeyFields = ["journey_type", "type_of_journey"];

    if (!dataSheet) {
      return;
    }

    // Extract entity names from the sheet schema
    const schemaEntityNames = new Set(
      dataSheet.sheet_schema.map((field: any) => field.entity_name)
    );

    // Check if at least one of the journey fields exists
    const hasValidJourneyField = journeyFields.some((field) =>
      schemaEntityNames.has(field)
    );

    // Find missing fields excluding journey fields
    const missingBaseFields = baseRequiredFields.filter(
      (field) => !schemaEntityNames.has(field)
    );

    // Validation passes if base fields are present AND exactly one journey field exists
    if (missingBaseFields.length === 0 && hasValidJourneyField) {
      exclude_fields = ["routing", "distance", "distance_unit_id"];
    }
    

    setLoaderUploadFile(true);
    setLoaderButton && setLoaderButton(true);
    try {
      const resp = await downloadSingleSheetTemplate(
        dataSheet?._id?.$oid,
        type,
        rowData?.display_fields,
        exclude_fields
      );

      if (resp && resp.statusCode === 200) {
        let fileName = "";
        const filenameMatch =
          resp?.headers?.["Content-Disposition"]?.match(/filename=([^;\n]+)/);
        if (filenameMatch) {
          fileName = filenameMatch[1];
        }
        const binaryString = atob(resp.body);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([bytes], {
          type: resp?.headers?.["Content-Type"],
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (resp.statusCode >= 400) {
        message.error(resp?.body);
      }
    } catch (e) {
      message.error("Something went wrong while downloading sample excel");
    } finally {
      setLoaderUploadFile(false);
      setLoaderButton && setLoaderButton(false);
    }
  };

  const moreButtons = (
    <TableCommonMenu>
      <Menu.Item key="uploadExcel">
        <StyledButton
          type="custom"
          onClick={() =>
            navigate("/data/import-data/import-new-data", {
              state: {
                dataSheet: toJS(dataSheet),
                type,
                surveyId,
                defaultMetaDataRecord: toJS(defaultMetaDataRecord),
                currentStep,
                displayFields: toJS(rowData?.display_fields),
                taskDeploymentId,
              },
            })
          }
        >
          Upload Excel
        </StyledButton>
      </Menu.Item>
      <Menu.Item key="uploadOCR">
        <StyledButton
          type="custom"
          disabled={dataSheet?.ocr_profiles?.length > 0 ? false : true}
          onClick={() =>
            navigate("/data/import-data/import-new-data", {
              state: {
                dataSheet: toJS(dataSheet),
                type,
                surveyId,
                defaultMetaDataRecord: toJS(defaultMetaDataRecord),
                currentStep,
                displayFields: toJS(rowData?.display_fields),
                taskDeploymentId,
                isOcrUpload: true,
              },
            })
          }
        >
          Upload OCR
        </StyledButton>
      </Menu.Item>
    </TableCommonMenu>
  );

  return (
    <>
      {showSampleExcelButton && (
        <StyledButton
          type="custom"
          loading={loaderUploadFile}
          className={` ${styles.uploadButton}`}
          icon={<FileOutlined />}
          onClick={onClickExportExcel}
        >
          <span>
            {type === "dataSheet" ? "Export Template" : "Export Sample Excel"}
          </span>
        </StyledButton>
      )}
      {showUploadExcelButton && !checkRolesPermission(["role:auditor"]) && (
        <Dropdown overlay={moreButtons} trigger={["click"]}>
          <StyledButton
            disabled={disabledUpload}
            className={`${styles.uploadButton}`}
            type="custom"
            loading={loaderUploadFile}
            icon={<UploadOutlined />}
          >
            <span> Upload </span>
          </StyledButton>
        </Dropdown>
      )}
    </>
  );
};

export default MassUploadDataSheet;
