import React, { ChangeEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  CaretDownOutlined,
  CaretRightOutlined,
  CheckOutlined,
  CloseOutlined,
  EllipsisOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  Popover,
  InputNumber,
  Input,
  Progress,
  message,
  Row,
  Button,
  Spin,
  Table,
} from "antd";
import { purple } from "@ant-design/colors";
import { useDynamicList } from "ahooks";
import {
  CommonTable,
  FormWrapper,
  StyledButton,
} from "../../../../shared/commonStyles";
import { observer } from "mobx-react-lite";
import {
  fractionalNumber,
  getEmissionsUnit,
  getLinkDataSheet,
  removeNullKeys,
} from "../../../Carbon/CalculationUtils";
import { toJS } from "mobx";
import {
  calculatorStore,
  calculate_emissions_total as calculatore_store_calculate_emissions_total,
} from "../../../Carbon/CalculatorStore";
import { carbonStore } from "../../../Carbon/CarbonStore";
import { getCompanyInfo } from "../../../../services/companyInfo";
import TableActionsButtons from "./TableActionsButtons";

import styles from "./EditableCalculationTable.module.scss";
import { InfoAndNotesDrawer } from "./InfoAndNotesDrawer";
import moment from "moment";
import { useCheckRolesPermissions } from "../../../../hooks/useCheckRolesPermissions";
import { getAssignedIdentifiersForDefaultMetadata } from "../../../../services/dataSheet";

export const EditableContext = React.createContext(null);

interface EditableCalculationTableProps {
  data: Emission[];
  attributionFactor?: number;
  isCalculationLocked?: boolean;
  isIndicatorTypeShown?: boolean;
  editEmission: (data: Emission) => void;
  addEmission: (newEmission: Emission | Emission[]) => void;
  onSaveConsumptionAmount: (data: Emission, consumptionAmount: number) => void;
  onSaveCustomName: (data: Emission, customName: string) => void;
  onDelete: (data: Emission) => void;
  onDeleteEmptyLine: (data: Emission) => void;
  onClickUndo: (data: Emission) => void;
  handleMarkAsEstimated?: (data: Emission) => void;
  emissionsUnit: string;
  conversionFactor: number;
  scope: ScopeName;
}

type Record = {
  co2e_total: number;
  co2: number;
  ch4: number;
  n2o: number;
};
const EditableCalculationTable = observer(
  ({
    data,
    attributionFactor,
    isCalculationLocked,
    isIndicatorTypeShown,
    editEmission,
    addEmission,
    onSaveConsumptionAmount,
    onSaveCustomName,
    onDelete,
    onDeleteEmptyLine,
    onClickUndo,
    handleMarkAsEstimated,
    emissionsUnit,
    conversionFactor,
    scope,
  }: EditableCalculationTableProps) => {
    const { checkRolesPermission } = useCheckRolesPermissions();
    const [emissionsData, setEmissionsData] = useState(data);
    const [expandedRows, setExpandedRows] = useState<string[]>([]);
    const [loadingKeys, setLoadingKeys] = useState<string[]>([]);
    const [infoDrawerVisible, setInfoDrawerVisible] = useState(false);
    const [identifiers, setIdentifiers] = useState([] as string[]);
    const [drawerData, setDrawerData] = useState({
      record: {} as Emission,
      drillDownRecord: {} as DrillDownRecord,
    });
    const [filterData, setFilterData] = useState<any>();
    const categories = [
      { value: "1", text: "1: Purchased Goods & Services" },
      { value: "2", text: "2: Capital Goods" },
      {
        value: "3",
        text: " 3: Fuel- and Energy-Related Activities Not Included in Scope 1 or Scope 2",
      },
      { value: "4", text: "4: Upstream Transportation and Distribution" },
      { value: "5", text: "5: Waste Generated in Operations" },
      { value: "6", text: "6: Business Travel" },
      { value: "7", text: "7: Employee Commuting" },
      { value: "8", text: "8: Upstream Leased Assets" },
      { value: "9", text: "9: Downstream Transportation and Distribution" },
      { value: "10", text: "10: Processing of Sold Products" },
      { value: "11", text: "11: Use of Sold Products" },
      { value: "12", text: "12: End-of-Life Treatment of Sold Products" },
      { value: "13", text: "13: Downstream Leased Assets" },
      { value: "14", text: "14: Franchises" },
      { value: "15", text: "15: Investments" },
    ];
    const getFilterData = () => {
      const filterObject: any = {};
      emissionsData.forEach((value) => {
        if (value?.factor_data) {
          if (
            "name" in value?.factor_data &&
            value?.factor_data?.["name"] &&
            !filterObject["name"]?.includes(value.factor_data?.["name"])
          ) {
            filterObject["name"]?.length
              ? filterObject["name"]?.push(value.factor_data?.["name"])
              : (filterObject["name"] = [value.factor_data?.["name"]]);
          }
          if (
            "region_name" in value.factor_data &&
            value.factor_data?.["region_name"] &&
            !filterObject["region_name"]?.includes(
              value.factor_data?.["region_name"]
            )
          ) {
            filterObject["region_name"]?.length
              ? filterObject["region_name"]?.push(
                  value.factor_data?.["region_name"]
                )
              : (filterObject["region_name"] = [
                  value.factor_data?.["region_name"],
                ]);
          }
        }
        if (
          "custom_name" in value &&
          value["custom_name"] &&
          !filterObject["custom_name"]?.includes(value["custom_name"])
        ) {
          filterObject["custom_name"]?.length
            ? filterObject["custom_name"]?.push(value["custom_name"])
            : (filterObject["custom_name"] = [value["custom_name"]]);
        }
        if (
          "indicatorType" in value &&
          value["indicatorType"] &&
          !filterObject["indicatorType"]?.includes(value["indicatorType"])
        ) {
          filterObject["indicatorType"]?.length
            ? filterObject["indicatorType"]?.push(value["indicatorType"])
            : (filterObject["indicatorType"] = [value["indicatorType"]]);
        }
      });
      setFilterData(filterObject);
    };

    useEffect(() => {
      emissionsData?.length && getFilterData();
    }, [emissionsData]);

    const getIdentifiers = async () => {
      try {
        const identifiers = await getAssignedIdentifiersForDefaultMetadata();
        setIdentifiers(identifiers);
        const isError = identifiers?.statusCode >= 400;
        if (isError) {
          setIdentifiers([]);
          return Promise.reject();
        }
        if (
          checkRolesPermission(["role:elevateduser"]) &&
          identifiers?.length === 1
        ) {
          getUpdatedEmissions(identifiers);
        }
      } catch {
        message.error("Error while getting identifiers!");
      }
    };

    useEffect(() => {
      getIdentifiers();
    }, []);

    const emptyEmission = {
      name: "",
      region_name: "",
      unit: "",
      factor: 0,
      custom_name: "",
      consumption_amount: 0,
      co2e_total: 0,
    };

    const [newEmission, setNewEmission] = useState(emptyEmission);
    const [companyDetails, setCompanyDetails] = useState<CompanyDetails>(
      {} as CompanyDetails
    );

    const {
      list: consumptionAmount,
      replace,
      resetList,
    } = useDynamicList(
      toJS(emissionsData).map((emission) => emission.consumption_amount)
    );

    const {
      list: customName,
      replace: replaceCustomName,
      resetList: resetListCustomName,
    } = useDynamicList(
      toJS(emissionsData).map((emission) => emission.custom_name || "")
    );

    useEffect(() => {
      resetList(
        toJS(emissionsData).map((emission) => emission.consumption_amount)
      );
      resetListCustomName(
        toJS(emissionsData).map((emission) => emission.custom_name || "")
      );
    }, [emissionsData]);

    useEffect(() => {
      setEmissionsData(data);
      setExpandedRows([]);
    }, [data]);

    const convertedNumber = fractionalNumber(
      companyDetails.decimal_places_number
    );

    const convertedFactor = fractionalNumber(4);

    const loadCompanyInfo = async () => {
      try {
        const companyInfo = await getCompanyInfo();
        if (companyInfo) {
          const companyData = companyInfo["data"]
            ? JSON.parse(companyInfo["data"])
            : "";

          setCompanyDetails(companyData);
        }
      } catch (error) {
        console.log("Error while load company details", error);
        message.error("Error while load company details!");
      }
    };

    useEffect(() => {
      loadCompanyInfo();
    }, []);

    const onDeleteFromEmissionsData = (emission: Emission) => {
      const newData = emissionsData.filter(
        (item) => item._id.$oid !== emission._id.$oid
      );
      setEmissionsData(newData);
    };

    const handleChangeCustomName = (
      e: ChangeEvent<HTMLInputElement>,
      index: number
    ) => {
      replaceCustomName(index, e.target.value || "");
    };

    const handleViewVersion = (historyData: CalculatorEntryHistory) => {
      const viewingData = historyData.data as Emission;
      const emissionIndex = emissionsData.findIndex(
        (item) => item.key === viewingData.key
      );

      const newData = emissionsData.map((item, index) => {
        return index === emissionIndex
          ? { ...viewingData, history: item.history, viewOnly: true }
          : item;
      });
      setEmissionsData(newData);
    };

    const handleViewCurrentVersion = (originalItem: Emission) => {
      const viewingData = originalItem as Emission;
      const emissionIndex = emissionsData.findIndex(
        (item) => item.key === viewingData.key
      );

      const newData = emissionsData.map((item, index) => {
        return index === emissionIndex
          ? { ...originalItem, history: originalItem.history }
          : item;
      });
      setEmissionsData(newData);
    };

    const handleRestoreVersion = (
      historyData: CalculatorEntryHistory,
      originalItem: Emission
    ) => {
      calculatorStore
        .editCalculationEntry({
          ...historyData.data,
          _id: { $oid: originalItem._id?.$oid },
          history: originalItem.history,
        })
        .then(() => calculatorStore.getCalculationById());
    };

    const isDisabledConfirm =
      newEmission.name && newEmission.region_name && newEmission.unit;

    const handleConfirm = (data: Emission, index: number) => {
      const emission: Emission = {
        ...data,
        factor_data: {
          name: newEmission.name,
          factor: newEmission?.factor,
          region_name: newEmission.region_name,
          region: newEmission.region_name,
          unit: newEmission?.unit,
          constituent_gases: {
            co2e_total: newEmission?.factor,
            co2e_other: 0,
            co2: 0,
            ch4: 0,
            n2o: 0,
          },
        } as CustomManualFactor,
        consumption_amount: newEmission.consumption_amount,
        custom_name: newEmission.custom_name,
        emission: newEmission.co2e_total,
        item_type: "emission",
      };
      if (!newEmission?.factor && !newEmission.consumption_amount) {
        emission.override = true;
        emission.unattributed_emission = emission.emission;
      }

      addEmission(emission as Emission);

      setEmissionsData((prevData) => {
        prevData[index] = emission;
        return prevData;
      });
      setNewEmission(emptyEmission);
    };

    const handleCancel = (data: Emission) => {
      onDeleteEmptyLine(data);
      setNewEmission(emptyEmission);
    };

    // need to check if we need to modify here if real time calculation
    const recalculateFields = (
      value: number,
      field: string,
      elem: Emission
    ) => {
      setNewEmission((prevState) => {
        let newData = { ...prevState };
        switch (field) {
          // if consumption amount modified
          case "consumption_amount":
            if (newData.factor) {
              // we may need to modify this if consumption amount is not multiplied by additional multiplier
              const emissions_total = calculate_emissions_total(
                value,
                newData.factor,
                elem?.datasheet_unit_conversion_factor,
                elem?.factor_data?.custom_unit_conversion_factor
              );
              newData.co2e_total = emissions_total;
            } else if (newData.co2e_total) {
              newData.factor = newData.co2e_total / value;
            }
            break;

          // if factor modified
          case "factor":
            if (newData.consumption_amount) {
              newData.co2e_total = value * newData.consumption_amount;
            } else if (newData.co2e_total) {
              newData.consumption_amount = newData.co2e_total / value;
            }
            break;

          // if co2e_total modified
          case "co2e_total":
            if (newData.consumption_amount) {
              newData.factor = value / newData.consumption_amount;
            } else if (newData.factor) {
              newData.consumption_amount = value / newData.factor;
            }
            break;
        }
        return newData;
      });
    };

    const handleEditNewEmission = (value: string | number, field: string) => {
      setNewEmission((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    };

    const handleGoCurrent = () => {
      setEmissionsData(data);
    };

    const consumptionColumns = {
      automated: (data: number, elem: AutomatedEmission, index: number) => {
        return (
          <div className={styles.emissionColumn}>
            <div className={styles.itemCell}>
              <span
                className={`${
                  (elem.missing_data && styles.itemSpanWarning) ||
                  styles.emissionFullWidth
                } 
                  ${styles.itemSpanInfo}`}
              >
                <span>
                  {convertedNumber(data)} {/* Use data directly */}
                </span>
                <span className={`${styles.emissionUnitFullWidth}`}>
                  {elem?.factor_data?.unit?.split("/").at(-1)}{" "}
                  {/* here it is taking denominator of conversion factor */}
                </span>
                <span className={styles.emissionWarning}>
                  {elem.missing_data && (
                    <Popover
                      placement="right"
                      content={
                        <>
                          <div className={styles.warningPopoverTitle}>
                            Warning
                          </div>
                          <div>
                            Some pieces of data are missing. Review the data
                            sheet to increase the accuracy of the calculation
                          </div>
                          <div className={styles.warningPopoverLink}>
                            <Link
                              to={
                                getLinkDataSheet(
                                  elem as AutomatedEmission,
                                  carbonStore.getSheetById(elem.activity_sheet)
                                ).url
                              }
                              onClick={() =>
                                localStorage.setItem(
                                  "filters",
                                  JSON.stringify(
                                    getLinkDataSheet(
                                      elem as AutomatedEmission,
                                      carbonStore.getSheetById(
                                        elem.activity_sheet
                                      )
                                    ).filtersArray
                                  )
                                )
                              }
                              target="_blank"
                            >
                              View Data Sheet
                            </Link>
                          </div>
                        </>
                      }
                      className={styles.warningIcon}
                      overlayClassName={`popoverContent ${styles.warningPopover}`}
                      autoAdjustOverflow
                    >
                      <WarningOutlined />
                    </Popover>
                  )}
                </span>
              </span>
            </div>
            <Popover
              placement="right"
              title={"Consumption Amount is calculated automatically"}
              content={
                <>
                  <p>
                    Go to{" "}
                    <EllipsisOutlined className={styles.infoIconOutlined} />{" "}
                    then click ”Edit” to edit this emission
                    <br /> Go to{" "}
                    <EyeOutlined className={styles.infoIconOutlined} /> to view
                    more details
                    {elem?.datasheet_unit_conversion_factor !== undefined &&
                      elem?.datasheet_unit_conversion_factor != 1 && (
                        <>
                          <br />
                          Datasheet Unit is expressed in {
                            elem?.datasheet_unit
                          }{" "}
                          so an automatic unit conversion multiplier of{" "}
                          {elem?.datasheet_unit_conversion_factor} is applied
                        </>
                      )}
                    {elem?.factor_data?.custom_unit_conversion_factor !==
                      undefined &&
                      elem?.factor_data?.custom_unit_conversion_factor?.valueOf() >=
                        0 && (
                        <>
                          <br />
                          Custom Unit Conversion Factor multiplier of{" "}
                          {convertedFactor(
                            elem?.factor_data?.custom_unit_conversion_factor?.valueOf() ||
                              0
                          )}{" "}
                          is being used
                          <br />
                          Original Consumption Amount:{" "}
                          {convertedFactor(
                            elem?.consumption_amount /
                              elem?.factor_data?.custom_unit_conversion_factor
                          )}{" "}
                          {elem.datasheet_unit}
                        </>
                      )}
                  </p>
                </>
              }
              className={styles.infoIcon}
              overlayClassName="popoverContent"
              arrowPointAtCenter
              autoAdjustOverflow
            >
              <InfoCircleOutlined />
            </Popover>
          </div>
        );
      },
      manual: (data: number, elem: Emission, index: number) => {
        return (
          <div className={`${styles.emptyContainer} ${styles.emissionColumn}`}>
            <span
              className={`${
                (!elem.consumption_amount &&
                  `${styles.itemSpanWarning} 
                ${styles.emptyInputWarning}`) ||
                styles.emissionFullWidth
              }`}
            >
              <FormWrapper
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <InputNumber
                  readOnly={isCalculationLocked || elem?.viewOnly}
                  className={styles.itemInput}
                  size="large"
                  min={0}
                  value={consumptionAmount[index]}
                  onChange={(value) => replace(index, Number(value || 0))}
                  onPressEnter={() => {
                    onSaveConsumptionAmount(elem, consumptionAmount[index]);
                  }}
                  onBlur={() => {
                    onSaveConsumptionAmount(elem, consumptionAmount[index]);
                  }}
                />

                <span style={{ width: "40px" }}>
                  {""} {elem.factor_data?.unit?.split("/").at(-1)}
                </span>
              </FormWrapper>
              {!elem.consumption_amount && (
                <div className={styles.emissionWarning}>
                  <Popover
                    placement="right"
                    content={
                      <>
                        <div className={styles.warningPopoverTitle}>
                          Warning
                        </div>
                        <div>
                          Not enough data exists for this calculation to be
                          auditable and/or accurate
                        </div>
                      </>
                    }
                    className={styles.warningIcon}
                    overlayClassName={`popoverContent ${styles.warningPopover}`}
                    autoAdjustOverflow
                  >
                    <WarningOutlined />
                  </Popover>
                </div>
              )}
            </span>
          </div>
        );
      },
      empty: (data: number, elem: Emission, index: number) => {
        return (
          <div className={`${styles.emptyContainer} ${styles.emissionColumn}`}>
            <span
              className={`${
                (!newEmission.consumption_amount &&
                  `${styles.itemSpanWarning} 
                ${styles.emptyInputWarning}`) ||
                styles.emissionFullWidth
              }`}
            >
              <FormWrapper style={{ display: "flex" }}>
                <InputNumber
                  readOnly={isCalculationLocked || elem?.viewOnly}
                  className={styles.emptyInputConsumptionAmount}
                  placeholder="Amount"
                  size="large"
                  min={0}
                  value={newEmission.consumption_amount}
                  onChange={(value) =>
                    handleEditNewEmission(value || 0, "consumption_amount")
                  }
                  onBlur={(e) =>
                    recalculateFields(
                      +e.target.value,
                      "consumption_amount",
                      elem
                    )
                  }
                />
                <Input
                  readOnly={isCalculationLocked || elem?.viewOnly}
                  className={styles.emptyInputUnit}
                  placeholder="Unit"
                  size="large"
                  value={newEmission?.unit}
                  onChange={(e) =>
                    handleEditNewEmission(e.target.value || "", "unit")
                  }
                />
              </FormWrapper>
              {!newEmission.consumption_amount && (
                <div
                  className={styles.emissionWarning}
                  style={{ paddingLeft: "10px" }}
                >
                  <Popover
                    placement="right"
                    content={
                      <>
                        <div className={styles.warningPopoverTitle}>
                          Warning
                        </div>
                        <div>
                          Not enough data exists for this calculation to be
                          auditable and/or accurate
                        </div>
                      </>
                    }
                    className={styles.warningIcon}
                    overlayClassName={`popoverContent ${styles.warningPopover}`}
                    autoAdjustOverflow
                  >
                    <WarningOutlined />
                  </Popover>
                </div>
              )}
            </span>
          </div>
        );
      },
    };

    const co2eFactorColumn = (_: any, data: Emission) => {
      const co2 = data?.factor_data?.constituent_gases?.co2
        ? data?.factor_data?.constituent_gases?.co2
        : 0;
      const ch4 = data?.factor_data?.constituent_gases?.ch4
        ? data?.factor_data?.constituent_gases?.ch4
        : 0;
      const n2o = data?.factor_data?.constituent_gases?.n2o
        ? data?.factor_data?.constituent_gases?.n2o
        : 0;
      const unit = data?.factor_data?.unit ? data?.factor_data?.unit : "";
      const content = (
        <div>
          <p>
            <b>
              Co<sub>2</sub>
            </b>
            : {co2 ? convertedFactor(co2) + " " + unit : "NA"}
          </p>
          <p>
            <b>
              Ch<sub>4</sub>
            </b>
            : {ch4 ? convertedFactor(ch4) + " " + unit : "NA"}
          </p>
          <p>
            <b>
              {" "}
              N<sub>2</sub>o
            </b>
            : {n2o ? convertedFactor(n2o) + " " + unit : "NA"}
          </p>
        </div>
      );
      return (
        <>
          {data.factor_data?.name ? (
            <div className={styles.emissionColumn}>
              <div className={styles.itemCell}>
                <span
                  className={`${
                    (!data.factor_data?.factor &&
                      `${styles.itemSpanWarning} 
                  ${styles.emptyInputWarning}`) ||
                    styles.emissionFullWidth
                  }`}
                  style={{ display: "flex" }}
                >
                  <span className={styles.itemSpan}>
                    {convertedFactor(data?.factor_data?.factor || 0)}
                  </span>

                  <Popover
                    placement="top"
                    title="Emission Factors"
                    content={content}
                    className={styles.infoIcon}
                    overlayClassName="popoverContent"
                    arrowPointAtCenter
                    autoAdjustOverflow
                  >
                    <InfoCircleOutlined />
                  </Popover>

                  {!data.factor_data?.factor && (
                    <div
                      className={styles.emissionWarning}
                      style={{ paddingLeft: "10px" }}
                    >
                      <Popover
                        placement="right"
                        content={
                          <>
                            <div className={styles.warningPopoverTitle}>
                              Warning
                            </div>
                            <div>
                              Not enough data exists for this calculation to be
                              auditable and/or accurate
                            </div>
                          </>
                        }
                        className={styles.warningIcon}
                        overlayClassName={`popoverContent ${styles.warningPopover}`}
                        autoAdjustOverflow
                      >
                        <WarningOutlined />
                      </Popover>
                    </div>
                  )}
                </span>
              </div>
            </div>
          ) : (
            <div
              className={`${styles.emptyContainer} ${styles.emissionColumn}`}
            >
              <span
                className={`${
                  (!newEmission?.factor &&
                    `${styles.itemSpanWarning} 
                    ${styles.emptyInputWarning}`) ||
                  styles.emissionFullWidth
                }`}
              >
                <FormWrapper>
                  <InputNumber
                    className={styles.itemInput}
                    readOnly={isCalculationLocked || data?.viewOnly}
                    style={{
                      cursor:
                        isCalculationLocked || data?.viewOnly
                          ? "not-allowed"
                          : "auto",
                    }}
                    placeholder="Enter factor"
                    size="large"
                    min={0}
                    value={newEmission?.factor}
                    onChange={(value) =>
                      handleEditNewEmission(value || 0, "factor")
                    }
                    onBlur={(e) =>
                      recalculateFields(+e.target.value, "factor", data)
                    }
                  />
                </FormWrapper>
                {!newEmission?.factor && (
                  <div className={styles.emissionWarning}>
                    <Popover
                      placement="right"
                      content={
                        <>
                          <div className={styles.warningPopoverTitle}>
                            Warning
                          </div>
                          <div>
                            Not enough data exists for this calculation to be
                            auditable and/or accurate
                          </div>
                        </>
                      }
                      className={styles.warningIcon}
                      overlayClassName={`popoverContent ${styles.warningPopover}`}
                      autoAdjustOverflow
                    >
                      <WarningOutlined />
                    </Popover>
                  </div>
                )}
              </span>
            </div>
          )}
        </>
      );
    };

    const expandedAmountColumn = (data: number, record?: Emission) => (
      <span>
        {convertedNumber(data)} {record?.factor_data?.unit?.split("/").at(-1)}
        <Popover
          placement="right"
          title={"Consumption Amount is calculated automatically"}
          content={
            <>
              <p>
                Go to <EyeOutlined className={styles.infoIconOutlined} /> to
                view more details
              </p>
            </>
          }
          className={styles.infoIcon}
          overlayClassName="popoverContent"
          arrowPointAtCenter
          autoAdjustOverflow
        >
          <InfoCircleOutlined />
        </Popover>
      </span>
    );

    const expandedFactorColumn = (factor: number, record?: Emission) => {
      const co2 = record?.factor_data?.constituent_gases?.co2
        ? record?.factor_data?.constituent_gases?.co2
        : 0;
      const ch4 = record?.factor_data?.constituent_gases?.ch4
        ? record?.factor_data?.constituent_gases?.ch4
        : 0;
      const n2o = record?.factor_data?.constituent_gases?.n2o
        ? record?.factor_data?.constituent_gases?.n2o
        : 0;
      const unit = record?.factor_data?.unit ? record?.factor_data?.unit : "";
      const content = (
        <div>
          <p>
            <b>
              Co<sub>2</sub>
            </b>
            : {co2 ? convertedFactor(co2) + " " + unit : "NA"}
          </p>
          <p>
            <b>
              Ch<sub>4</sub>
            </b>
            : {ch4 ? convertedFactor(ch4) + " " + unit : "NA"}
          </p>
          <p>
            <b>
              {" "}
              N<sub>2</sub>o
            </b>
            : {n2o ? convertedFactor(n2o) + " " + unit : "NA"}
          </p>
        </div>
      );
      return (
        <span>
          {convertedFactor(factor)}
          <Popover
            placement="top"
            title="Emission Factors"
            content={content}
            className={styles.infoIcon}
            overlayClassName="popoverContent"
            arrowPointAtCenter
            autoAdjustOverflow
          >
            <InfoCircleOutlined />
          </Popover>
        </span>
      );
    };
    const categoryColumn = (category: string, record?: Emission) => {
      if (record?.category) {
        return <span>{record?.category}</span>;
      } else return <span>{category}</span>;
    };

    const expandedTotalColumn = (total_amount: number, record?: Emission) => {
      const attributedTotal =
        total_amount * (attributionFactor ? attributionFactor / 100 : 0);
      const attributedRecAmount =
        (record?.recPercent || 0) * attributedTotal * conversionFactor;
      const recAmount =
        (record?.recPercent || 0) * total_amount * conversionFactor;
      const totalValue = total_amount - recAmount;
      const attributedTotalValue = attributedTotal - attributedRecAmount;
      const content = (
        <>
          {!!record?.recAmount && (
            <div>
              <p>
                Original amount - {convertedNumber(total_amount)}{" "}
                {getEmissionsUnit(companyDetails.emissions_unit)} CO2e
              </p>
              <p>
                Renewable ({convertedNumber((record?.recPercent || 0) * 100)}
                %) - {convertedNumber(recAmount)}{" "}
                {getEmissionsUnit(companyDetails.emissions_unit)} CO2e
              </p>
            </div>
          )}
          {!!attributionFactor && attributionFactor !== 100 && (
            <div>
              <p>Attribution factor: {attributionFactor}%</p>
              <p>
                Unattributed - {convertedNumber(totalValue)}{" "}
                {getEmissionsUnit(companyDetails.emissions_unit)} CO2e
              </p>
            </div>
          )}
        </>
      );

      return (
        <span>
          {convertedNumber(attributedTotalValue)}
          {(!!record?.recAmount ||
            (!!attributionFactor && attributionFactor !== 100)) && (
            <Popover
              placement="top"
              title="Emission Factors"
              content={content}
              className={styles.infoIcon}
              overlayClassName="popoverContent"
              arrowPointAtCenter
              autoAdjustOverflow
            >
              <InfoCircleOutlined />
            </Popover>
          )}
        </span>
      );
    };

    const calculate_emissions_total = (
      consumption_amount: number,
      factor: number,
      multiplication_factor: number | undefined,
      custom_unit_conversion_factor: number | undefined
    ): number => {
      return calculatore_store_calculate_emissions_total(
        consumption_amount,
        factor,
        multiplication_factor,
        custom_unit_conversion_factor
      );
    };

    const totalColumn = (emission: Emission) => {
      // here's where emissions total is calculated for each row.
      // need to modify this for custom factor
      // need to take a look if we need to use newEmission for datasheet and custom factor data

      // not sure if calculate_emissions_total ever gets used since emission.emission will always be calculated
      const emissionTotal =
        (emission.factor_data?.name
          ? emission.emission!
          : calculate_emissions_total(
              newEmission?.consumption_amount,
              newEmission?.factor,
              emission?.datasheet_unit_conversion_factor,
              emission?.factor_data?.custom_unit_conversion_factor
            )) * conversionFactor;

      /*
      console.log('emission data before calculate emissions total');
      console.log(emission);
      console.log(conversionFactor);
      const emissionTotal = calculate_emissions_total(emission?.consumption_amount, emission?.factor_data?.factor, emission?.datasheet_unit_conversion_factor, emission?.factor_data?.custom_unit_conversion_factor);
      */

      const recAmount = (emission.recAmount || 0) * conversionFactor;

      const content = (
        <>
          {!!emission.recAmount && (
            <div>
              <Row>
                Original amount -{" "}
                {convertedNumber(
                  Number(emission?.unattributed_emission! * conversionFactor) +
                    recAmount
                )}{" "}
                {getEmissionsUnit(companyDetails.emissions_unit)} CO2e
              </Row>
              <p>
                Renewable ({convertedNumber((emission.recPercent || 0) * 100)}
                %) - {convertedNumber(recAmount)}{" "}
                {getEmissionsUnit(companyDetails.emissions_unit)} CO2e
              </p>
            </div>
          )}
          {!!attributionFactor && attributionFactor !== 100 && (
            <div>
              <Row>Attribution factor: {attributionFactor}%</Row>
              <p>
                Unattributed -{" "}
                {(emission?.unattributed_emission! * conversionFactor).toFixed(
                  companyDetails.decimal_places_number
                )}{" "}
                {getEmissionsUnit(companyDetails.emissions_unit)} CO2e
              </p>
            </div>
          )}
        </>
      );

      if (emission.factor_data?.name) {
        // If the column is in viewing mode
        return (
          <div className={styles.emissionColumn}>
            <span
              className={`${
                (emission.recPercent !== 1 &&
                  !emissionTotal &&
                  `${styles.itemSpanWarning} 
                  ${styles.emptyInputWarning}`) ||
                styles.emissionFullWidth
              }`}
            >
              {emission.override ? (
                <div>{convertedNumber(emissionTotal - recAmount)}</div>
              ) : (
                emissionTotal && (
                  <div>
                    {convertedNumber(emissionTotal)}{" "}
                    {/* this is emissionTotal */}{" "}
                  </div>
                )
              )}
              {emission.recPercent !== 1 && !emissionTotal && (
                <div className={styles.emissionWarning}>
                  <Popover
                    placement="right"
                    content={
                      <>
                        <div className={styles.warningPopoverTitle}>
                          Warning
                        </div>
                        <div>
                          Not enough data exists to auto calculate the emissions
                        </div>
                      </>
                    }
                    className={styles.warningIcon}
                    overlayClassName={`popoverContent ${styles.warningPopover}`}
                    autoAdjustOverflow
                  >
                    <WarningOutlined />
                  </Popover>
                </div>
              )}
            </span>
            {(!!emission.recAmount ||
              (!!attributionFactor && attributionFactor !== 100)) && (
              <Popover
                placement="top"
                title="Emission Factors"
                content={content}
                className={styles.infoIcon}
                overlayClassName="popoverContent"
                arrowPointAtCenter
                autoAdjustOverflow
              >
                <InfoCircleOutlined />
              </Popover>
            )}
          </div>
        );
      } else {
        // If the column is in editing mode
        return (
          <div className={`${styles.emptyContainer} ${styles.emissionColumn}`}>
            <span
              className={`${
                (!newEmission.co2e_total &&
                  `${styles.itemSpanWarning} 
                ${styles.emptyInputWarning}`) ||
                styles.emissionFullWidth
              }`}
            >
              <FormWrapper>
                <InputNumber
                  className={styles.itemInput}
                  placeholder="Enter total"
                  size="large"
                  min={0}
                  value={newEmission.co2e_total}
                  onChange={(value) => {
                    handleEditNewEmission(value || 0, "co2e_total");
                  }}
                  onBlur={(e) =>
                    recalculateFields(
                      +e.currentTarget.value,
                      "co2e_total",
                      emission
                    )
                  }
                />
              </FormWrapper>
              {!newEmission.co2e_total && (
                <div className={styles.emissionWarning}>
                  <Popover
                    placement="right"
                    content={
                      <>
                        <div className={styles.warningPopoverTitle}>
                          Warning
                        </div>
                        <div>
                          Not enough data exists to auto calculate the emissions
                        </div>
                      </>
                    }
                    className={styles.warningIcon}
                    overlayClassName={`popoverContent ${styles.warningPopover}`}
                    autoAdjustOverflow
                  >
                    <WarningOutlined />
                  </Popover>
                </div>
              )}
            </span>
          </div>
        );
      }
    };

    const columns = [
      Table.EXPAND_COLUMN,
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        filters: filterData?.name?.map((v: string) => ({
          value: v,
          text: v,
        })),
        filteredValue:
          calculatorStore.calculationItemsFilter?.[scope]?.name || null,
        sorter: (a: Emission, b: Emission) =>
          a.factor_data.name.localeCompare(b.factor_data.name),
        render: (data: string, elem: Emission | DrillDownRecord) => (
          <>
            {(elem as DrillDownRecord)?.isExpanded ? (
              (elem as DrillDownRecord)?.group
            ) : !(elem as Emission).factor_data?.name ? (
              <FormWrapper>
                <Input
                  className={styles.emptyInput}
                  placeholder="Enter name"
                  size="large"
                  value={newEmission?.name}
                  onChange={(e) => {
                    handleEditNewEmission(e.target.value || "", "name");
                  }}
                />
              </FormWrapper>
            ) : (
              <span>{(elem as Emission).factor_data?.name}</span>
            )}
          </>
        ),
      },
      {
        title: "Region",
        dataIndex: "region_name",
        key: "region_name",
        filters: filterData?.region_name?.map((v: string) => ({
          value: v,
          text: v,
        })),
        filteredValue:
          calculatorStore.calculationItemsFilter?.[scope]?.region_name || null,
        width: "10%",
        sorter: (a: Emission, b: Emission) =>
          a.factor_data?.region_name.localeCompare(b.factor_data?.region_name),
        render: (data: string, elem: Emission | DrillDownRecord) => (
          <>
            {(elem as DrillDownRecord)?.isExpanded ? (
              ""
            ) : !(elem as Emission).factor_data?.name ? (
              <FormWrapper>
                <Input
                  className={styles.emptyInput}
                  placeholder="Enter region"
                  size="large"
                  value={newEmission?.region_name}
                  onChange={(e) => {
                    handleEditNewEmission(e.target.value || "", "region_name");
                  }}
                />
              </FormWrapper>
            ) : (
              <span>{(elem as Emission).factor_data?.region_name}</span>
            )}
          </>
        ),
      },
      {
        title: "Custom name",
        dataIndex: "custom_name",
        key: "custom_name",
        filters: filterData?.custom_name?.map((v: string) => ({
          value: v,
          text: v,
        })),
        filteredValue:
          calculatorStore.calculationItemsFilter?.[scope]?.custom_name || null,
        width: "10%",
        render: (
          data: string,
          elem: Emission | DrillDownRecord,
          index: number
        ) => {
          return (
            <>
              {(elem as DrillDownRecord)?.isExpanded ? (
                ""
              ) : checkRolesPermission(["role:elevateduser"]) ? (
                customName[index] || ""
              ) : (elem as Emission).factor_data?.name ? (
                <Input
                  readOnly={isCalculationLocked || elem?.viewOnly}
                  className={styles.customName}
                  placeholder="Add"
                  size="large"
                  value={customName[index]}
                  onChange={(e) => handleChangeCustomName(e, index)}
                  onPressEnter={() => {
                    onSaveCustomName(elem as Emission, customName[index] || "");
                  }}
                  onBlur={() => {
                    onSaveCustomName(elem as Emission, customName[index] || "");
                  }}
                />
              ) : (
                <FormWrapper>
                  <Input
                    readOnly={isCalculationLocked || elem?.viewOnly}
                    className={styles.emptyInput}
                    placeholder="Enter custom name"
                    size="large"
                    value={newEmission.custom_name}
                    onChange={(e) => {
                      handleEditNewEmission(
                        e.target.value || "",
                        "custom_name"
                      );
                    }}
                  />
                </FormWrapper>
              )}
            </>
          );
        },
      },
      {
        title: "Consumption Amount",
        dataIndex: "consumption_amount",
        key: "consumption_amount",
        width: "15%",
        className: styles.consumptionColumn,

        render: (
          data: number,
          elem: Emission | DrillDownRecord,
          index: number
        ) => {
          const emissionRecord = emissionsData?.find(
            (v) => v?._id?.$oid === expandedRows[0]
          );

          return (
            <>
              {(elem as DrillDownRecord)?.isExpanded ? (
                expandedAmountColumn(
                  (elem as DrillDownRecord)?.sum,
                  emissionRecord
                )
              ) : (elem as Emission).factor_data?.name ? (
                <>
                  {!(elem as Emission).manual
                    ? consumptionColumns.automated(
                        data,
                        elem as AutomatedEmission,
                        index
                      )
                    : consumptionColumns.manual(data, elem as Emission, index)}
                </>
              ) : (
                consumptionColumns.empty(data, elem as Emission, index)
              )}
            </>
          );
        },
      },
      {
        title: "CO2e Factor",
        dataIndex: "co2e_emission_factor",
        key: "co2e_emission_factor",
        className: styles.emissionFactor,
        width: "10%",

        render: (_: any, data: Emission | DrillDownRecord) => {
          const emissionRecord = emissionsData?.find(
            (v) => v?._id?.$oid === expandedRows[0]
          );
          return (data as DrillDownRecord)?.isExpanded
            ? expandedFactorColumn(
                (data as DrillDownRecord).factor!,
                emissionRecord
              )
            : co2eFactorColumn(_, data as Emission);
        },
      },
      {
        title: `Total Emission (${emissionsUnit} CO2e)`,
        dataIndex: "total_emission",
        key: "total_emission",
        className: styles.totalEmission,
        width: "15%",
        render: (value: number, data: Emission | DrillDownRecord) => {
          const emissionRecord = emissionsData?.find(
            (v) => v?._id?.$oid === expandedRows[0]
          );
          return (data as DrillDownRecord)?.isExpanded
            ? expandedTotalColumn(
                (data as DrillDownRecord).total_amount!,
                emissionRecord
              )
            : totalColumn(data as Emission);
        },
      },

      ...(isIndicatorTypeShown
        ? [
            {
              title: "Indicator type",
              dataIndex: "indicatorType",
              key: "indicatorType",
              filters: filterData?.indicatorType?.map((v: string) => ({
                value: v,
                text: v,
              })),
              filteredValue:
                calculatorStore.calculationItemsFilter?.[scope]
                  ?.indicatorType || null,
              className: styles.totalEmission,
              width: "15%",
              render: (_: Record, data: Emission) => {
                switch (data.indicatorType) {
                  case "default":
                    return IndicatorType.default;
                  case "l":
                    return IndicatorType.l;
                  case "m":
                    return IndicatorType.m;
                  default:
                    return IndicatorType.default;
                }
              },
            },
          ]
        : []),
      /*{
        title: "Data Quality",
        dataIndex: "dataQualityScore",
        key: "dataQualityScore",
        width: "7%",
        render: (_: any, data: Emission) => {
          return (
            <>
              <Progress
                percent={data.qualityScore?.percent}
                steps={3}
                strokeColor={[purple[2], purple[3], purple[4]]}
                trailColor="#4E4268"
                showInfo={false}
              />
              <span className={styles.dataQualityScoreLabel}>
                {data.qualityScore?.label || "N/A"}
              </span>
            </>
          );
        },
      },*/
      ...(scope === "scope_3"
        ? [
            {
              title: `Category`,
              dataIndex: "category",
              key: "category",
              className: styles.totalEmission,
              width: "5%",
              filters: [
                ...new Set(
                  (emissionsData || [])
                    ?.filter((v) => !!v?.category)
                    .map((v) => v?.category)
                ),
              ].map((category) => ({
                value: category,
                text: category,
              })),
              filteredValue:
                calculatorStore.calculationItemsFilter?.[scope]?.category ||
                null,
              render: (value: number, data: Emission | DrillDownRecord) => {
                const emissionRecord = emissionsData?.find(
                  (v) => v?._id?.$oid === expandedRows[0]
                );
                return categoryColumn(
                  (data as DrillDownRecord).category!,
                  emissionRecord
                );
              },
            },
          ]
        : []),
      {
        title: "",
        dataIndex: "action",
        key: "action",
        width: "10%",
        render: (
          _: any,
          currentData: Emission | DrillDownRecord,
          index: number
        ) => {
          const emissionRecord = emissionsData?.find(
            (v) => v?._id?.$oid === expandedRows[0]
          );
          const item = data[index];

          return (
            <>
              {(currentData as DrillDownRecord)?.isExpanded ? (
                <Button
                  className={styles.btnIcon}
                  onClick={() => handleInfoDrawer(emissionRecord!, currentData)}
                >
                  <EyeOutlined />
                </Button>
              ) : (currentData as Emission).factor_data?.name ? (
                <TableActionsButtons
                  isCalculationLocked={isCalculationLocked}
                  data={currentData as Emission}
                  identifiers={identifiers}
                  onEditEmission={editEmission}
                  onDelete={() => onDelete(currentData as Emission)}
                  onDeleteFromEmissionsData={() =>
                    onDeleteFromEmissionsData(currentData as Emission)
                  }
                  onClickUndo={() => onClickUndo(currentData as Emission)}
                  onViewHistory={handleViewVersion}
                  onRestoreHistory={(history) =>
                    handleRestoreVersion(history, item)
                  }
                  onViewCurrentVersion={() => handleViewCurrentVersion(item)}
                  onGoCurrent={handleGoCurrent}
                  onMarkAsEstimated={handleMarkAsEstimated}
                  viewingHistory={currentData.viewOnly}
                />
              ) : (
                <div style={{ display: "flex" }}>
                  <StyledButton
                    type="primary"
                    onClick={() =>
                      handleConfirm(currentData as Emission, index)
                    }
                    disabled={!isDisabledConfirm}
                  >
                    <CheckOutlined />
                    Confirm
                  </StyledButton>
                  <StyledButton
                    type="primary"
                    onClick={() => handleCancel(currentData as Emission)}
                  >
                    <CloseOutlined />
                  </StyledButton>
                </div>
              )}
            </>
          );
        },
      },
    ];
    const handleInfoDrawer = (record: Emission, drillDownRecord: any) => {
      setInfoDrawerVisible(true);
      setDrawerData({ record, drillDownRecord });
    };

    const handleExpandRow = async (
      expanded: boolean,
      record: AutomatedEmission
    ) => {
      if (expanded) {
        setExpandedRows([record?._id.$oid!]);
        setLoadingKeys([record?._id.$oid]);
        const currentFilter =
          calculatorStore.calculation.drilldownFilters?.find(
            (v) => v.dataSheetId === record.activity_sheet
          );
        const records = await calculatorStore.aggregateDrillDownRecords(
          currentFilter!,
          record.filters,
          record.activity_field
        );
        const newRecords = records?.map(
          (item: DrillDownRecord, index: number) => {
            const newFilter =
              currentFilter?.column_value &&
              moment(item.group).format("YYYY-MM-DD") !== item.group
                ? [
                    {
                      column: currentFilter?.column,
                      op: "$gte",
                      column_value: moment(item.group)
                        .startOf(
                          currentFilter?.column_value === "annual"
                            ? "year"
                            : "month"
                        )
                        .toISOString(),
                    },
                    {
                      column: currentFilter?.column,
                      op: "$lte",
                      column_value: moment(item.group)
                        .endOf(
                          currentFilter?.column_value === "annual"
                            ? "year"
                            : "month"
                        )
                        .toISOString(),
                    },
                  ]
                : [
                    {
                      column: currentFilter?.column,
                      op: "$eq",
                      column_value: item.group,
                    },
                  ];
            return {
              ...item,
              factor: record.factor_data?.factor,
              total_amount:
                item.sum * record.factor_data?.factor * conversionFactor,
              time_period: currentFilter?.column_value,
              filter: newFilter,
              isExpanded: true,
            };
          }
        );
        const emissions = emissionsData.map((item) =>
          item?._id.$oid === record?._id.$oid && newRecords?.length
            ? { ...item, children: newRecords }
            : item
        );
        setEmissionsData(emissions);
        setLoadingKeys([]);
      } else {
        setExpandedRows([]);
      }
    };
    const loadExpandedRow = (record: Emission) => {
      if (loadingKeys.includes(record._id?.$oid!)) {
        return (
          <div style={{ textAlign: "center" }}>
            <Spin size="small" />
          </div>
        );
      }
    };
    const expandedTable =
      calculatorStore.calculation.isDrilldownEnabled &&
      calculatorStore.calculation.drilldownFilters?.length
        ? {
            expandIcon: ({
              expanded,
              onExpand,
              record,
            }: {
              expanded: boolean;
              onExpand: (record: Emission, e: any) => void;
              record: Emission;
            }) =>
              !record.manual &&
              calculatorStore.calculation.isDrilldownEnabled &&
              calculatorStore.calculation.drilldownFilters?.find(
                (v) =>
                  v.dataSheetId === (record as AutomatedEmission).activity_sheet
              ) ? (
                expanded ? (
                  <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
                ) : (
                  <CaretRightOutlined onClick={(e) => onExpand(record, e)} />
                )
              ) : null,
            onExpand: (expanded: boolean, record: AutomatedEmission) =>
              handleExpandRow(expanded, record),
            showExpandColumn: true,
            expandedRowRender: loadExpandedRow,
            expandedRowKeys: expandedRows,
          }
        : null;

    const handleTableChange = (_: any, filters: any) => {
      const updatedFilters = Object.values(removeNullKeys(filters))?.length
        ? removeNullKeys(filters)
        : null;
      calculatorStore.changeCalculationItemsFilter(updatedFilters, scope);
    };

    return (
      <>
        <InfoAndNotesDrawer
          isCalculationLocked={isCalculationLocked}
          typeDrawer={"info"}
          calculationEntry={drawerData.record as Emission}
          drillDownRecord={drawerData.drillDownRecord}
          visible={infoDrawerVisible}
          onClose={() => setInfoDrawerVisible(false)}
          calculationEntryType={CalculationEntryType.EMISSIONS}
        />
        <CommonTable
          rowKey={(record: Emission) => record?._id?.$oid}
          className="calculationTable"
          rowClassName={(record: Emission) =>
            record.estimated && "estimatedRow"
          }
          childrenColumnName={
            calculatorStore.calculation.isDrilldownEnabled
              ? "children"
              : "empty"
          }
          columns={columns}
          dataSource={emissionsData}
          pagination={false}
          scroll={{ x: true }}
          loading={calculatorStore.loadingCalculationItems}
          size="small"
          expandable={expandedTable}
          onChange={handleTableChange}
        />
      </>
    );
  }
);

export default EditableCalculationTable;
function getUpdatedEmissions(identifiers: any) {
  throw new Error("Function not implemented.");
}
