import React, { useState } from "react";
import {
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import type { SelectProps } from "antd";
import { FormWrapper, StyledButton } from "../../../../shared/commonStyles";

const { Title } = Typography;
const { Option } = Select;

interface CategoryData {
  name: string;
  isEditing: boolean;
}

export const DecarbonizationPageSettings: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [mainForm] = Form.useForm();
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const [categories, setCategories] = useState<CategoryData[]>([
    { name: "Lorem Ipsum", isEditing: false },
    { name: "Lorem Ipsum", isEditing: false },
    { name: "Lorem Ipsum", isEditing: false },
    { name: "Lorem Ipsum", isEditing: false },
    { name: "Lorem Ipsum", isEditing: false },
  ]);

  const [editingValue, setEditingValue] = useState<string>("");

  const handleAddCategory = (values: { newCategory: string }) => {
    if (values.newCategory?.trim()) {
      setCategories([
        ...categories,
        { name: values.newCategory, isEditing: false },
      ]);
      form.resetFields();
    }
  };

  const handleDeleteCategory = (index: number) => {
    const newCategories = categories.filter((_, i) => i !== index);
    setCategories(newCategories);
    message.success("Category deleted successfully");
  };

  const handleEditClick = (index: number) => {
    const newCategories = categories.map((category, i) => ({
      ...category,
      isEditing: i === index ? true : false,
    }));
    setEditingValue(categories[index].name);
    setCategories(newCategories);
  };

  const handleEditSave = (index: number) => {
    if (editingValue.trim()) {
      const newCategories = categories.map((category, i) => ({
        name: i === index ? editingValue : category.name,
        isEditing: false,
      }));
      setCategories(newCategories);
      message.success("Category updated successfully");
    }
  };

  const handleEditCancel = (index: number) => {
    const newCategories = categories.map((category, i) => ({
      ...category,
      isEditing: false,
    }));
    setCategories(newCategories);
    setEditingValue("");
  };

  const handleSaveAll = async () => {
    try {
      const mainFormValues = await mainForm.validateFields();
      const settingsData = {
        baselineYear: mainFormValues.baselineYear,
        targetYear: mainFormValues.targetYear,
        metricUnit: mainFormValues.metricUnit,
        scope1DataSheet: mainFormValues.scope1DataSheet,
        scope2DataSheet: mainFormValues.scope2DataSheet,
        categories: categories.map((cat) => cat.name),
      };

      console.log("Settings data to be saved:", settingsData);
      message.success("Settings saved successfully");
    } catch (error) {
      message.error("Please fill in all required fields");
    }
  };

  const selectProps: SelectProps = {
    style: { width: "100%" },
    className: "custom-dark-select",
    dropdownStyle: {
      backgroundColor: "#4A4458",
    },
  };

  return (
    <div
      className="p-6"
      style={{ backgroundColor: "#1a1625", minHeight: "100vh" }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Card
            style={{
              backgroundColor: "#2d273f",
              padding: "20px",
            }}
            bodyStyle={{
              padding: "0px",
            }}
          >
            <div style={{ marginBottom: "1rem" }}>
              <button
                onClick={() => navigate(-1)}
                style={{
                  background: "transparent",
                  border: "none",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  cursor: "pointer",
                }}
              >
                <ArrowLeftOutlined /> <span>Exit</span>
              </button>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "2rem",
              }}
            >
              <Title level={4} style={{ color: "white", margin: 0 }}>
                Decarbonization Tool Settings
              </Title>
              <StyledButton
                onClick={handleSaveAll}
                style={{
                  background: "#9747FF",
                  border: "none",
                  color: "white",
                  padding: "8px 16px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <SaveOutlined /> <span>Save Settings</span>
              </StyledButton>
            </div>

            <FormWrapper>
              <Form
                form={mainForm}
                labelCol={layout.labelCol}
                wrapperCol={layout.wrapperCol}
                layout="vertical"
              >
                <Row gutter={[32, 16]}>
                <Col xs={24} lg={12}>
                    <div>
                      <Title
                        level={5}
                        style={{ color: "white", marginBottom: "1.5rem" }}
                      >
                        Baseline and Target Years
                      </Title>

                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            name="baselineYear"
                            label={
                              <span style={{ color: "white" }}>
                                Baseline Year
                              </span>
                            }
                            initialValue="2024"
                          >
                            <Input
                              style={{
                                height: "2rem"
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="targetYear"
                            label={
                              <span style={{ color: "white" }}>
                                Target Year
                              </span>
                            }
                          >
                            <Input
                              style={{
                                height: "2rem"
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Title
                        level={5}
                        style={{
                          color: "white",
                          marginTop: "2rem",
                          marginBottom: "1.5rem",
                        }}
                      >
                        Data Sheets for Project Form Inputs
                      </Title>

                      <Form.Item
                        name="scope1DataSheet"
                        label={
                          <span style={{ color: "white" }}>
                            Scope 1 Data Sheet
                          </span>
                        }
                      >
                        <Select
                          placeholder="Select scope 1 data sheet"
                          {...selectProps}
                          style={{
                            width: "30%",
                            height: "2rem",
                            backgroundColor: "#9747FF",
                            marginRight: "8px",
                          }}
                        >
                          <Option value="sheet1">Sheet 1</Option>
                          <Option value="sheet2">Sheet 2</Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="scope2DataSheet"
                        label={
                          <span style={{ color: "white" }}>
                            Scope 2 Data Sheet
                          </span>
                        }
                      >
                        <Select
                          placeholder="Select scope 2 data sheet"
                          {...selectProps}
                          style={{
                            width: "30%",
                            height: "2rem",
                            backgroundColor: "#9747FF",
                            marginRight: "8px",
                          }}
                        >
                          <Option value="sheet1">Sheet 1</Option>
                          <Option value="sheet2">Sheet 2</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>

                  <Col xs={24} lg={12}>
                    <Title
                      level={5}
                      style={{ color: "white", marginBottom: "1.5rem" }}
                    >
                      Decarbonization Categories
                    </Title>

                    <Card
                      style={{ backgroundColor: "#1a1625" }}
                      bodyStyle={{ padding: "1rem" }}
                    >
                      <Row style={{ color: "#9292A3", marginBottom: "1rem" }}>
                        <Col span={12}>Category</Col>
                        <Col span={12}>Actions</Col>
                      </Row>

                      {categories.map((category, index) => (
                        <div key={index}>
                          <Divider
                            style={{
                              margin: "0.5rem 0",
                              backgroundColor: "#4F4669",
                            }}
                          />
                          <Row align="middle">
                            <Col span={12} style={{ color: "white" }}>
                              {category.isEditing ? (
                                <Input
                                  value={editingValue}
                                  onChange={(e) =>
                                    setEditingValue(e.target.value)
                                  }
                                  onPressEnter={() => handleEditSave(index)}
                                  style={{ width: "90%" }}
                                />
                              ) : (
                                category.name
                              )}
                            </Col>
                            <Col
                              span={12}
                              style={{ display: "flex", gap: "8px" }}
                            >
                              {category.isEditing ? (
                                <>
                                  <StyledButton
                                    onClick={() => handleEditSave(index)}
                                    style={{
                                      background: "#4A4458",
                                      border: "none",
                                      color: "white",
                                      padding: "4px 12px",
                                      borderRadius: "4px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <SaveOutlined /> <span>Save</span>
                                  </StyledButton>
                                  <StyledButton
                                    onClick={() => handleEditCancel(index)}
                                    style={{
                                      background: "#4A4458",
                                      border: "none",
                                      color: "white",
                                      padding: "4px 12px",
                                      borderRadius: "4px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <CloseOutlined /> <span>Cancel</span>
                                  </StyledButton>
                                </>
                              ) : (
                                <>
                                  <StyledButton
                                    onClick={() => handleEditClick(index)}
                                    style={{
                                      background: "#4A4458",
                                      border: "none",
                                      color: "white",
                                      padding: "4px 12px",
                                      borderRadius: "4px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <EditOutlined /> <span>Edit</span>
                                  </StyledButton>
                                  <StyledButton
                                    onClick={() => handleDeleteCategory(index)}
                                    style={{
                                      background: "#4A4458",
                                      border: "none",
                                      color: "white",
                                      padding: "4px 12px",
                                      borderRadius: "4px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <DeleteOutlined /> <span>Delete</span>
                                  </StyledButton>
                                </>
                              )}
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </Card>

                    <Form
                      form={form}
                      onFinish={handleAddCategory}
                      style={{ marginTop: "1rem" }}
                    >
                      <div
                        style={{
                          margin: "2rem 0",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Form.Item
                          name="newCategory"
                          style={{ marginBottom: 0 }}
                        >
                          <Input
                            placeholder="Add category"
                            style={{
                              width: "8rem",
                              height: "2rem",
                              marginRight: "8px",
                              backgroundColor: "#4A4458",
                              color: "white",
                              border: "none",
                            }}
                          />
                        </Form.Item>
                        <Form.Item>
                          <StyledButton
                            htmlType="submit"
                            style={{
                              background: "#4A4458",
                              border: "none",
                              color: "white",
                              padding: "4px 12px",
                              borderRadius: "4px",
                              cursor: "pointer",
                            }}
                          >
                            <span>Add to table</span>
                          </StyledButton>
                        </Form.Item>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </Form>
            </FormWrapper>
          </Card>
        </Col>
      </Row>
    </div>
  );
};