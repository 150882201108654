import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "antd";
import {
  FormOutlined,
  CalculatorOutlined,
  ClusterOutlined,
  DashboardOutlined,
  CreditCardOutlined,
  ShoppingCartOutlined,
  MenuOutlined,
  AuditOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import { ContentSection, CardsWrap } from "../../shared/commonStyles";
//import { Card } from "../../components/dashboard/Card"
import { useCheckRolesPermissions } from "../../hooks/useCheckRolesPermissions";
import { COLORS } from "../../shared/colors";
import { CustomIconCard } from "../../components/CustomCard/CustomIconCard";
import { PopularActionCard } from "../../components/CustomCard/PopularActionCard";
import { companyInfoStore } from "./CompanyInfoStore";
import { openInNewTab } from "../../shared/commonFunctions";
import { observer } from "mobx-react-lite";

const customIconCardStyle = {
  cardStyle: { margin: "10px 20px 10px 0" },
  titleStyle: { color: COLORS.white },
  iconStyle: { fontSize: 26, color: COLORS.white },
};

const cards = [
  {
    permission: "carbon-page",
    cardTitle: "Carbon Overview",
    cardIcon: <DashboardOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/carbon/overview",
    type: "heading",
  },
  {
    permission: "carbon-page",
    cardTitle: "Emissions Calculator",
    cardIcon: <CalculatorOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/carbon/calculations",
    type: "heading",
  },
  {
    permission: "carbon-page",
    cardTitle: "Automated Calculation",
    cardIcon: <ClusterOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/carbon/automated-calculation",
    type: "heading",
  },
  {
    permission: "carbon-page",
    cardTitle: "What-if-Analysis",
    cardIcon: <DashboardOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: companyInfoStore.isWhatIfOnboardingCompleted
      ? "/carbon/reduction-plan"
      : "/carbon/reduction-plan/onboarding-flow",
    type: "heading",
  },
  {
    permission: "carbon-page",
    cardTitle: "Carbon Offset Orders",
    cardIcon: <ShoppingCartOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/carbon/carbon-offsets-orders",
    type: "carbon offset",
  },
  {
    permission: "carbon-page",
    cardTitle: "Purchase Carbon Offsets",
    cardIcon: <CreditCardOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/carbon/carbon-offsets",
    type: "carbon offset",
  },
  {
    permission: "carbon-page",
    cardTitle: "Create a Custom Factor",
    cardIcon: <FormOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/carbon/custom-factors",
    type: "quick nav",
  },
  {
    permission: "carbon-page",
    cardTitle: "View Factors Library",
    cardIcon: <MenuOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/carbon/emission-library",
    type: "quick nav",
  },
  {
    permission: "carbon-page",
    cardTitle: "Decarbonizatoin Tool",
    cardIcon: <AppstoreOutlined style={customIconCardStyle.iconStyle} />,
    cardLink: "/carbon/decarbonization-tool",
    type: "quick nav",
  },
];

export const Carbon = observer(function Carbon() {
  const navigate = useNavigate();
  const { checkPermissions } = useCheckRolesPermissions();
  const [showPopluarActions, setShowPopluarActions] = useState(false);

  const onClickCardsSelectSection = (section) => {
    switch (section) {
      case "/carbon/overview":
        navigate(section);
        break;
      case "/carbon/calculations":
        navigate(section);
        break;
      case "/carbon/automated-calculation":
        navigate(section);
        break;
      case "/carbon/reduction-plan":
        navigate(section);
        break;
      case "/carbon/reduction-plan/onboarding-flow":
        navigate(section);
        break;
      case "/carbon/carbon-offsets-orders":
        navigate(section);
        break;
      case "/carbon/carbon-offsets":
        navigate(section);
        break;
      case "/carbon/emissions-factor-methodology":
        openInNewTab(
          "https://docs.google.com/document/d/1oxMwjYBGmc7d9GgiSDs7y2rkbDpSkIuYTwWmOrzfYwA/edit#heading=h.54dg7itv2fmm"
        );
        break;
      case "/carbon/custom-factors":
        navigate(section);
        break;
      case "/carbon/emission-library":
        navigate(section);
        break;
      case "/carbon/decarbonization-tool":
        navigate(section);
        break;
      default:
    }
  };

  const allowedHeadingCards = cards.filter(
    (card) => card.type == "heading" && checkPermissions([card.permission])
  );

  const allowedCarbonOffsetCards = cards.filter(
    (card) =>
      card.type == "carbon offset" && checkPermissions([card.permission])
  );

  const allowedQuickNavCards = cards.filter(
    (card) => card.type == "quick nav" && checkPermissions([card.permission])
  );

  const popularActions = [
    {
      permission: "carbon-page-elevated",
      cardTitle: "Carbon Overview",
      cardIcon: <DashboardOutlined />,
      // iconColor: "#FC7786",
      iconColor: "linear-gradient(180deg, #FC7786 0%, #E66270 100%)",
      cardLink: "/carbon/overview",
      type: "heading",
    },
    {
      permission: "carbon-page-elevated",
      cardTitle: "Emissions Calculator",
      cardIcon: <CalculatorOutlined />,
      // iconColor: "#569CE6",
      iconColor: "linear-gradient(180deg, #569CE6 0%, #3A84D1 100%)",
      cardLink: "/carbon/calculations",
      type: "heading",
      moduleName: "CARBON_EMISSIONS_CALCULATOR",
    },
    {
      permission: "carbon-page",
      cardTitle: "What-if-Analysis",
      cardIcon: <DashboardOutlined />,
      // iconColor: "#45D1B0",
      iconColor: "linear-gradient(180deg, #44C6A6 0%, #31AC8F 100%)",
      cardLink: companyInfoStore.isWhatIfOnboardingCompleted
        ? "/carbon/reduction-plan"
        : "/carbon/reduction-plan/onboarding-flow",
      type: "heading",
      moduleName: "CARBON_REDUCTION_CALCULATOR",
    },
    {
      permission: "carbon-page",
      cardTitle: "Decarbonization Tool",
      cardIcon: <AppstoreOutlined style={customIconCardStyle.iconStyle} />,
      iconColor: "linear-gradient(180deg, #44C6A6 0%, #31AC8F 100%)",
      cardLink: "/carbon/decarbonization-tool",
      type: "quick nav",
      moduleName: "CARBON_DECARBONIZATION_TOOL",
    },
  ];
  useEffect(() => {
    popularActions.map((card) => {
      if (checkPermissions([card.permission])) {
        setShowPopluarActions(true);
      }
    });
  }, [popularActions]);

  const otherServices = [
    // {
    //   permission: 'carbon-page',
    //   cardTitle: 'Automated Calculation',
    //   cardIcon: <ClusterOutlined style={customIconCardStyle.iconStyle}/>,
    //   cardLink: '/carbon/automated-calculation',
    //   type: 'heading'
    // },
    {
      permission: "carbon-page",
      cardTitle: "Carbon Offset Orders",
      cardIcon: <ShoppingCartOutlined style={customIconCardStyle.iconStyle} />,
      cardLink: "/carbon/carbon-offsets-orders",
      type: "carbon offset",
      moduleName: "CARBON_OFFSETS_MARKETPLACE",
    },
    {
      permission: "carbon-page",
      cardTitle: "Purchase Carbon Offsets",
      cardIcon: <CreditCardOutlined style={customIconCardStyle.iconStyle} />,
      cardLink: "/carbon/carbon-offsets",
      type: "carbon offset",
      moduleName: "CARBON_OFFSETS_MARKETPLACE",
    },
    {
      permission: "carbon-page",
      cardTitle: "Create a Custom Factor",
      cardIcon: <FormOutlined style={customIconCardStyle.iconStyle} />,
      cardLink: "/carbon/custom-factors",
      type: "quick nav",
      moduleName: "CARBON_CUSTOM_FACTOR",
    },
    {
      permission: "carbon-page",
      cardTitle: "View Factors Library",
      cardIcon: <MenuOutlined style={customIconCardStyle.iconStyle} />,
      cardLink: "/carbon/emission-library",
      type: "quick nav",
    },
    {
      permission: "carbon-page",
      cardTitle: "Emissions Factor Methodology",
      cardIcon: <AuditOutlined style={customIconCardStyle.iconStyle} />,
      cardLink: "/carbon/emissions-factor-methodology",
      type: "quick nav",
    },
  ];

  return (
    <ContentSection>
      {popularActions.length > 0 && (
        <>
          {showPopluarActions && (
            <Typography.Title
              level={2}
              style={{
                color: "white",
                fontFamily: "Roboto",
                fontWeight: "500",
                marginTop: "20px",
              }}
            >
              Popular Actions
            </Typography.Title>
          )}
          <CardsWrap>
            {popularActions.map(
              (card, index) =>
                checkPermissions([card.permission]) && (
                  <PopularActionCard
                    title={card.cardTitle}
                    iconColor={card.iconColor}
                    customCardStyle={customIconCardStyle.cardStyle}
                    titleStyle={customIconCardStyle.titleStyle}
                    onClick={onClickCardsSelectSection}
                    iconComponent={card.cardIcon}
                    section={card.cardLink}
                    key={index + 1}
                    isLockModule={card.moduleName ? card.moduleName : false}
                  />
                )
            )}
          </CardsWrap>
        </>
      )}
      {otherServices.length > 0 && checkPermissions(["carbon-page"]) && (
        <>
          <Typography.Title
            level={2}
            style={{
              color: "white",
              fontFamily: "Roboto",
              fontWeight: "500",
              marginTop: "20px",
            }}
          >
            Other Services
          </Typography.Title>

          <CardsWrap>
            {otherServices.map(
              (card, index) =>
                checkPermissions([card.permission]) && (
                  <CustomIconCard
                    title={card.cardTitle}
                    customCardStyle={customIconCardStyle.cardStyle}
                    titleStyle={customIconCardStyle.titleStyle}
                    onClick={onClickCardsSelectSection}
                    iconComponent={card.cardIcon}
                    section={card.cardLink}
                    key={index + 1}
                    isLockModule={card.moduleName ? card.moduleName : false}
                  />
                )
            )}
          </CardsWrap>
        </>
      )}
    </ContentSection>
  );
});
