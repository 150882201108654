import React from "react";
import { Input, Popconfirm, Progress, Row, Space, Table, Tooltip } from "antd";
import { purple } from "@ant-design/colors";
import {
  CheckOutlined,
  CloseOutlined,
  CommentOutlined,
  EyeOutlined,
  FileTextOutlined,
  LoginOutlined,
  RedoOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { YearMonthDayFormat } from "../../../../shared/constants";
import { ActionButtons } from "../DataSheetDocuments/ActionButtons";
import styles from "../DataSheetDocuments/DataSheetDocuments.module.scss";
import tableColumnStyles from "./TableColumns.module.scss";
import { FormWrapper, StyledButton } from "../../../../shared/commonStyles";
import { useCheckRolesPermissions } from "../../../../hooks/useCheckRolesPermissions";
import { RichTextColumn } from "./RichTextColumn";

interface TableColumnsProps {
  dataSheet?: DataSheet;
  dataSheetSchema: SheetSchema[];
  manageColumnData: any[];
  loadingFields: string[];
  disabledFields: string[];
  identifierConnectedValues?: (string | number)[];
  metadataSheet?: DataSheet;
  handleChangeIdentifierField: (value: string, entityName: any) => void;
  companyDetails: any;
  newRecordKey: any;
  editingKey: any;
  isDataQualityScoreShown: boolean;
  onAddNewRecordFiles: (record: DataSheetRecord) => void;
  submitting: boolean;
  handleConfirmCreateRecord: (record: DataSheetRecord) => void;
  handleCancelCreatingRecord: (record: DataSheetRecord) => void;
  lastRevisionData: any;
  isLatestRevision: boolean;
  onClickUpdateRecord: (record: DataSheetRecord, reason?: string) => void;
  onCancelHandler: () => void;
  editReason: string;
  setEditReason: (reason: string) => void;
  onClickAttachedFileBtn: (reason: DataSheetRecord) => void;
  onClickDataSheetFileBtn: (record: DataSheetRecord) => void;
  onChangeHistoryHandler: (record: DataSheetRecord, historyData: any) => void;
  onClickEditRecord: (record: DataSheetRecord) => void;
  onClickRecordLockBtn: (record: DataSheetRecord) => void;
  onClickDeleteRestoreRecord: (
    record: DataSheetRecord,
    isArchived: boolean
  ) => void;
  onUpdateNotes: (notes: Note[], record: DataSheetRecord) => void;
  onClickUpdateRecordNotes: (record: DataSheetRecord, note: string) => void;
  isDeleted: boolean;
  isPending: boolean;
  isDenied: boolean;
  onClickChangeApprovalStatusRecord: (
    record: any,
    status: RecordStatus,
    reason?: string,
    files?: File[]
  ) => void;
  onSubmitOwners: (record: any, users: string[], groups: string[]) => void;
  usersList: User[];
  groupsList: UserGroup[];
  loadingSheetRecords: boolean;
  handleOpenNotesDrawer: (
    currentField: string,
    currentFieldName: string,
    value: any,
    recordId: string,
    currentTab: "comment" | "warning" | "estimate"
  ) => void;
  expandedData?: DataSheetRecord[];
  parentRecord?: DataSheetRecord;
  handleAddFieldNote: (
    currentField: string,
    recordId: string,
    note: string,
    noteType: "comment" | "warning"
  ) => void;
  handleViewRichText: (record: DataSheetRecord, fieldName: string) => void;
  handleResizeColumn: (column: any) => void;
}

export const TableColumns = ({
  dataSheet,
  dataSheetSchema,
  manageColumnData,
  loadingFields,
  disabledFields,
  identifierConnectedValues,
  metadataSheet,
  handleChangeIdentifierField,
  companyDetails,
  newRecordKey,
  isDataQualityScoreShown,
  editingKey,
  onAddNewRecordFiles,
  submitting,
  handleConfirmCreateRecord,
  handleCancelCreatingRecord,
  lastRevisionData,
  isLatestRevision,
  onClickUpdateRecord,
  onCancelHandler,
  editReason,
  setEditReason,
  onClickAttachedFileBtn,
  onClickDataSheetFileBtn,
  onChangeHistoryHandler,
  onClickEditRecord,
  onClickRecordLockBtn,
  onClickDeleteRestoreRecord,
  onUpdateNotes,
  onClickUpdateRecordNotes,
  isDeleted,
  isPending,
  isDenied,
  onClickChangeApprovalStatusRecord,
  onSubmitOwners,
  usersList,
  groupsList,
  loadingSheetRecords,
  handleOpenNotesDrawer,
  expandedData,
  parentRecord,
  handleAddFieldNote,
  handleViewRichText,
  handleResizeColumn,
}: TableColumnsProps) => {
  const tableColumns = () => {
    const { checkRolesPermission } = useCheckRolesPermissions();
    if (dataSheetSchema?.length) {
      const columns: any = dataSheetSchema?.map((item) => {
        const selectedColumn = manageColumnData?.find(
          (data) => data.dataIndex === item?.entity_name
        );

        const dataColumn = {
          title: item.display_name,
          schemaField: item,
          dataType: item.data_type,
          inputType: item.input_type,
          required: item.required,
          dataIndex: item.entity_name,
          width: +selectedColumn?.width
            ? selectedColumn?.width
            : dataSheet?.is_standard_data
              ? item?.input_type === "rich-text"
                ? 550
                : 300
              : 250,
          ellipsis: false,
          editable: true,
          loading: loadingFields,
          disabledFields,
          hidden: selectedColumn?.hide,
          identifierConnectedValues,
          className: expandedData ? "" : "dataSheetColumn",
          isIdentifierConnected: metadataSheet?.sheet_schema.find(
            (value) => value.entity_name === item.metadata_entity_name
          )?.isIdentifier,
          onHeaderCell: (column: any) => ({
            width: column.width,
            onResize: handleResizeColumn(column),
          }),
          handleChangeIdentifierField: (value: string, item: any) =>
            handleChangeIdentifierField(value, item),
          handleViewRichText: (value: DataSheetRecord, fieldName: string) =>
            handleViewRichText(value, fieldName),
          render: (_: any, data: any) => {
            const errorAlert =
              typeof data?.alerts !== "string" &&
              data?.alerts?.find(
                (alertItem: any) => alertItem.field === item?.entity_name
              );
            let newValue: any = null;
            let originalValue = null;
            const originalUnit =
              data?.[data?.sheet_name]?.[item.entity_name + "_actual_unit_id"];
            try {
              if (item?.input_type === "date") {
                const value =
                  data?.[data?.sheet_name as keyof DataSheet]?.[
                  item?.entity_name
                  ];
                if (typeof value === "object" && value !== null) {
                  newValue =
                    value === "" || value === undefined || value === null
                      ? ""
                      : moment.utc(value.$date).format(YearMonthDayFormat);
                } else {
                  newValue =
                    value === "" || value === undefined || value === null
                      ? ""
                      : moment.utc(value).format(YearMonthDayFormat);
                }
              } else if (item?.input_type === "checkbox") {
                const value = data?.[data?.sheet_name]?.[item?.entity_name];
                if (Array.isArray(value)) {
                  newValue = value?.toString();
                } else {
                  newValue = value ? "TRUE" : "FALSE";
                }
              } else if (
                item?.input_type === "number" ||
                item?.data_type === "number"
              ) {
                const newOriginalValue =
                  data?.[data?.sheet_name]?.[
                  item.entity_name + "_actual_value"
                  ];
                originalValue =
                  newOriginalValue !== undefined && newOriginalValue !== null
                    ? Number(newOriginalValue)?.toFixed(
                      +companyDetails.decimal_places_number
                    )
                    : "";

                const value =
                  typeof data?.[data?.sheet_name]?.[item.entity_name] ===
                    "object"
                    ? null
                    : data?.[data?.sheet_name]?.[item.entity_name];

                newValue =
                  value !== undefined && value !== null && value !== ""
                    ? Number(value)?.toFixed(
                      +companyDetails.decimal_places_number
                    )
                    : "";
              } else if (item?.input_type === "rich-text") {
                newValue = (
                  <RichTextColumn data={data} fieldName={item.entity_name} />
                );
              } else {
                const value =
                  typeof data?.[data?.sheet_name]?.[item.entity_name] ===
                    "object"
                    ? null
                    : data?.[data?.sheet_name]?.[item.entity_name];
                originalValue =
                  data?.[data?.sheet_name]?.[
                  item?.entity_name + "_actual_value"
                  ];
                newValue = value;
              }
            } catch (error) {
              console.log("error", error);
              newValue = "";
            }
            const fieldNotes = data?.field_notes?.filter(
              (note: FieldNote) => note?.field === item.entity_name
            );

            let incomingValue =
              parentRecord?.[parentRecord?.sheet_name]?.[item.entity_name] ||
              "Not Available";

            if (item?.input_type === "date") {
              const parsedDate = moment.utc(incomingValue);
              incomingValue = parsedDate.isValid()
                ? parsedDate.format(YearMonthDayFormat)
                : "Not Available";
            } else if (item?.input_type === "number") {
              const parsedValue = Number(incomingValue);
              incomingValue = isNaN(parsedValue)
                ? incomingValue
                : parsedValue.toFixed(+companyDetails.decimal_places_number);
            }

            const columnData = (
              <Row align="middle">
                {!isDeleted && (
                  <>
                    {(incomingValue !== "Not Available" || (originalValue && originalValue !== newValue)) && (
                      <Tooltip
                        overlayClassName="popoverContent"
                        autoAdjustOverflow
                        trigger="hover"
                        title={
                          incomingValue !== null &&  incomingValue !== "Not Available"   ? (
                            <>
                              Incoming Value: {incomingValue}
                              {originalValue && originalValue !== newValue && (
                                <>
                                  <br />
                                  Original Value (in {originalUnit}): {originalValue}
                                </>
                              )}
                            </>
                          ) : (
                            originalValue && (
                              <>
                                Original Value (in {originalUnit}): {originalValue}
                              </>
                            )
                          )
                        }
                      >
                        <div className={tableColumnStyles.DataCell}>
                          <span
                            className={tableColumnStyles.DataCell}
                            onClick={() =>
                              handleOpenNotesDrawer(
                                item.entity_name,
                                item.display_name,
                                newValue,
                                data?._id.$oid,
                                "estimate"
                              )
                            }
                          >
                            {newValue}
                          </span>
                        </div>
                      </Tooltip>
                    )}
                    {/* Always render the note drawer triggers */}
                    
                    <div className={tableColumnStyles.DataCell}>
                    {!(incomingValue !== "Not Available" || (originalValue && originalValue !== newValue)) && <span
                        className={tableColumnStyles.DataCell}
                        onClick={() =>
                          handleOpenNotesDrawer(
                            item.entity_name,
                            item.display_name,
                            newValue,
                            data?._id.$oid,
                            "estimate"
                          )
                        }
                      >
                        {newValue}
                      </span>
                      }
                      {fieldNotes && (
                        <>
                          {fieldNotes.some(
                            (note: Note) =>
                              note.noteType === "estimate" ||
                              note.noteType === "estimatedBoolean"
                          ) && (
                            <FileTextOutlined
                              onClick={() =>
                                handleOpenNotesDrawer(
                                  item.entity_name,
                                  item.display_name,
                                  newValue,
                                  data?._id.$oid,
                                  "estimate"
                                )
                              }
                              style={{ marginRight: 4 }}
                              className={
                                data?.field_notes?.find(
                                  (v: FieldNote) => v.field === item.entity_name
                                ) && "textViolet"
                              }
                            />
                          )}
                          {fieldNotes.some(
                            (note: Note) => note.noteType === "comment"
                          ) && (
                            <CommentOutlined
                              onClick={() =>
                                handleOpenNotesDrawer(
                                  item.entity_name,
                                  item.display_name,
                                  newValue,
                                  data?._id.$oid,
                                  "comment"
                                )
                              }
                              style={{ marginRight: 4 }}
                              className={
                                data?.field_notes?.find(
                                  (v: FieldNote) => v.field === item.entity_name
                                ) && "textViolet"
                              }
                            />
                          )}
                          {fieldNotes.some(
                            (note: Note) => note.noteType === "warning"
                          ) && (
                            <WarningOutlined
                              onClick={() =>
                                handleOpenNotesDrawer(
                                  item.entity_name,
                                  item.display_name,
                                  newValue,
                                  data?._id.$oid,
                                  "warning"
                                )
                              }
                              style={{ marginRight: 4 }}
                              className={
                                data?.field_notes?.find(
                                  (v: FieldNote) => v.field === item.entity_name
                                ) && "textOrange"
                              }
                            />
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
              </Row>
            );
            

            return (
              <>
                {errorAlert ? (
                  <Tooltip
                    overlayClassName={`popoverContent`}
                    trigger={"hover"}
                    overlayStyle={{
                      padding: "10px",
                    }}
                    placement="left"
                    autoAdjustOverflow
                    title={
                      <>
                        <b className={styles.tooltipTitle}>
                          {errorAlert?.alert_type === "warning"
                            ? "Warning"
                            : "Alert"}
                        </b>
                        <br />
                        <span className={styles.tooltipText}>
                          {errorAlert?.alert_desc}
                        </span>
                      </>
                    }
                  >
                    <span
                      style={{
                        color:
                          errorAlert?.alert_type === "warning"
                            ? "orange"
                            : "red",
                      }}
                      className={
                        errorAlert?.alert_type === "warning"
                          ? "textOrange"
                          : "textRed"
                      }
                    >
                      {columnData || <WarningOutlined />}
                    </span>
                  </Tooltip>
                ) : (
                  // ) : originalValue &&
                  //   originalValue !== newValue &&
                  //   !isPending ? (
                  //   <Tooltip
                  //     overlayClassName="popoverContent"
                  //     autoAdjustOverflow
                  //     trigger="hover"
                  //     title={<>Original value: {originalValue}</>}
                  //   >
                  //     <span>{columnData}</span>
                  //   </Tooltip>
                  // ) : (
                  //   <>{columnData}</>
                  // )}
                  <>{columnData}</>
                )}
              </>
            );
          },
        };
        const changeColumn = {
          title: item.display_name + " Change",
          dataType: item.data_type,
          inputType: item.input_type,
          schemaField: item,
          required: item.required,
          dataIndex: item.entity_name,
          width: selectedColumn?.width || 250,
          ellipsis: false,
          editable: false,
          loading: loadingFields,
          disabledFields,
          hidden: selectedColumn?.hide,
          className: expandedData ? "" : "dataSheetColumn",
          onHeaderCell: (column: any) => ({
            width: column.width,
            onResize: handleResizeColumn(column),
          }),
          render: (_: any, data: DataSheetRecord, index: number) => {
            const prevRecord =
              index === 0 ? parentRecord : expandedData?.[index - 1];

            const parentValue =
              typeof prevRecord?.[prevRecord?.sheet_name]?.[
                item.entity_name
              ] === "number"
                ? prevRecord?.[prevRecord?.sheet_name]?.[item.entity_name]
                : 0;
            const currentValue =
              typeof data?.[data?.sheet_name]?.[item.entity_name] === "number"
                ? data?.[data?.sheet_name]?.[item.entity_name]
                : 0;
            // let incomingValue =
            //   parentRecord?.[parentRecord?.sheet_name]?.[item.entity_name] ||
            //   "Not Available";

            // if (item?.input_type === "date") {
            //   incomingValue = moment
            //     .utc(incomingValue)
            //     .format(YearMonthDayFormat);
            // } else if (item?.input_type === "number") {
            //   const parsedValue = Number(incomingValue);
            //   incomingValue = isNaN(parsedValue)
            //     ? incomingValue
            //     : parsedValue.toFixed(+companyDetails.decimal_places_number);
            // }

            const changeValue = currentValue - parentValue;
            const percentValue =
              parentValue === currentValue
                ? 0
                : currentValue === 0
                  ? parentValue > 0
                    ? -100
                    : 100
                  : (changeValue / currentValue) * 100;

            return <>{percentValue.toFixed(2)}%</>;
          },
        };

        const selectedColumnUnit = manageColumnData?.find(
          (data) => data.dataIndex === item.entity_name + "_unit_id"
        );

        const unitColumn = {
          title: item.display_name + " Unit",
          schemaField: {
            ...item,
            options: item?.convertable_units?.map((v: any) => {
              return { value: v.unit, label: v.name };
            }),
          },
          dataType: "dropdown",
          dataIndex: item.entity_name + "_unit_id",
          width: selectedColumnUnit?.width || 100,
          onHeaderCell: (column: any) => ({
            width: column.width,
            onResize: handleResizeColumn(column),
          }),
          inputType: "dropdown",
          className: expandedData ? "" : "dataSheetColumn",
          editable: !!newRecordKey.length,
          disabledFields,
          hidden: selectedColumnUnit?.hide,
          render: (_: any, data: any) => {
            try {
              const originalValue =
                data?.[data?.sheet_name]?.[
                item.entity_name + "_actual_unit_id"
                ];
              const value =
                data?.[data?.sheet_name]?.[item.entity_name + "_unit_id"] ||
                item.unit_id;
              const fieldNotes = data?.field_notes?.filter(
                (note: FieldNote) =>
                  note?.field === item.entity_name + "_unit_id"
              );

              const columnData = (
                <Row align="middle">
                  {!isDeleted &&
                    ((isDenied &&
                      isPending &&
                      data?.review_status === "PENDING") ||
                      (!isDenied && !isPending)) ? (
                    <div className={tableColumnStyles.DataCell}>
                      <span
                        className={tableColumnStyles.DataCell}
                        onClick={() =>
                          handleOpenNotesDrawer(
                            item.entity_name + "_unit_id",
                            item.display_name,
                            value,
                            data?._id.$oid,
                            "estimate"
                          )
                        }
                      >
                        {value}
                      </span>

                      {/* {fieldNotes && (
                        <>
                          {fieldNotes.some(
                            (note: Note) =>
                              note.noteType === "estimate" ||
                              note.noteType === "estimatedBoolean"
                          ) && (
                            <FileTextOutlined
                              onClick={() =>
                                handleOpenNotesDrawer(
                                  item.entity_name + "_unit_id",
                                  item.display_name,
                                  value,
                                  data?._id.$oid,
                                  "estimate"
                                )
                              }
                              style={{ marginRight: 4 }}
                              className={
                                data?.field_notes?.find(
                                  (v: FieldNote) =>
                                    v.field === item.entity_name + "_unit_id"
                                ) && "textViolet"
                              }
                            />
                          )}
                          {fieldNotes.some(
                            (note: Note) => note.noteType === "comment"
                          ) && (
                            <CommentOutlined
                              onClick={() =>
                                handleOpenNotesDrawer(
                                  item.entity_name + "_unit_id",
                                  item.display_name,
                                  value,
                                  data?._id.$oid,
                                  "comment"
                                )
                              }
                              style={{ marginRight: 4 }}
                              className={
                                data?.field_notes?.find(
                                  (v: FieldNote) =>
                                    v.field === item.entity_name + "_unit_id"
                                ) && "textViolet"
                              }
                            />
                          )}
                          {fieldNotes.some(
                            (note: Note) => note.noteType === "warning"
                          ) && (
                            <WarningOutlined
                              onClick={() =>
                                handleOpenNotesDrawer(
                                  item.entity_name + "_unit_id",
                                  item.display_name,
                                  value,
                                  data?._id.$oid,
                                  "warning"
                                )
                              }
                              style={{ marginRight: 4 }}
                              className={
                                data?.field_notes?.find(
                                  (v: FieldNote) =>
                                    v.field === item.entity_name + "_unit_id"
                                ) && "textOrange"
                              }
                            />
                          )}
                        </>
                      )} */}
                    </div>
                  ) : (
                    value
                  )}
                </Row>
              );
              return (
                <>
                  {originalValue && originalValue !== value ? (
                    <Tooltip
                      overlayClassName="popoverContent"
                      autoAdjustOverflow
                      title={<>Original unit {originalValue}</>}
                      trigger="hover"
                    >
                      {columnData}
                    </Tooltip>
                  ) : (
                    <>{columnData}</>
                  )}
                </>
              );
            } catch (error) {
              console.log("error", error);
              return "";
            }
          },
        };

        if (
          item.unit_id &&
          manageColumnData.find(
            (v) => !v.hide && v.dataIndex === item.entity_name
          )
        ) {
          const column = {
            dataIndex: item.entity_name,
            children: expandedData
              ? [dataColumn, changeColumn, unitColumn]
              : [dataColumn, unitColumn],
          };
          return column;
        }
        if (
          item.data_type === "number" &&
          expandedData &&
          manageColumnData.find(
            (v) => !v.hide && v.dataIndex === item.entity_name
          )
        ) {
          const column = {
            dataIndex: item.entity_name,
            children: [dataColumn, changeColumn],
          };
          return column;
        }
        return dataColumn;
      });
      isDataQualityScoreShown &&
        columns.push({
          title: "Data Quality",
          dataIndex: "dataQualityScore",
          fixed: "right",
          width: "150px",
          className: "actionsRightBorder",
          render: (_: any, record: DataSheetRecord) => {
            return (
              <>
                <Progress
                  percent={record.qualityScore?.percent}
                  steps={3}
                  strokeColor={[purple[2], purple[3], purple[4]]}
                  trailColor="#4E4268"
                  showInfo={false}
                />
                <span className={styles.dataQualityScoreLabel}>
                  {record.qualityScore?.label || "N/A"}
                </span>
              </>
            );
          },
        });
      columns.push({
        title: "Survey name",
        dataIndex: "surveyName",
        className: expandedData ? "" : "dataSheetColumn",
        hidden: manageColumnData?.find(
          (data) => data.dataIndex === "surveyName"
        )?.hide,
      });

      columns.push({
        title: "Username",
        dataIndex: "userName",
        className: expandedData ? "" : "dataSheetColumn",
        hidden: manageColumnData?.find((data) => data.dataIndex === "userName")
          ?.hide,
      });
      columns.push({
        title: "Review Status",
        dataIndex: "reviewStatus",
        className: expandedData ? "" : "dataSheetColumn",
        hidden: manageColumnData?.find(
          (data) => data.dataIndex === "reviewStatus"
        )?.hide,
      });

      columns.push({
        title: "User-entered KPI Value",
        dataIndex: "userEnteredKPIValue",
        fixed: "right",
        width: "100px",
        hidden: manageColumnData?.find(
          (data) => data.dataIndex === "userEnteredKPIValue"
        )?.hide,
        render: (_: any, record: DataSheetRecord) => {
          return (
            <><span>{record[record.sheet_name].enteredValue}</span></>
          );
        }
      });

      !expandedData &&
        columns.push({
          title: "Actions",
          dataIndex: "action",
          fixed: "right",
          width: "50px",
          render: (_: any, record: DataSheetRecord) => {
            const editable = record?.["_id"]?.["$oid"]
              ? record?.["_id"]?.["$oid"] === editingKey
              : record?.key === editingKey;
            return editable ? (
              newRecordKey ? (
                <>
                  <Space direction="horizontal">
                    <StyledButton
                      type="default"
                      icon={<FileTextOutlined />}
                      onClick={() => onAddNewRecordFiles(record)}
                    >
                      Files
                    </StyledButton>
                    <StyledButton
                      type="primary"
                      loading={submitting}
                      icon={<CheckOutlined />}
                      onClick={() => handleConfirmCreateRecord(record)}
                    >
                      Confirm
                    </StyledButton>
                    <StyledButton
                      type="default"
                      icon={<CloseOutlined />}
                      onClick={() => handleCancelCreatingRecord(record)}
                    >
                      Cancel
                    </StyledButton>
                  </Space>
                </>
              ) : (
                <>
                  <Space direction="horizontal">
                    {lastRevisionData ? (
                      <>
                        {!checkRolesPermission(["role:auditor"]) && (
                          <Tooltip
                            title={
                              isLatestRevision
                                ? "This is the latest version and cannot be restored."
                                : ""
                            }
                            placement="topLeft"
                          >
                            <StyledButton
                              type="default"
                              loading={submitting}
                              disabled={isLatestRevision}
                              onClick={() => onClickUpdateRecord(record)}
                              icon={<RedoOutlined />}
                            >
                              Restore this version
                            </StyledButton>
                          </Tooltip>
                        )}
                        <StyledButton
                          type="default"
                          onClick={() => onCancelHandler()}
                          icon={<LoginOutlined />}
                        >
                          Go to current version
                        </StyledButton>
                      </>
                    ) : (
                      <>
                        <StyledButton type="default" onClick={onCancelHandler}>
                          Cancel
                        </StyledButton>
                        <Popconfirm
                          disabled={!dataSheet?.enable_change_history}
                          title={
                            <FormWrapper>
                              <div>Reason</div>
                              <Input.TextArea
                                value={editReason}
                                onChange={(e) => setEditReason(e.target.value)}
                              />
                            </FormWrapper>
                          }
                          onConfirm={() => {
                            onClickUpdateRecord(record, editReason);
                          }}
                          okText="Confirm"
                          cancelText="Cancel"
                          placement="bottom"
                          icon={<></>}
                          overlayClassName="popoverContent reasonPopover"
                        >
                          <StyledButton
                            type="default"
                            onClick={() => {
                              !dataSheet?.enable_change_history &&
                                onClickUpdateRecord(record);
                              setEditReason("");
                            }}
                          >
                            Update
                          </StyledButton>
                        </Popconfirm>
                      </>
                    )}
                  </Space>
                </>
              )
            ) : (
              <ActionButtons
                record={record}
                columns={columns}
                areNotesOn={!!dataSheet?.enable_comments}
                isHistoryOn={!!dataSheet?.enable_change_history}
                onClickAttachedFileBtn={onClickAttachedFileBtn}
                onClickDataSheetFileBtn={onClickDataSheetFileBtn}
                onChangeHistoryHandler={onChangeHistoryHandler}
                onClickEditRecord={onClickEditRecord}
                onClickRecordLockBtn={onClickRecordLockBtn}
                onClickDeleteRestoreRecord={onClickDeleteRestoreRecord}
                onUpdateNotes={onUpdateNotes}
                onSubmit={(note) => onClickUpdateRecordNotes(record, note)}
                isDeleted={isDeleted}
                isPending={isPending}
                isDenied={isDenied}
                onClickChangeApprovalStatusRecord={
                  onClickChangeApprovalStatusRecord
                }
                onSubmitOwners={onSubmitOwners}
                usersList={usersList}
                groupsList={groupsList}
                loading={loadingSheetRecords}
              />
            );
          },
        });
      return columns;
    }
    return [];
  };
  return tableColumns();
};
