import { makeAutoObservable, runInAction } from "mobx";
import {
  doConversion,
  fetchRecordData,
  getDataLakeVarianceDetection,
  getModifiedForMetaData,
  getSheetById,
  insertData,
  updateData,
  uploadDataSheetFileNew,
} from "../services/dataSheet";
import {
  getDataLakeFiles,
  getSelectedStandardForSurveyById,
  parsedStandardData,
  updateSelectedStandards,
} from "../services/standards";
import {
  handleCheckMultilineValidation,
  handleCheckSingleValidation,
  uploadSingleDataSheetFile,
  uploadDataLakeFile,
  getFilesSourceKpiId,
} from "../pages/viewSurveyForAdmin/Components/SurveyFunctions";
import surveyKpiService from "../services/SurveyKpiService";
import { API, Auth } from "aws-amplify";
import { message } from "antd";
import { getSurveyById } from "../services/surveys";
import { getTaskDeploymentById } from "../services/taskDeployment";
import { getKpiMetric } from "../services/kpi";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import path from "path";
import { getFirstAndLastNameByUsername } from "../shared/commonFunctions";
import { getFilesById } from "../services/importData";
import * as queries from "../graphql/queries";

class SurveyStore {
  surveyId: string;
  taskDeploymentId?: string;
  taskDeployment: SurveyDeployment;
  surveyInfo: SurveyInfo;
  surveyLoading: boolean;
  surveySubmitting: boolean;
  currentStep: number;
  isAutosave: boolean;
  surveySteps: (
    | StandardSurveyStep
    | InfoSurveyStep
    | DataSheetSurveyStep
    | KPISurveyStep
  )[];
  constructor() {
    this.surveyId = "";
    this.taskDeploymentId = "";
    this.taskDeployment = {} as SurveyDeployment;
    this.surveyInfo = {} as SurveyInfo;
    this.surveyLoading = false;
    this.isAutosave = false;
    this.surveySubmitting = false;
    this.currentStep = 0;
    this.surveySteps = [];

    makeAutoObservable(this, {}, { autoBind: true });
  }

  resetState() {
    this.surveyId = "";
    this.taskDeploymentId = "";
    this.surveyInfo = {} as SurveyInfo;
    this.surveyLoading = true;
    this.surveySubmitting = false;
    this.currentStep = 0;
    this.surveySteps = [];
  }

  get dataSheetRecords() {
    return (this.surveySteps?.[this.currentStep] as DataSheetSurveyStep)
      ?.submissions;
  }

  get informationData() {
    return this.surveyInfo?.survey_order?.[this.currentStep]?.survey_type ===
      "standards"
      ? (this.surveySteps?.[this.currentStep] as StandardSurveyStep)?.data
      : this.surveyInfo?.survey_order?.[this.currentStep]?.survey_type ===
          "data_sheets" &&
          (this.surveySteps?.[this.currentStep] as DataSheetSurveyStep)
            ?.dataSheet;
  }

  get currentStepData() {
    return this.surveySteps?.[this.currentStep] as DataSheetSurveyStep;
  }

  get currentStepInfo() {
    return this.surveyInfo?.survey_order?.[this.currentStep];
  }

  get standardData() {
    return (this.surveySteps?.[this.currentStep] as StandardSurveyStep)?.data;
  }

  get dataSheet() {
    return (this.currentStepData as DataSheetSurveyStep).dataSheet;
  }
  get dataToSave() {
    return this.surveyInfo.survey_type === "kpi"
      ? ((this.currentStepData as any).dataToSave as SurveyKPI[])
      : (this.currentStepData.dataToSave as DataSheetRecord[]);
  }
  get defaultMetaDataRecord() {
    return this.surveyInfo?.defaultMetadataRecord;
  }

  get schemaToSave() {
    return this.dataSheet?.sheet_schema?.filter(
      (v) =>
        !v.isExpression &&
        v.input_type !== "expression" &&
        (this.currentStepInfo?.display_fields?.length
          ? this.currentStepInfo?.display_fields?.find(
              (item: string) => v.entity_name === item
            )
          : this.currentStepInfo?.hide_fields && v.metadata_entity_name)
    );
  }

  get requiredSchemaFields() {
    return this.schemaToSave
      ?.filter((schema) => schema.required)
      .map((v) => v.entity_name);
  }

  async getSurveyById(
    id: string,
    settingValue: any[],
    taskDeploymentId?: string,
    metadataRecordId?: string
  ) {
    this.changeSurveyLoading(true);
    this.surveyId = id;
    if (taskDeploymentId) {
      this.changeTaskDeploymentId(taskDeploymentId);
      const currentTask = await getTaskDeploymentById(taskDeploymentId);
      runInAction(() => {
        this.changeTaskDeployment(currentTask);
      });
    }
    const surveyInfo = await getSurveyById(id);
    if (!surveyInfo) {
      message.error("Survey not found");
      return Promise.reject();
    }

    const surveyOrder = JSON.parse(surveyInfo?.survey_order);
    const processedSteps: any = [];
    let metadataRecord: DataSheetRecord = {} as DataSheetRecord;
    let identifierData = {};

    if (surveyInfo?.defaultMetaDataSheetId) {
      const metaDataSsheet = await getSheetById(
        surveyInfo?.defaultMetaDataSheetId
      );
      const idnfFieldData = metaDataSsheet.sheet_schema.find(
        (v: SheetSchema) => v.isIdentifier
      );

      if (metadataRecordId) {
        metadataRecord = await this.getMetadataRecord(
          surveyInfo?.defaultMetaDataSheetId,
          metadataRecordId
        ).catch(() => {
          return Promise.reject();
        });
        identifierData = {
          idnfDisplayName: idnfFieldData?.display_name,
          idnfEntityName: idnfFieldData?.entity_name,
          idnfValue: metadataRecord?.[idnfFieldData?.entity_name],
        };
      } else {
        const foundUserSettings = settingValue?.find(
          (v) => v.metadata_sheet_id === surveyInfo?.defaultMetaDataSheetId
        );
        if (foundUserSettings) {
          metadataRecord = await this.getMetadataRecord(
            surveyInfo?.defaultMetaDataSheetId,
            foundUserSettings?.record_id
          ).catch(() => {
            return Promise.reject();
          });
          identifierData = {
            idnfDisplayName: idnfFieldData?.display_name,
            idnfEntityName: idnfFieldData?.entity_name,
            idnfValue: metadataRecord?.[idnfFieldData?.entity_name],
          };
        } else {
          message.error("Something went wrong while load flow items!");
          return Promise.reject();
        }
      }
    }
    runInAction(() => {
      this.surveyInfo = {
        ...surveyInfo,
        survey_order: surveyOrder,
        ...(surveyInfo?.defaultMetaDataSheetId && {
          defaultMetadataRecord: metadataRecord,
          identifierData: identifierData,
        }),
      };
    });

    const steps =
      surveyInfo.survey_type === "kpi"
        ? await this.loadKPIsSurveyData()
        : await Promise.all(
            surveyOrder?.map(async (item: any, index: number) => {
              if (item.survey_type === "data_sheets") {
                const dataSheet = await getSheetById(item.id);

                if (dataSheet && !dataSheet.archive) {
                  const sheetRecords = await this.getDataSheetRecords(
                    dataSheet?._id.$oid
                  );
                  const status = this.checkStepStatus(
                    item,
                    dataSheet,
                    sheetRecords?.data
                  );
                  const limits = await getDataLakeVarianceDetection(
                    dataSheet?._id?.$oid
                  );
                  let validationFields: {
                    recordId: string;
                    fields: string[];
                  }[] = [];
                  if (dataSheet?.validationRules?.length) {
                    const { values, validation } =
                      handleCheckMultilineValidation(
                        dataSheet,
                        sheetRecords?.data?.filter(
                          (v: DataSheetRecord) => !v.import_status
                        ),
                        !item.multiline_form_flag,
                        false
                      );
                    const isValid = !values?.some((v: any) => v === false);
                    if (!isValid && !!validation?.length) {
                      validationFields = validation;
                    }
                  }
                  const popconfirms: PopconfirmAlert[] = [];
                  sheetRecords?.data?.forEach(
                    (record: DataSheetRecord) =>
                      record.alerts?.length &&
                      record.alerts?.forEach((alert) =>
                        popconfirms.push({
                          name: alert.field,
                          isOpen: false,
                          warning_word: (alert.warning_word === "above"
                            ? "higher"
                            : "lower") as any,
                          reason: alert.alert_desc.split(". ")[1] || "",
                          recordId: record?._id?.$oid,
                          ...(alert.alert_type && {
                            alert_type: alert.alert_type,
                          }),
                        })
                      )
                  );

                  const stepData = {
                    dataSheet,
                    status,
                    limits: limits,
                    settings: item,
                    submissions: sheetRecords?.data || [],
                    stepName: dataSheet?.sheet_name || `Step ${index + 1}`,
                    popconfirms,
                    ...(validationFields?.length && {
                      validation: validationFields,
                      fieldsStatus: "error",
                    }),
                    dataToSave: item?.multiline_form_flag
                      ? []
                      : [
                          sheetRecords?.data?.length
                            ? { ...sheetRecords?.data[0] }
                            : {},
                        ],
                  } as DataSheetSurveyStep;

                  return stepData;
                } else return { stepName: `Step ${index + 1}` };
              }
              if (item.survey_type === "welcome_page") {
                const welcomeData = {
                  ...item,
                  stepName:
                    (item as InfoSurveyStep)?.subject || `Step ${index + 1}`,
                } as InfoSurveyStep;
                processedSteps[index] = welcomeData;
                return welcomeData;
              }
              if (item.survey_type === "standards") {
                const selectedStandard = await this.getStandardData(item?.id);
                if (selectedStandard) {
                  const standardData = {
                    data: selectedStandard,
                    dataToSave: selectedStandard,
                    stepName: selectedStandard?.metric || `Step ${index + 1}`,
                  } as StandardSurveyStep;
                  processedSteps[index] = standardData;
                  return standardData;
                } else
                  return {
                    stepName: `Step ${index + 1}`,
                  } as StandardSurveyStep;
              }
            })
          );
    runInAction(() => {
      this.surveySteps = steps;
      this.changeSurveyLoading(false);
    });
  }

  async fetchSurveyData() {
    try {
      const data: any = await Auth.currentSession();
      const username = data.accessToken.payload.username;

      const surveyOrderArray = Array.isArray(this.surveyInfo.survey_order)
        ? this.surveyInfo.survey_order
        : [];

      const uniqueSheetIds = Array.from(
        new Set(surveyOrderArray.map((order) => order.sheet_id))
      );

      const fetchPromises = uniqueSheetIds.map((sheetId) => {
        let recordsQuery = {
          skip: 0,
          sheet_id: sheetId,
          shouldFilterByRole: false,
        };
        return fetchRecordData(recordsQuery);
      });

      const response = await Promise.all(fetchPromises);

      return response.flatMap((res) => res.data || []);
    } catch (error) {
      return Promise.reject();
    }
  }

  async loadKPIsSurveyData() {
    try {
      const data: any = await Auth.currentSession();
      const username = data.accessToken.payload.username;

      const surveyOrderArray = Array.isArray(this.surveyInfo.survey_order)
        ? this.surveyInfo.survey_order
        : [];

      const kpisBySheetId = surveyOrderArray.reduce((acc, order) => {
        if (order.survey_type === "kpi") {
          if (!acc[order.sheet_id]) {
            acc[order.sheet_id] = [];
          }
          acc[order.sheet_id].push(order.id);
        }
        return acc;
      }, {} as Record<string, string[]>);

      const fetchPromises = Object.entries(kpisBySheetId).map(
        ([sheetId, kpiIds]) => {
          const recordsQuery = {
            skip: 0,
            limit: 1000,
            sheet_id: sheetId,
            filter: {
              _id: { $in: kpiIds },
            },
            shouldFilterByRole: false,
          };
          return fetchRecordData(recordsQuery);
        }
      );

      const responses = await Promise.all(fetchPromises);
      const response = { data: responses.flatMap((r) => r.data) };

      const kpisList: KPI[] = response.data.map((kpi: any) => ({
        aggregate: [],
        aggregateColumn: "",
        aggregateOp: "",
        analyticResult: {},
        autoRefresh: undefined,
        group_id: kpi.group_id || null,
        description: kpi[(kpi as any).sheet_name]?.kpi_description || null,
        filters: [],
        name:
          kpi[(kpi as any).sheet_name]?.kpi_name ||
          (kpi as any).sheet_name ||
          "Unnamed KPI",
        sheetId: kpi.sheet_id?.$oid || null,
        username: username || null,
        _id: kpi._id || { $oid: "unknown" },
        hasMissingFields: false,
        files: kpi.files || [],
        notes: kpi.notes || [],
        reviewStatus: kpi.review_status,
        filesList: kpi.filesList || [],
        required: kpi.required || false,
      }));

      const selectedKpiIds = Array.isArray(this.surveyInfo.survey_order)
        ? this.surveyInfo.survey_order
            .filter((order) => order.survey_type === "kpi")
            .map((order) => order.id)
        : [];

      const kpiOrderMap = new Map(
        selectedKpiIds.map((id, index) => [id, index])
      );

      const kpisData = await Promise.all(
        kpisList
          .filter((kpi) => selectedKpiIds.includes(kpi._id.$oid))
          .map(async (kpi) => {
            const foundOrder = surveyOrderArray.find(
              (o) => o.id === kpi._id.$oid
            );

            const files: KPIFile[] = [];
            if (kpi.files?.length) {
              for (const file of kpi.files) {
                const result = file.isExcel
                  ? await getFilesById(
                      file.fileId,
                      "dataSheetImport",
                      "DATASHEET_LIST"
                    )
                  : (await getDataLakeFiles(file.fileId)) ||
                    (await getFilesById(
                      file.fileId,
                      "dataSheetImport",
                      "DATASHEET_LIST"
                    ));

                if (result) {
                  const fileName = path.basename(
                    result.replace(/['"]+/g, "").replace(/^\[(.+)\]$/, "$1")
                  );
                  files.push({ ...file, name: fileName });
                }
              }
            }
            return {
              _id: { $oid: kpi._id.$oid },
              name: kpi.name,
              mainKpiId: kpi._id.$oid,
              mainKPISheetId: kpi.sheetId,
              required: foundOrder?.required ?? false,
              username: kpi.username || "",
              surveyId: this.surveyId,
              reviewStatus: kpi.reviewStatus || ("NOT_STARTED" as RecordStatus),
              notes: kpi.notes || [],
              statusReasons: [],
              files,
              filesList: kpi.filesList || [],
              enteredValue: 0,
            };
          })
      );

      const sortedKpisData = kpisData.sort((a, b) => {
        const indexA = kpiOrderMap.get(a.mainKpiId) ?? Number.MAX_SAFE_INTEGER;
        const indexB = kpiOrderMap.get(b.mainKpiId) ?? Number.MAX_SAFE_INTEGER;
        return indexA - indexB;
      });

      return [
        { data: kpisList, dataToSave: sortedKpisData },
      ] as KPISurveyStep[];
    } catch (error) {
      return Promise.reject();
    }
  }

  checkStepStatus(
    surveyData: any,
    dataSheetInfo: DataSheet,
    sheetRecords?: DataSheetRecord[]
  ) {
    const filteredSchema = dataSheetInfo?.sheet_schema?.filter(
      (schema) =>
        !schema.isExpression &&
        schema.input_type !== "expression" &&
        !schema?.metadata_entity_name &&
        surveyData?.display_fields &&
        surveyData?.display_fields?.find(
          (v: string) => v === schema.entity_name
        )
    );
    const requiredFields = filteredSchema
      ?.filter((schema) => schema.required)
      ?.map((v) => v.entity_name);
    const isActionRequired =
      sheetRecords?.length &&
      !!sheetRecords?.find((record, i) => {
        const isAcknowledged = record.field_notes
          ?.filter((note) =>
            filteredSchema?.find(
              (v) =>
                v.entity_name === note.field ||
                v.entity_name + "_unit_id" === note.field
            )
          )
          ?.find((note) => note.isAddedByAdmin && !note.isAcknowledged);

        return isAcknowledged;
      });
    const isRequiredFieldEmpty =
      requiredFields?.length &&
      sheetRecords?.length &&
      sheetRecords
        ?.filter(
          (v) =>
            v.review_status === RecordStatus.IN_PROGRESS ||
            v.review_status === RecordStatus.PENDING ||
            v.review_status === RecordStatus.DENIED
        )
        ?.find((item) => {
          return (
            !requiredFields?.every((v) =>
              Object.keys(item[item.sheet_name]).find((key) => key === v)
            ) ||
            Object.entries(item[item.sheet_name]).find(([key, value]) => {
              return !!requiredFields?.includes(key) && !value && value !== 0;
            })
          );
        });
    if (!sheetRecords?.length) {
      return requiredFields?.length ? "requiredEmpty" : "empty";
    } else if (isActionRequired && isRequiredFieldEmpty) {
      return "mixedrequired";
    } else if (isActionRequired) {
      return "actionRequired";
    } else if (isRequiredFieldEmpty) {
      return "requiredEmpty";
    } else return "completed";
  }

  async getDataSheetRecords(sheetId: string) {
    const data: any = await Auth.currentSession();
    const username = data.accessToken.payload.username;
    const payload = {
      sheet_id: sheetId,
      skip: 0,
      limit: 1000,
      record_types: {
        include: [],
        exclude: RecordTypes.ARCHIVED,
      },
      filter: this.taskDeploymentId?.length
        ? {
            taskDeploymentId: this.taskDeploymentId,
          }
        : {
            username: username,
            data_survey_id: this.surveyId,
          },
      sort: { _id: -1 },
      ...(!!this.taskDeployment?.assignedGroup && {
        shouldFilterByRole: false,
      }),
    };
    const result = await fetchRecordData(payload);
    return result;
  }

  async getMetadataRecord(metadataSheetId: string, recordId: string) {
    const payload = {
      filter: {
        sheet_id: metadataSheetId,
      },
      search_query: {
        id: recordId,
      },
    };

    const metaDataRecord = await getModifiedForMetaData(payload);
    if (metaDataRecord) {
      const record = metaDataRecord[metaDataRecord?.sheet_name];
      return record[0];
    } else return Promise.reject();
  }

  async getStandardData(id: string) {
    const data = await getSelectedStandardForSurveyById(id);
    const parsedData = await parsedStandardData(data, "reporting");
    return parsedData;
  }

  async updateStandardData(isStepChanged?: boolean, newStep?: number) {
    this.changeSurveySubmitting(true);
    const dataToSave: any = (
      this.surveySteps[this.currentStep] as StandardSurveyStep
    ).dataToSave;

    const files = (this.surveySteps[this.currentStep] as StandardSurveyStep)
      .files;
    delete dataToSave?.modalTitle;
    delete dataToSave?.standardLink;
    delete dataToSave?.standards;
    const formJson = JSON.stringify(dataToSave?.form_json_code);
    const formUi = JSON.stringify(dataToSave?.form_ui_code);
    const dataToSubmit = {
      ...dataToSave,
      form_json_code: formJson,
      form_ui_code: formUi,
      modify_reason: "",
    };
    const response = await updateSelectedStandards(dataToSubmit);
    if (response) {
      if (files?.length) {
        for (const file of files) {
          await uploadDataLakeFile(file, dataToSave?._id.$oid);
        }
      }
      const newData = await this.getStandardData(dataToSave?._id.$oid);
      runInAction(() => {
        this.changeSurveyData(newData, "data");
        this.changeSurveyData(newData, "dataToSave");
      });
    }
    runInAction(() => {
      isStepChanged && this.changeCurrentStep(newStep!);
      this.changeSurveySubmitting(false);
    });
  }

  // 3rd optional paramteer to force update
  async saveDataSheetRecords(
    isStepChanged?: boolean,
    newStep?: number,
    is_force_update?: boolean
  ) {
    this.changeSurveySubmitting(true);
    try {
      if (this.currentStepInfo?.multiline_form_flag) {
        await this.saveMultilineRecords().catch((error) => {
          return Promise.reject(error);
        });
      } else {
        if (
          this.currentStepData?.dataToSave?.filter(
            (v) => v.sheet_type === "new"
          )?.length
        ) {
          await this.addSingleRecord(
            this.currentStepData?.dataToSave?.filter(
              (v) => v.sheet_type === "new"
            )[0],
            this.dataSheet,
            this.schemaToSave
          ).catch((error) => {
            return Promise.reject(error);
          });
        }

        if (
          this.currentStepData?.dataToSave?.filter(
            (v) => v.sheet_type === "edited"
          )?.length
        ) {
          await this.updateSingleRecord(
            this.currentStepData?.dataToSave?.filter(
              (v) => v.sheet_type === "edited"
            )[0],
            this.dataSheet,
            this.schemaToSave
          ).catch((error) => {
            return Promise.reject(error);
          });
        } else {
          if (is_force_update) {
            // we force update if nothing was changed. this is to get out of denied status
            await this.updateSingleRecord(
              this.currentStepData?.dataToSave?.filter(
                (v) => v.sheet_type !== null
              )[0],
              this.dataSheet,
              this.schemaToSave
            ).catch((error) => {
              return Promise.reject(error);
            });
          }
        }
      }

      await this.switchToNewStep(isStepChanged, newStep);
    } catch (error) {
      this.changeSurveySubmitting(false);
      return Promise.reject(error);
    }
  }
  async switchToNewStep(isStepChanged?: boolean, newStep?: number) {
    const newStepData = this.dataSheet
      ? await this.getDataSheetRecords(this.dataSheet?._id.$oid)
      : [];
    let validationFields: {
      recordId: string;
      fields: string[];
    }[] = [];
    const popconfirms: PopconfirmAlert[] = [];
    newStepData?.data?.forEach(
      (record: DataSheetRecord) =>
        record.alerts?.length &&
        record.alerts?.forEach((alert) =>
          popconfirms.push({
            name: alert.field,
            isOpen: false,
            warning_word: (alert.warning_word === "above"
              ? "higher"
              : "lower") as any,
            reason: alert.alert_desc.split(". ")[1] || "",
            recordId: record?._id?.$oid,
            ...(alert.alert_type && { alert_type: alert.alert_type }),
          })
        )
    );
    if (this.dataSheet?.validationRules?.length) {
      const { values, validation } = handleCheckMultilineValidation(
        this.dataSheet,
        newStepData?.data?.filter((v: DataSheetRecord) => !v.import_status),
        !this.currentStepInfo.multiline_form_flag,
        false
      );
      const isValid = !values?.some((v: any) => v === false);
      if (!isValid && !!validation?.length) {
        validationFields = validation;
      }
    }
    const limits = this.dataSheet
      ? await getDataLakeVarianceDetection(this.dataSheet?._id?.$oid)
      : null;
    runInAction(() => {
      const status = this.checkStepStatus(
        this.currentStepInfo,
        this.dataSheet,
        newStepData.data
      );
      this.changeSurveyData(status, "status");

      status === "requiredEmpty" &&
        this.changeSurveyData("skippedRequiredEmpty", "stepStatus");

      status === "mixedrequired" &&
        this.changeSurveyData("skipped", "stepStatus");
      status === "actionRequired" && this.changeSurveyData("", "stepStatus");

      (status === "completed" || status === "empty") &&
        this.changeSurveyData("", "stepStatus");
      this.changeSurveyData(newStepData.data, "submissions");
      this.changeSurveyData(popconfirms, "popconfirms");
      this.changeSurveyData(validationFields, "validation");
      this.changeSurveyData(limits, "limits");
      const lastEdited = this.dataToSave?.[0]?._id?.$oid
        ? newStepData.data?.find(
            (v: DataSheetRecord) =>
              v?._id?.$oid === this.dataToSave?.[0]?._id?.$oid
          )
        : newStepData.data?.[0];
      this.changeSurveyData(
        !this.currentStepInfo?.multiline_form_flag
          ? [
              { ...lastEdited, sheet_type: "edited" } || {
                sheet_type: "new",
              },
            ]
          : [],
        "dataToSave"
      );
      isStepChanged && this.changeCurrentStep(newStep!);
      this.changeSurveySubmitting(false);
    });
  }

  async addSingleRecord(
    record: DataSheetRecord,
    dataSheet: DataSheet,
    schemaToSave: SheetSchema[]
  ) {
    const { notes, fieldNotes, files, filesList } = record;
    const data: any = await Auth.currentSession();
    const username = data.accessToken.payload.username;
    const sheetName = dataSheet?.sheet_name;
    const sheetId = dataSheet?._id.$oid;
    const values = record[dataSheet.sheet_name];

    await Promise.all(
      schemaToSave?.map(async (item) => {
        if (
          item?.metadata_entity_name &&
          this.surveyInfo.defaultMetaDataSheetId?.length
        ) {
          values[item.entity_name] =
            this.surveyInfo?.defaultMetadataRecord?.[
              item?.metadata_entity_name
            ];
          if (item.unit_id) {
            values[item.entity_name + "_unit_id"] = item.unit_id;
          }
        } else {
          if (!item.unit_id) return;

          if (!item.entity_name) return;

          if (values && values.hasOwnProperty(item.entity_name + "_unit_id")) {
            values[item.entity_name + "_actual_unit_id"] =
              values[item.entity_name + "_unit_id"];
            values[item.entity_name + "_actual_value"] =
              values[item.entity_name];

            // Correct handling for '0' values
            if (values[item.entity_name + "_unit_id"]) {
              if (
                values[item.entity_name] === null ||
                values[item.entity_name] === undefined ||
                values[item.entity_name] === ""
              ) {
                values[item.entity_name] = ""; // Only set to an empty string if truly empty
              } else {
                // Ensure '0' is retained and only non-zero values are converted
                values[item.entity_name] = await doConversion(
                  Number(values[item.entity_name]), // Use Number conversion to keep '0'
                  values[item.entity_name + "_unit_id"],
                  item.unit_id,
                  item.customConversions
                );
              }
            }

            if (
              values[item.entity_name + "_unit_id"] === null ||
              values[item.entity_name + "_unit_id"] === undefined ||
              values[item.entity_name + "_unit_id"] === ""
            ) {
              values[item.entity_name + "_actual_unit_id"] = item.unit_id;
            }

            values[item.entity_name + "_unit_id"] = item.unit_id;
          }
        }
      })
    );

    const newPopconfirms = handleCheckSingleValidation(
      schemaToSave,
      values,
      this.currentStepData?.popconfirms?.filter(
        (popconfirm) => popconfirm.recordId === record?.key
      ),
      this.currentStepData?.limits
    );
    const surveyDataArray: any[] = [];

    const alerts: RecordAlerts[] = [] as RecordAlerts[];
    newPopconfirms?.map((item) =>
      alerts?.push({
        field: item.name,
        alert_type: "warning",
        alert_desc: `The ${item.name
          .split("_")
          .join(" ")} is above the variance threshold. ${item.reason}`,
        warning_word: item.warning_word,
      })
    );
    const newRecord = { ...values };
    if (alerts?.length > 0) newRecord.alerts = alerts;
    if (notes?.length) newRecord.notes = notes;
    if (fieldNotes?.length > 0) newRecord.field_notes = fieldNotes;

    const payload = {
      source: "Survey",
      sheet_id: sheetId,
      sheet_name: sheetName,
      username,
      filesList: filesList || [],
      review_status: RecordStatus.IN_PROGRESS,
      data_survey_id: this.surveyId,
      ...(!!this.taskDeploymentId?.length && {
        taskDeploymentId: this.taskDeploymentId,
      }),
      ...(this.taskDeployment?.assignedGroup && {
        owner_groups: [this.taskDeployment?.assignedGroup],
      }),
      documents: [newRecord],
    };
    const savedRecord = await insertData(payload);
    const getOidObj = savedRecord[0];
    if (getOidObj?.$oid) {
      surveyDataArray.push({
        id: getOidObj?.$oid,
        payload: payload,
        type: "new",
        sheetName: sheetName,
      });
      if (files?.length) {
        for (const file of files) await uploadSingleDataSheetFile(file);
      }
    }
  }

  async updateSingleRecord(
    updatedRecord: any,
    dataSheet: DataSheet,
    schemaToSave: SheetSchema[]
  ) {
    if (!updatedRecord) return Promise.reject();
    const sheetName = dataSheet["sheet_name"];
    const values = updatedRecord[sheetName];
    /*
    console.log('values before data transformations')
    console.log(values);
    */

    await Promise.all(
      schemaToSave?.map(async (item: SheetSchema) => {
        if (
          item?.metadata_entity_name &&
          this.surveyInfo.defaultMetaDataSheetId?.length
        ) {
          values[item.entity_name] = updatedRecord
            ? updatedRecord[sheetName][item.entity_name]
            : this.surveyInfo.defaultMetadataRecord![
                item?.metadata_entity_name
              ];
          if (item.unit_id) {
            values[item.entity_name + "_unit_id"] = updatedRecord
              ? updatedRecord[sheetName][item.entity_name + "_unit_id"]
              : item.unit_id;
          }
        } else {
          if (!item.unit_id) return;
          values[item.entity_name + "_actual_unit_id"] =
            values[item.entity_name + "_unit_id"];
          values[item.entity_name + "_actual_value"] = values[item.entity_name];
          if (values[item.entity_name + "_unit_id"]) {
            // need to handle 0 as a valid number only set to empty string if null or undefined
            if (!values[item.entity_name] && values[item.entity_name] != 0) {
              //console.log('item entity name is null')
              // set to blank string
              values[item.entity_name] = "";
            } else {
              // this is what sets things to 0
              values[item.entity_name] = await doConversion(
                Number(values[item.entity_name] || 0),
                values[item.entity_name + "_unit_id"],
                item.unit_id,
                item.customConversions
              );
            }
          }
          if (
            values[item.entity_name + "_unit_id"] === null &&
            values[item.entity_name + "_unit_id"] === undefined &&
            values[item.entity_name + "_unit_id"] === ""
          ) {
            values[item.entity_name + "_actual_unit_id"] = item.unit_id;
          }
          values[item.entity_name + "_unit_id"] = item.unit_id;
        }
      })
    );
    /*
    console.log('valuesl after data transformations')
    console.log(values);
    */

    const newPopconfirms = handleCheckSingleValidation(
      schemaToSave,
      values,
      this.currentStepData?.popconfirms?.filter(
        (popconfirm) => popconfirm?.recordId === updatedRecord?._id?.$oid
      ),
      this.currentStepData?.limits
    );
    const alerts: RecordAlerts[] = [] as RecordAlerts[];
    newPopconfirms?.map((item) =>
      alerts?.push({
        field: item.name,
        alert_type: "warning",
        alert_desc: `The ${item.name
          .split("_")
          .join(" ")} is above the variance threshold. ${item.reason}`,
        warning_word: item.warning_word,
      })
    );
    updatedRecord.alerts = alerts;
    delete updatedRecord.popconfirms;
    delete updatedRecord.files;
    const surveyDataArray: any[] = [];
    const payload = {
      ...updatedRecord,
      _id: updatedRecord._id,
      revision: updatedRecord.revision,
      sheet_id: updatedRecord.sheet_id,
      sheet_type: dataSheet.sheet_type,
      sheet_name: sheetName,
      ...(updatedRecord?.notes?.length && { notes: updatedRecord.notes }),
      ...(updatedRecord?.fieldNotes?.length && {
        field_notes: updatedRecord.fieldNotes,
      }),
      ...(updatedRecord.statusReasons?.length && {
        statusReasons: updatedRecord.statusReasons,
      }),
      ...(!updatedRecord.import_id && {
        review_status:
          updatedRecord?.review_status === RecordStatus.APPROVED
            ? updatedRecord?.review_status
            : RecordStatus.IN_PROGRESS,
      }),
      data_survey_id: this.surveyId,
      [sheetName]: values,
    };
    await updateData(payload);
    if (updatedRecord.sheet_id) {
      surveyDataArray.push({
        id: updatedRecord._id.$oid,
        payload: payload,
        type: "update",
        sheetName: sheetName,
      });
    }
  }

  async saveMultilineRecords() {
    if (!this.currentStepData.dataToSave) return;
    const requiredFields = this.schemaToSave
      ?.filter((item) => !item.metadata_entity_name && item.required)
      ?.map((item) => item.entity_name);
    const filteredRecords = this.currentStepData?.dataToSave?.filter(
      (item) => !item?.import_id && !item.isLocked
    );
    const isEmptyFields = filteredRecords?.find((item) => {
      return Object.entries(item[item.sheet_name]).find(([key, value]) => {
        return !!requiredFields?.includes(key) && !value && value !== 0;
      });
    });

    if (isEmptyFields) {
      runInAction(() => {
        this.changeSurveyData("error", "fieldsStatus");
      });
    }

    const isRequiredNote = this.currentStepData?.dataToSave?.find((v) =>
      v.field_notes
        ?.filter((v) =>
          this.dataSheet?.sheet_schema?.find(
            (item) =>
              item.entity_name === v.field ||
              item.entity_name + "_unit_id" === v.field
          )
        )
        ?.some((item) => item.isAddedByAdmin && !item.isAcknowledged)
    );
    if (isRequiredNote) {
      runInAction(() => {
        this.changeSurveyData("error", "fieldsStatus");
      });
    }
    const data: any = await Auth.currentSession();
    const username = data.accessToken.payload.username;
    const newDataToSave = await Promise.all(
      this.currentStepData.dataToSave?.map(async (row) => {
        const newRow = row;
        const newValues = newRow[this.dataSheet?.sheet_name];

        // Iterate over schema fields to process data
        for (const item of this.dataSheet?.sheet_schema || []) {
          if (
            item?.metadata_entity_name &&
            this.surveyInfo.defaultMetaDataSheetId?.length
          ) {
            // Set default metadata values if defined
            newValues[item.entity_name] =
              this.surveyInfo?.defaultMetadataRecord![
                item?.metadata_entity_name
              ];
            if (item.unit_id) {
              newValues[item.entity_name + "_unit_id"] = item.unit_id;
            }
          } else {
            if (item.unit_id) {
              // Initialize missing fields to avoid null/undefined issues
              if (!newValues[item.entity_name + "_actual_unit_id"]) {
                newValues[item.entity_name + "_actual_unit_id"] = item.unit_id;
              }
              if (!newValues[item.entity_name + "_actual_value"]) {
                newValues[item.entity_name + "_actual_value"] =
                  newValues[item.entity_name] || "";
              }
              if (!newValues[item.entity_name + "_unit_id"]) {
                newValues[item.entity_name + "_unit_id"] = item.unit_id;
              }

              // Validate required fields before attempting conversion
              if (!newValues[item.entity_name + "_unit_id"] || !item.unit_id) {
                console.warn(
                  `Missing unit conversion data for: ${item.entity_name}`
                );
                continue;
              }

              // Debug: Log before conversion
              console.log(`Before conversion for ${item.entity_name}:`, {
                actualValue: newValues[item.entity_name + "_actual_value"],
                entityValue: newValues[item.entity_name],
                actualUnitId: newValues[item.entity_name + "_actual_unit_id"],
                targetUnitId: item.unit_id,
              });

              // Perform unit conversion if actual value exists
              if (
                newValues[item.entity_name + "_actual_value"] !== undefined &&
                newValues[item.entity_name + "_actual_value"] !== null &&
                newValues[item.entity_name + "_actual_value"] !== ""
              ) {
                newValues[item.entity_name] = await doConversion(
                  Number(newValues[item.entity_name + "_actual_value"]),
                  newValues[item.entity_name + "_actual_unit_id"] ||
                    newValues[item.entity_name + "_unit_id"],
                  item.unit_id,
                  item.customConversions
                );
              } else if (
                newValues[item.entity_name] !== undefined &&
                newValues[item.entity_name] !== null &&
                newValues[item.entity_name] !== ""
              ) {
                newValues[item.entity_name] = await doConversion(
                  Number(newValues[item.entity_name]),
                  newValues[item.entity_name + "_actual_unit_id"] ||
                    newValues[item.entity_name + "_unit_id"],
                  item.unit_id,
                  item.customConversions
                );
              } else {
                newValues[item.entity_name] = ""; // Default to empty string if no value
              }

              // Debug: Log after conversion
              console.log(`After conversion for ${item.entity_name}:`, {
                convertedValue: newValues[item.entity_name],
              });
            }
          }
        }

        // Generate alerts for any variance issues
        const alerts: RecordAlerts[] = [] as RecordAlerts[];
        if (this.currentStepData?.limits?.length) {
          const newPopconfirms = handleCheckSingleValidation(
            this.schemaToSave,
            newValues,
            this.currentStepData?.popconfirms?.filter(
              (v) =>
                v.recordId === newRow?._id?.$oid || v.recordId === newRow.key
            ),
            this.currentStepData?.limits
          );

          // Push alerts for warnings
          newPopconfirms?.map((item) =>
            alerts?.push({
              field: item.name,
              alert_type: "warning",
              alert_desc: `The ${item.name
                .split("_")
                .join(" ")} is above the variance threshold. ${item.reason}`,
              warning_word: item.warning_word,
            })
          );
        }

        // Return the processed row with alerts
        return {
          ...newRow,
          alerts: alerts,
          [this.dataSheet?.sheet_name]: newValues,
        };
      })
    );

    console.log("Final processed data:", newDataToSave);

    if (newDataToSave?.filter((v) => v.sheet_type === "new")?.length) {
      const sheetName = this.dataSheet?.sheet_name;
      const sheetId = this.dataSheet?._id?.$oid;
      const newRecordsData = newDataToSave
        ?.filter((v) => v.sheet_type === "new")
        .map((row) => {
          const newRow = row;
          const newValues = newRow[this.dataSheet?.sheet_name];
          const alerts = newRow.alerts;
          const result = {
            ...newValues,
            alerts,
            ...(newRow.notes?.length && {
              notes: newRow.notes,
            }),
            ...(newRow.field_notes?.length && {
              field_notes: newRow.field_notes,
            }),
            ...(newRow.filesList?.length && {
              filesList: newRow.filesList,
            }),
          };

          return { ...newRow, values: result };
        });
      const newRecords = newRecordsData?.map((v) => {
        return {
          ...v?.values,
          key: v?.key,
        };
      });
      const newRecordsPayload = {
        sheet_id: sheetId,
        sheet_name: sheetName,
        username: username,
        review_status: RecordStatus.IN_PROGRESS,
        data_survey_id: this.surveyId,
        ...(!!this.taskDeploymentId?.length && {
          taskDeploymentId: this.taskDeploymentId,
        }),
        ...(this.taskDeployment?.assignedGroup && {
          owner_groups: [this.taskDeployment?.assignedGroup],
        }),
        documents: newRecords,
        source: "Survey",
      };
      const savedRecords = await insertData(newRecordsPayload);

      savedRecords.ids.forEach(
        async (record: { $oid: string; key: string }) => {
          const getRecord = newDataToSave.find((v) => {
            if (v?.key === record.key) {
              return v;
            }
          });
          if (getRecord?.files?.length) {
            for (const file of getRecord?.files)
              await uploadSingleDataSheetFile(file);
          }
        }
      );
    }
    if (newDataToSave?.filter((v) => v.sheet_type === "edited")?.length) {
      const newEditedData = newDataToSave?.filter(
        (v) => v.sheet_type === "edited"
      );
      for (const record of newEditedData) {
        const newRow = record;
        if (!newRow.import_status) {
          newRow.review_status =
            newRow.review_status === RecordStatus.APPROVED
              ? newRow.review_status
              : RecordStatus.IN_PROGRESS;
        }
        newRow.data_survey_id = this.surveyId;
        const files = record?.files || [];
        delete newRow?.files;

        await updateData({ ...newRow, sheet_type: this.dataSheet?.sheet_type });
        if (newRow?.sheet_id) {
          if (files?.length) {
            for (const file of files) await uploadSingleDataSheetFile(file);
          }
        } else {
          message.error("Error while updating record!");
        }
      }
    }
  }

  async saveKPIsEntries(reviewStatus?: RecordStatus) {
    let payloadUpdates = [];
    try {
      this.changeSurveySubmitting(true);
      const data: any = await Auth.currentSession();
      const username = data.accessToken.payload.username;
      const userName = await getFirstAndLastNameByUsername(username);
      const dataToSave = (this.dataToSave as SurveyKPI[])?.filter((item) =>
        item?._id
          ? item.type === "edited" ||
            (item.reviewStatus === RecordStatus.IN_PROGRESS &&
              reviewStatus === RecordStatus.PENDING)
          : item
      );
      let newRecords: SurveyKPI[] = [];
      const updatedRecords: SurveyKPI[] = [];
      let newFilesId: any[] = [];
      const newFiles: any[] = [];
      if (dataToSave?.length) {
        for (const surveyData of dataToSave) {
          const localFiles: any[] = [];
          const item = { ...surveyData };
          const currentKPI = (this.surveySteps[0] as KPISurveyStep).data?.find(
            (kpi: KPI) => kpi?._id.$oid === item?.mainKpiId
          );
          const uploadedFiles = item.uploadedFiles?.map((file: File) => {
            newFiles.push(file);
            localFiles.push(file);
            return {
              name: file.name,
              fileId: uuidv4(),
              userId: username,
              userName,
              mainKpiId: item.mainKpiId,
            };
          });
          const mainKPISheetId = surveyData.mainKPISheetId;
          uploadedFiles?.length && newFilesId.push(...uploadedFiles);
          const type = item.type || "";
          delete item.mainKPISheetId;
          delete item.name;
          delete item.uploadedFiles;
          delete item.type;

          let processedNewFiles = [];
          if (reviewStatus === RecordStatus.PENDING) {
            processedNewFiles = await this.processUpdaload(localFiles);
          }

          const metaDataSheet = await getSheetById(mainKPISheetId);

          const responseData = await this.fetchSurveyData();

          // Filter kpiData based on the condition
          const kpiData = responseData.filter(
            // (item) => item[item.sheet_name]?.kpi_name === metaDataSheet[metaDataSheet.sheet_name]?.kpi_name
            (data) => data?._id.$oid === item.mainKpiId
          );

          // Extract filesList from kpiData if it exists
          let filesList = processedNewFiles.map((file) => file.file_id);

          kpiData.forEach((item) => {
            if (item.filesList && Array.isArray(item.filesList)) {
              filesList.push(...item.filesList);
            }
          });

          const currentSurveyData = {
            ...item,
            surveyId: this.surveyId,
            username,
            mainKpiId: currentKPI?._id.$oid,
            reviewStatus:
              item?._id?.$oid &&
              type !== "edited" &&
              item.reviewStatus !== RecordStatus.IN_PROGRESS
                ? item.reviewStatus
                : !uploadedFiles?.length && reviewStatus
                ? reviewStatus
                : RecordStatus.IN_PROGRESS,
            ...(this.taskDeploymentId && {
              taskDeploymentId: this.taskDeploymentId,
            }),
            enteredValue: item.enteredValue,
            filesList,
            files:
              item?.files?.map((v) => {
                delete v.name;
                return v;
              }) || [],
            ...(reviewStatus === RecordStatus.PENDING && {
              filesSourceKpiId: getFilesSourceKpiId(
                surveyData,
                mainKPISheetId,
                this.dataToSave as SurveyKPI[]
              ),
            }),
          } as SurveyKPI;

          let payloadItem = {
            _id: { $oid: item.mainKpiId },
            sheet_id: metaDataSheet._id,
            sheet_name: metaDataSheet.sheet_name,
            group_id: metaDataSheet.group,
            username: username,
            revision: 1,
            last_modified: metaDataSheet.last_modified
              ? metaDataSheet.last_modified
              : metaDataSheet.create_date,
            owner_users: [username],
            filesList,
            data_survey_id: this.surveyId,
            review_status: RecordStatus.PENDING,
            [metaDataSheet.sheet_name]: {
              ...metaDataSheet[metaDataSheet.sheet_name],
              enteredValue: item.enteredValue ? item.enteredValue : "",
            },
            alerts: [],
            files:
              item?.files?.map((v) => {
                delete v.name;
                return v;
              }) || [],
            notes: item?.notes?.map((v) => ({
              ...v,
              key: v.key || uuidv4(),
            })),
            ...(reviewStatus === RecordStatus.PENDING && {
              filesSourceKpiId: getFilesSourceKpiId(
                surveyData,
                mainKPISheetId,
                this.dataToSave as SurveyKPI[]
              ),
            }),
            sheet_type: metaDataSheet.sheet_type
              ? metaDataSheet.sheet_type
              : "Custom",
          };

          payloadUpdates.push(payloadItem);

          currentSurveyData?._id
            ? updatedRecords.push(currentSurveyData)
            : newRecords.push(currentSurveyData);
        }
      }

      let res: any = null;
      if (updatedRecords?.length)
        res = await surveyKpiService.updateSurveyKpi(updatedRecords);
      if (newRecords?.length) {
        res = await surveyKpiService.addSurveyKpi(newRecords);
        res &&
          (newRecords = newRecords.map((record, index) => ({
            ...record,
            _id: res?.[index],
          })));
      }
      if (newRecords?.length) {
        newRecords = newRecords.map((record, index) => ({
          ...record,
          ...(reviewStatus === RecordStatus.PENDING && {
            filesSourceKpiId: getFilesSourceKpiId(
              record,
              (this.dataToSave as SurveyKPI[])[index]?.mainKPISheetId,
              (this.dataToSave as SurveyKPI[])?.map((v, i) => ({
                ...v,
                _id: newRecords[i]._id,
              }))
            ),
          }),
        }));
        await surveyKpiService.updateSurveyKpi(newRecords);
      }

      const surveyKpisList = newRecords?.length ? newRecords : updatedRecords;
      let dataToUpdate: SurveyKPI[] = surveyKpisList;
      if (newFiles?.length) {
        let processedNewFiles: any[] = newFiles;

        dataToUpdate = await this.createNewImports(
          newFilesId,
          processedNewFiles,
          surveyKpisList,
          dataToUpdate,
          reviewStatus || RecordStatus.IN_PROGRESS
        );
      }

      if (reviewStatus === RecordStatus.PENDING) {
        dataToUpdate = await this.processFilesToUpdate(dataToUpdate);
      }

      this.changeSurveySubmitting(false);
      await surveyKpiService.updateSurveyKpi(dataToUpdate);

      const newStepsData: KPISurveyStep[] = await this.loadKPIsSurveyData();

      runInAction(() => {
        this.surveySteps = newStepsData;
      });

      payloadUpdates.forEach(async (payloadItem) => {
        await updateData(payloadItem);
      });

      message.success("Successfully saved");
    } catch (err) {
      console.log(err);
      message.error("Something went wrong while saving data");
      return Promise.reject();
    } finally {
      this.changeSurveySubmitting(false);
    }
  }

  async processUpdaload(dataToUpdate: any[]) {
    for (const file of dataToUpdate) {
      const CurrentDate = moment().unix();
      const fileName =
        CurrentDate + "-" + file.name?.replace(/[/\\$#@?%*:|"<>]/g, "");

      const signedURLNew = await uploadDataSheetFileNew(fileName);
      const parsedsignedURLNew = JSON.parse(signedURLNew);

      file.url = parsedsignedURLNew.url;
      file.file_id = parsedsignedURLNew.file_id;

      const arrayBufferData = await file.arrayBuffer();
      if (arrayBufferData) {
        const blob = new Blob([new Uint8Array(arrayBufferData)], {
          type: file.type,
        });
        const result = await fetch(parsedsignedURLNew?.url, {
          method: "PUT",
          body: blob,
        });
      }
    }

    return dataToUpdate;
  }

  async createNewImports(
    newFilesId: any[],
    newFiles: any[],
    surveyKpisList: SurveyKPI[],
    dataToUpdate: SurveyKPI[],
    reviewStatus: RecordStatus
  ) {
    let newFilesWithId: any[] = [];
    for (const file of newFilesId) {
      const uploadedFile = newFiles?.find((v) => v.name === file.name);
      const surveyKpi = surveyKpisList.find(
        (v) => v.mainKpiId === file.mainKpiId
      );
      if (file?.name?.endsWith(".xlsx")) {
        const kpi = (this.surveySteps[0] as KPISurveyStep)?.data?.find(
          (v) => v._id.$oid === file.mainKpiId
        );
        const importPayload: {
          import_name: string;
          import_status: string;
          import_type: string;
          sheet_id: string | undefined;
          file_id: string;
          filesList: string[];
          data_id: string | undefined;
          createDate: moment.Moment;
          surveyKpiId: string | undefined;
          surveyId: string;
          mainKpiId: string | undefined;
          taskDeploymentId?: string;
          importCategory: ImportCategory;
        } = {
          import_name: `Import for KPI ${kpi?.name} ${moment().format(
            "YYYY-MM-DD HH:mm:ss A"
          )}`,
          import_status: "NOT_STARTED",
          import_type: uploadedFile.type,
          sheet_id: kpi?.sheetId,
          file_id: "",
          filesList: [],
          data_id: file?.fileId,
          createDate: moment(),
          surveyKpiId: surveyKpi?._id?.$oid,
          surveyId: this.surveyId,
          mainKpiId: surveyKpi?.mainKpiId,
          ...(this.taskDeploymentId && {
            taskDeploymentId: this.taskDeploymentId,
          }),
          importCategory: ImportCategory.SINGLE_DATASHEET,
        };
        const currentFile = newFilesId.find((v) => v.fileId === file.fileId);
        const newFile = {
          ...currentFile,
          isExcel: true,
          importName: importPayload.import_name,
          importStatus: "NOT_STARTED",
        };
        newFilesWithId.push(newFile);
      } else {
        try {
          await uploadDataLakeFile(uploadedFile, file.fileId);
          const currentFile = newFilesId.find((v) => v.fileId === file.fileId);
          newFilesWithId.push(currentFile);
        } catch (e) {
          console.log("error", e);
        }
      }
    }
    const updatedData = dataToUpdate?.map((item) => {
      const itemFilesArray = newFilesId?.filter(
        (v) => v.mainKpiId === item.mainKpiId
      );
      return {
        ...item,
        reviewStatus,
        files: [
          ...(item?.files || []),
          ...(itemFilesArray?.map((file: any) => {
            const newFile = newFilesWithId?.find(
              (v) => v.fileId === file.fileId
            );
            delete file.mainKpiId;
            return newFile
              ? {
                  ...file,
                  ...(newFile?.importId && { importId: newFile?.importId }),
                  ...(newFile?.importStatus && {
                    importStatus: newFile?.importStatus,
                  }),
                  ...(newFile?.importName && {
                    importName: newFile?.importName,
                  }),
                }
              : file;
          }) || []),
        ],
      };
    });
    return updatedData;
  }

  async processFilesToUpdate(dataToUpdate: SurveyKPI[]) {
    const updatedRecords: SurveyKPI[] = [];
    for (const record of dataToUpdate) {
      const mappingFiles = record?.files?.filter(
        (v) =>
          !v.isAdmin &&
          v.importId &&
          v.importStatus === "NOT_STARTED" &&
          !v.automapping_status
      );
      const kpi = (this.surveySteps[0] as KPISurveyStep)?.data?.find(
        (v) => v._id.$oid === record.mainKpiId
      );
      const dataSheet = await getSheetById(kpi?.sheetId);
      let recordFiles = record?.files;
      if (mappingFiles?.length) {
        for (const file of mappingFiles) {
          const result = await getFilesById(
            file.fileId,
            "dataSheetImport",
            "DATASHEET_LIST"
          );
          const fileName = path.basename(
            result?.replace(/['"]+/g, "")?.replace(/^\[(.+)\]$/, "$1")
          );
          const response: any = await API.graphql({
            query: queries["datalakeImportData"],
            variables: {
              request_type: "PROCESS_FILE",
              import_id: file.importId,
              version: 1,
              sheet_id: kpi?.sheetId,
              sheet_name: dataSheet.sheet_name,
              file_name: fileName,
            },
          });
          const res = JSON.parse(response["data"]["datalakeImportData"]);
          if (res) {
            const isError = res?.statusCode >= 400;
            if (res.import_document || isError) {
              recordFiles = recordFiles?.map((v) =>
                v.fileId === file.fileId
                  ? {
                      ...file,
                      automapping_status:
                        res?.import_document?.automapping_status ||
                        (isError && "FAILED"),
                      importStatus:
                        res.import_document?.import_status || file.importStatus,
                    }
                  : v
              );
            }
          }
        }
      }
      updatedRecords.push({ ...record, files: recordFiles });
      continue;
    }
    return updatedRecords;
  }

  changeSurveyData(value: any, key: string) {
    const newData = this.surveySteps.map((surveyStep, index) =>
      index === this.currentStep
        ? { ...surveyStep, [key]: value }
        : { ...surveyStep }
    );
    this.surveySteps = newData;
  }
  changeSurveyInfo(value: any, key: string) {
    this.surveyInfo = { ...this.surveyInfo, [key]: value };
  }

  changeCurrentStep(step: number) {
    this.currentStep = step;
  }

  changeSurveyAutosaving(isAutosave: boolean) {
    this.isAutosave = isAutosave;
  }
  changeSurveyLoading(loading: boolean) {
    this.surveyLoading = loading;
  }

  changeSurveySubmitting(loading: boolean) {
    this.surveySubmitting = loading;
  }

  changeTaskDeploymentId(id: string) {
    this.taskDeploymentId = id;
  }

  changeTaskDeployment(newData: SurveyDeployment) {
    this.taskDeployment = newData;
  }
}

const surveyStore = new SurveyStore();

export { surveyStore };
