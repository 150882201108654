import * as queries from '../graphql/queries';

import { API, Auth } from 'aws-amplify';

import {AuthContext} from '../contexts/AuthContext';
import {permissions} from "../shared/constants";
import {useContext} from 'react';

export const useCheckRolesPermissions = () => {
  const {auth} = useContext(AuthContext);

  const refreshToken = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      
      cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
        checkLockModules()
      });
    } catch (e) {
        console.log('Unable to refresh Token', e);
    }
  } 


  const checkLockModules = async() => {
    try{          
      const data = await Auth.currentSession();      
      const group = data["accessToken"]["payload"]["cognito:groups"].filter(
        (element) => element.includes("org:")
      )[0];
      const response = await API.graphql({ query: queries["getClientSettings"], variables: { group }})
      localStorage.setItem("modules",response?.data?.getClientSettings?.modules); 
      return response?.data?.getClientSettings?.modules
    }catch(error){
      console.log("Error while load client Settings data",error);
    }
    
  }

  const checkRolesPermission = (permissionRoles) => {   
    let isPermissionAllowed = false;
    if(auth?.roles){
      Array.isArray(permissionRoles) && permissionRoles.every((role)=>{
        const isRoleExist = auth?.roles?.includes(role);
        if(isRoleExist){
            isPermissionAllowed = true;
            return false;
        }
        return true;
      });
      return isPermissionAllowed;
    }
    return false;
  }



  const isEditButtonVisible = () => {    
    let hasEditPermission = false;
    if (auth?.groups) {
      Array.isArray(auth.groups) && auth.groups.every((group) => {
        const isGroupAllowed = group === "org:ultragenyx.com";
        if (isGroupAllowed) {
          hasEditPermission = true;
          return false; 
        }
        return true; 
      });
    }
    return hasEditPermission;
  };

  
  const checkGroupsPermission = (permissionGroups) => {    
  let isPermissionAllowed = false;
  if(auth?.groups){
    Array.isArray(permissionGroups) && permissionGroups.every((group)=>{
      const isGroupExist = auth?.groups?.includes(group);
      if(isGroupExist){
          isPermissionAllowed = true;
          return false;
      }
      return true;
    });
    return isPermissionAllowed;
  }
  return true;
}
  
  const checkPermissions = (permissionKey) => {
    const permissionKeyData = permissions[permissionKey];
    let isPermissionAllowed = false;
    if(permissionKeyData && (auth?.roles && auth?.roles.length) && (auth?.groups && auth?.groups.length)){
      const permissionKeyRoles = permissionKeyData["roles"];
      const permissionKeyGroups = permissionKeyData["orgs"];  
      const isRolePermissionAllowed = checkRolesPermission(permissionKeyRoles);
      isPermissionAllowed = isRolePermissionAllowed;
      if(permissionKeyGroups?.length && isPermissionAllowed){
        const isGroupPermissionAllowed = checkGroupsPermission(permissionKeyGroups);
        isPermissionAllowed = isGroupPermissionAllowed;
      }
      return isPermissionAllowed;
    }
    return false;  
  }  
  return {checkRolesPermission,checkPermissions, checkLockModules, refreshToken,isEditButtonVisible};
} 
