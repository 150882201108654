import React, { useState, useEffect } from "react";
import {
  message,
  Space,
  Dropdown,
  Menu,
  Switch,
  Row,
  Card,
  Typography,
  Col,
  Tag,
  Pagination,
  Spin,
  Checkbox,
} from "antd";
import {
  EllipsisOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  SendOutlined,
  UnorderedListOutlined,
  AppstoreOutlined,
  CopyOutlined,
  BellOutlined,
} from "@ant-design/icons";

import {
  getSurveys,
  getSurveyMetrics,
  getSurveyById,
} from "../../../services/surveys";
import { useCheckRolesPermissions } from "../../../hooks/useCheckRolesPermissions";
import {
  CommonTable,
  StyledButton,
  TableStyledMenu,
  FormWrapper,
  CardsWrap,
  StyledTag,
  TableHeaderRightTabWrap,
} from "../../../shared/commonStyles";

import {
  getFilteredTaskDeployments,
  getTaskDeploymentsByEmail,
} from "./../../../services/taskDeployment";
import { useAuthenticator } from "@aws-amplify/ui-react";
import DeployProgressBar from "./DeployProgressBar";
import styles from "./SurveysDataTable.module.scss";
import { getSheetById } from "../../../services/dataSheet";
import { getCompanyInfo } from "../../../services/companyInfo";
import { getModifiedForMetaData } from "../../../services/dataSheet";
import SearchableColumn from "../../../components/SearchableColumn";
import { getTaskColor } from "../../viewSurveyForAdmin/Components/SurveyFunctions";
import { getUserGroupById, getUserGroups } from "../../../services/users";
import { stripHtmlTags } from "../../../shared/commonFunctions";
const { Title, Link } = Typography;

const SurveysDataTable = ({
  refreshAllSurveyData,
  handleEditSurvey,
  handleDuplicateSurvey,
  handleRemindUsers,
  handleDeleteSurvey,
  handleSurveyStatus,
  setLoading,
  loading,
  handleDeploySurvey,
  handleViewUserFlow,
  setSelectedRowKeys,
  selectedRowKeys,
}) => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [toggleListCardView, setToggleListCardView] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [dataSource, setDataSource] = useState([]);
  const [surveyMatrics, setSurveyMatrics] = useState(null);
  const [tableFilters, setTableFilters] = useState({
    filteredInfo: null,
    sortedInfo: null,
  });
  const [nameFilter, setNameFilter] = useState([]);
  const [descriptionFilter, setDescriptionFilter] = useState([]);
  const [entityFilter, setEntityFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState([]);
  const [replyEmail, setReplyEmail] = useState("");
  const [displayedData, setDisplayedData] = useState([]);

  const { checkRolesPermission, checkPermissions } = useCheckRolesPermissions();

  const loadCompanyInfo = async () => {
    try {
      const companyInfo = await getCompanyInfo();
      if (companyInfo) {
        const companyData = JSON.parse(companyInfo?.["data"]);
        setReplyEmail(companyData?.reply_to_email || "");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadCompanyInfo();
  }, []);

  const getSurveyType = (type) => {
    switch (type) {
      case "standards":
        return "Standards";
      case "data_sheets":
        return "Data Collection";
      case "kpi":
        return "KPI";
      default:
        return "Mixed";
    }
  };

  const isUser =
    checkRolesPermission(["role:user"]) ||
    checkRolesPermission(["role:elevateduser"]);

  const menu = (record) => (
    <TableStyledMenu style={{ padding: "0" }}>
      <Menu.Item
        key={record.id + 1}
        onClick={() => handleEditSurvey(record)}
        style={{ backgroundColor: "transparent", padding: "0" }}
      >
        <StyledButton
          disabled={record.status === "INACTIVE"}
          type="default"
          style={{
            width: "100%",
            margin: "0",
            padding: "11px 18px",
            textAlign: "left",
          }}
          icon={<EditOutlined />}
        >
          Edit
        </StyledButton>
      </Menu.Item>
      <Menu.Item
        key={record.id + 3}
        onClick={() => handleRemindUsers(record)}
        style={{ backgroundColor: "transparent", padding: "0" }}
      >
        <StyledButton
          type="default"
          style={{
            width: "100%",
            margin: "0",
            padding: "11px 18px",
            textAlign: "left",
          }}
          icon={<BellOutlined />}
        >
          Remind Users
        </StyledButton>
      </Menu.Item>
      <Menu.Item
        key={record.id + 3}
        onClick={() => handleDuplicateSurvey(record, dataSource)}
        style={{ backgroundColor: "transparent", padding: "0" }}
      >
        <StyledButton
          type="default"
          style={{
            width: "100%",
            margin: "0",
            padding: "11px 18px",
            textAlign: "left",
          }}
          icon={<CopyOutlined />}
        >
          Duplicate Survey
        </StyledButton>
      </Menu.Item>
      <Menu.Item
        key={record.id + 3}
        onClick={() => handleDeploySurvey(record)}
        style={{ backgroundColor: "transparent", padding: "0" }}
      >
        <StyledButton
          type="default"
          style={{
            width: "100%",
            margin: "0",
            padding: "11px 18px",
            textAlign: "left",
          }}
          icon={<SendOutlined />}
        >
          Deploy Survey
        </StyledButton>
      </Menu.Item>

      <Menu.Item
        key={record.id + 2}
        onClick={() => handleDeleteSurvey(record)}
        style={{ backgroundColor: "transparent", padding: "0" }}
      >
        <StyledButton
          type="default"
          style={{
            width: "100%",
            margin: "0",
            padding: "11px 18px",
            textAlign: "left",
          }}
          icon={<DeleteOutlined />}
        >
          Delete
        </StyledButton>
      </Menu.Item>
    </TableStyledMenu>
  );

  const getEntityName = async (sheetId, entityId) => {
    const payload = {
      skip: 0,
      limit: 10,
      filter: {
        sheet_id: sheetId,
      },
    };
    const metaDataRecord = await getModifiedForMetaData(payload);
    const dataSheet = await getSheetById(sheetId);
    const identifierField =
      dataSheet.sheet_schema.find((v) => v.isIdentifier).entity_name || "";
    if (metaDataRecord) {
      const records = metaDataRecord[metaDataRecord?.sheet_name];
      const filteredRecordValue = records?.find(
        (item) => item.id === entityId
      )?.[identifierField];

      return filteredRecordValue;
    }
  };
  const loadTasksData = async () => {
    try {
      setLoading(true);
      const userGroups = await getUserGroups({
        filter: { group_users: { $regex: user.attributes.sub, $options: "i" } },
      });
      const taskResponse = await getFilteredTaskDeployments(
        user?.attributes?.email,
        userGroups
      );
      const taskSurveys = await Promise.all(
        taskResponse.map(async (task) => {
          const newTask = task;
          const survey = await getSurveyById(task.surveyManagementID);
          if (task.assignedGroup) {
            const assignedGroupData = await getUserGroupById(
              task.assignedGroup
            );
            newTask.assignedGroupName = assignedGroupData.name;
          }

          if (survey) {
            if (task.entityId) {
              const entityName = await getEntityName(
                survey.defaultMetaDataSheetId,
                task.entityId
              );
              newTask.entityName = entityName;
            }
            return {
              ...newTask,
              ...survey,
              id: newTask.id,
              name: survey.name,
              description: newTask.description || survey.description,
              status: newTask.status,
              surveyStatus: survey.status,
            };
          } else return null;
        })
      );

      const dataTable = taskSurveys
        .filter((v) => v?.surveyStatus === "ACTIVE")
        .map((v) => {
          const { surveyStatus, ...rest } = v;
          return rest;
        });

      setPagination({ ...pagination, total: dataTable.length });
      setDataSource(dataTable);
    } catch (e) {
      console.log(e);
      message.error("Failed to load data");
    } finally {
      setLoading(false);
    }
  };

  const loadData = async () => {
    try {
      setLoading(true);
      const response = await getSurveys();
      const surveyResponse = await Promise.all(
        response?.map(async (item) => {
          const taskResponse = await getTaskDeploymentsByEmail(
            user?.attributes?.email,
            item.id
          );

          return {
            ...item,
            key: item.id,
            isSurveyAccessible: taskResponse?.items?.length > 0 ? true : false,
          };
        })
      );

      // filter record?.status deleted case insensitive
      const surveyData = surveyResponse.filter(
        (record) => record?.status.toString().toLowerCase() !== "deleted"
      );

      setPagination({ ...pagination, total: response?.length });
      setDataSource(surveyData);
    } catch (e) {
      console.log(e);
      message.error("Failed to load data");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (isUser) {
      user && loadTasksData();
    } else {
      user && loadData();
    }
  }, [refreshAllSurveyData, user]);

  const handleSurveyMetrics = async () => {
    const metrics = await getSurveyMetrics();
    setSurveyMatrics(JSON.parse(metrics));
  };

  useEffect(() => {
    if (dataSource.length > 0) {
      handleSurveyMetrics();
      const nameFilter = dataSource.reduce((arr, val) => {
        if ("name" in val && !arr.includes(val["name"])) {
          arr.push(val["name"]);
        }
        return arr;
      }, []);
      setNameFilter(nameFilter);

      const descriptionFilter = dataSource.reduce((arr, val) => {
        if (
          "description" in val &&
          !arr.includes(val["description"]) &&
          val["description"]
        ) {
          arr.push(val["description"]);
        }
        return arr;
      }, []);
      setDescriptionFilter(descriptionFilter);
      const typeFilter = dataSource.reduce((arr, val) => {
        if ("survey_type" in val && !arr.includes(val["survey_type"])) {
          arr.push(val["survey_type"]);
        }
        return arr;
      }, []);
      setTypeFilter(typeFilter);
      const entityFilter = dataSource.reduce((arr, val) => {
        if ("entityName" in val && !arr.includes(val["entityName"])) {
          arr.push(val["entityName"]);
        }
        return arr;
      }, []);
      setEntityFilter(entityFilter);
      const statusFilter = dataSource.reduce((arr, val) => {
        if ("status" in val && !arr.includes(val["status"])) {
          arr.push(val["status"]);
        }
        return arr;
      }, []);

      setStatusFilter(statusFilter);
    }
  }, [dataSource]);

  useEffect(() => {
    if (!dataSource.length) {
      setDisplayedData([]);
      return;
    }
    const { current, pageSize } = pagination;
    const startIndex = (current - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setDisplayedData(dataSource.slice(startIndex, endIndex));
  }, [dataSource]);

  const columns = [
    {
      title: () => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Checkbox
            checked={
              displayedData.length > 0 &&
              displayedData.every((item) => selectedRowKeys.includes(item.id))
            }
            onChange={(e) => {
              const currentPageIds = displayedData.map((item) => item.id);
              if (e.target.checked) {
                setSelectedRowKeys((prev) => [
                  ...new Set([...prev, ...currentPageIds]),
                ]);
              } else {
                setSelectedRowKeys((prev) =>
                  prev.filter((id) => !currentPageIds.includes(id))
                );
              }
            }}
          />
          <span>Select All</span>
        </div>
      ),
      dataIndex: "select",
      width: "10%",
      render: (_, record) => (
        <Checkbox
          checked={selectedRowKeys.includes(record.id)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedRowKeys((prev) => [...prev, record.id]);
            } else {
              setSelectedRowKeys((prev) =>
                prev.filter((id) => id !== record.id)
              );
            }
          }}
        />
      ),
    },
    SearchableColumn({
      title: "Survey Name",
      dataIndex: "name",
      key: "name",
      width: 300,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      sortOrder:
        tableFilters.sortedInfo?.field === "name" &&
        tableFilters.sortedInfo?.order,
      searchPlaceHolder: "Search survey name",
      filteredValue: tableFilters.filteredInfo?.name
        ? tableFilters.filteredInfo.name
        : null,
    }),
    SearchableColumn({
      title: "Survey Description",
      dataIndex: "description",
      width: 300,
      filteredValue: tableFilters.filteredInfo?.description
        ? tableFilters.filteredInfo.description
        : null,
      searchPlaceHolder: "Search survey description",

      sorter: (a, b) => a.description.localeCompare(b.description),
      sortDirections: ["descend", "ascend"],
      render: (description) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
            width: "300px",
            whiteSpace: "nowrap",
          }}
        >
          <div
            style={{
              marginTop: "20px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {stripHtmlTags(description)}
          </div>
        </div>
      ),
    }),
  ];
  isUser
    ? columns.push({
        title: "Assigned Group",
        dataIndex: "assignedGroupName",
        width: 150,
      })
    : columns.push({
        title: "Type",
        dataIndex: "survey_type",
        width: 150,
        filteredValue: tableFilters.filteredInfo?.survey_type
          ? tableFilters.filteredInfo.survey_type
          : null,

        sorter: (a, b) => a.survey_type.localeCompare(b.survey_type),
        sortDirections: ["descend", "ascend"],
        filters: typeFilter.map((fil) => ({
          text:
            fil === "standards"
              ? "Standards"
              : fil === "data_sheets"
              ? "Data Collection"
              : fil === "kpi"
              ? "KPI"
              : "Mixed",
          value: fil,
        })),
        onFilter: (value, record) => record?.survey_type?.indexOf(value) === 0,
        sortOrder:
          tableFilters.sortedInfo?.field === "survey_type" &&
          tableFilters.sortedInfo?.order,
        render: (type) => {
          return getSurveyType(type);
        },
      });
  columns.push({
    title: "Status",
    dataIndex: "status",
    width: 100,
    sorter: (a, b) => (a?.status || "")?.localeCompare(b?.status || ""),
    filters: statusFilter.map((fil) => ({
      text: fil === "Contacted" ? "Not started" : fil,
      value: fil,
    })),
    onFilter: (value, record) => record?.status?.indexOf(value) === 0,
    filteredValue: tableFilters.filteredInfo?.status
      ? tableFilters.filteredInfo.status
      : null,
    sortOrder:
      tableFilters.sortedInfo?.field === "status" &&
      tableFilters.sortedInfo?.order,
    render: (status, rowData) => {
      const recordStatus = status === "Contacted" ? "Not started" : status;
      return (
        <Space>
          {isUser ? (
            <StyledTag bgcolor={getTaskColor(recordStatus)}>
              {recordStatus}
            </StyledTag>
          ) : (
            <>
              <Switch
                onChange={(checked) => handleSurveyStatus(checked, rowData)}
                checked={status === "ACTIVE" ? true : false}
              />
              <div style={{ display: "inline-block", marginLeft: "4px" }}>
                {rowData.status === "ACTIVE" ? "Active" : "Inactive"}
              </div>
            </>
          )}
        </Space>
      );
    },
  });
  {
    isUser
      ? columns.push({
          title: "Entity",
          dataIndex: "entityName",
          width: 200,
          sorter: (a, b) =>
            (a?.entityName || "")?.localeCompare(b?.entityName || ""),
          filters: entityFilter.map((fil) => ({ text: fil, value: fil })),
          onFilter: (value, record) => record?.entityName?.indexOf(value) === 0,
          filteredValue: tableFilters.filteredInfo?.entityName
            ? tableFilters.filteredInfo.entityName
            : null,
          sortOrder:
            tableFilters.sortedInfo?.field === "entityName" &&
            tableFilters.sortedInfo?.order,
        })
      : columns.push({
          title: "Progress",
          dataIndex: "progress",
          width: 200,
          render: (_, record) => {
            return <DeployProgressBar surveyId={record?.id} />;
          },
        });
  }
  columns.push({
    title: "Actions",
    key: "operation",
    fixed: "right",
    width: 60,
    //className: "actionsRightBorder",
    render: (_, record) => (
      <Space direction="horizontal">
        <StyledButton
          type="default"
          onClick={() => handleViewUserFlow(record)}
          icon={<EyeOutlined />}
        >
          {isUser && "Open survey"}
        </StyledButton>
        {checkPermissions(["manage-collect-data"]) &&
          !checkRolesPermission(["role:elevateduser"]) && (
            <Dropdown overlay={() => menu(record)} trigger={["click"]}>
              <StyledButton type="default" icon={<EllipsisOutlined />} />
            </Dropdown>
          )}
      </Space>
    ),
  });

  const handleDeleteSurveyChange = (ids) => {
    handleDeleteSurvey({ ids, count: ids.length });
  };
  const handleTableChange = (pagination, filters, sorter, extra) => {
    handlePageChange(pagination.current, pagination.pageSize);
    setTableFilters({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
    const allFilteredSortedRows = extra.currentDataSource || [];

    const { current, pageSize } = pagination;
    const startIndex = (current - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const currentPageRows = allFilteredSortedRows.slice(startIndex, endIndex);

    setDisplayedData(currentPageRows);
  };

  const locale = {
    emptyText:
      checkPermissions(["manage-collect-data"]) &&
      !checkRolesPermission(["role:elevateduser"]) ? (
        <Col span={24} style={{ paddingTop: "100px", paddingBottom: "100px" }}>
          <Row justify="center">
            <Title level={3} style={{ color: "#B1AFBC", fontWeight: "normal" }}>
              There are no data collection requests.
            </Title>
          </Row>
          <Row justify="center">
            <Title level={4} style={{ color: "#B1AFBC", fontWeight: "normal" }}>
              Click on the button on the top right to create your first data
              sheet or refine your search criteria.
            </Title>
          </Row>
        </Col>
      ) : isUser ? (
        <Col span={24} style={{ paddingTop: "100px", paddingBottom: "100px" }}>
          <Row justify="center">
            <Title level={3} style={{ color: "#B1AFBC", fontWeight: "normal" }}>
              Sorry, you do not have any surveys assigned to you.
            </Title>
          </Row>

          <Row justify="center">
            <Title level={4} style={{ color: "#B1AFBC", fontWeight: "normal" }}>
              {replyEmail?.length ? (
                <>
                  Please contact your administrator at:{" "}
                  <Link
                    style={{ color: "#a68dfb" }}
                    href={`mailto:${replyEmail}`}
                  >
                    {replyEmail}
                  </Link>
                </>
              ) : (
                "Please contact your administrator."
              )}
            </Title>
          </Row>
        </Col>
      ) : null,
  };

  const getPaginatedData = () => {
    const startIndex = (pagination.current - 1) * pagination.pageSize;
    const endIndex = startIndex + pagination.pageSize;
    return dataSource.slice(startIndex, endIndex);
  };

  const handlePageChange = (page, pageSize) => {
    setPagination({ current: page, pageSize });
  };

  return (
    <>
      <TableHeaderRightTabWrap
        style={{ justifyContent: "end", marginBottom: "10px" }}
      >
        <StyledButton
          type="default"
          bgcolor={toggleListCardView && "#7F5FEE"}
          style={{
            borderRadius: "0",
            marginRight: "0",
            padding: "5px 18px",
          }}
          icon={<UnorderedListOutlined />}
          onClick={() => setToggleListCardView(true)}
        >
          List View
        </StyledButton>
        <StyledButton
          type="default"
          bgcolor={!toggleListCardView && "#7F5FEE"}
          style={{
            borderRadius: "0",
            marginLeft: "0",
            padding: "5px 18px",
          }}
          icon={<AppstoreOutlined />}
          onClick={() => setToggleListCardView(false)}
        >
          Card View
        </StyledButton>
      </TableHeaderRightTabWrap>
      <FormWrapper>
        {!isUser && (
          <Row gutter={[16, 18]} style={{ marginTop: 20, marginBottom: 20 }}>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Card
                bordered={false}
                style={{
                  backgroundColor: "#2d273f",
                  borderLeft: "4px solid #77f4fc",
                  padding: "20px",
                  textAlign: "center",
                }}
              >
                <Title level={4}>Total Surveys</Title>
                <Title level={2} style={{ marginTop: "0" }}>
                  {dataSource?.length}
                </Title>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Card
                bordered={false}
                style={{
                  backgroundColor: "#2d273f",
                  borderLeft: "4px solid #fcc777",
                  padding: "20px",
                  textAlign: "center",
                }}
              >
                <Title level={4}>Completed</Title>
                <Title level={2} style={{ marginTop: "0" }}>
                  {surveyMatrics?.completed_survey_count || 0}
                </Title>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Card
                bordered={false}
                style={{
                  backgroundColor: "#2d273f",
                  borderLeft: "4px solid #7794fc",
                  padding: "20px",
                  textAlign: "center",
                }}
              >
                <Title level={4}>Paused Surveys</Title>
                <Title level={2} style={{ marginTop: "0" }}>
                  {surveyMatrics?.paused_survey_count
                    ? surveyMatrics?.paused_survey_count
                    : 0}
                </Title>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Card
                bordered={false}
                style={{
                  backgroundColor: "#2d273f",
                  borderLeft: "4px solid #77fca4",
                  padding: "20px",
                  textAlign: "center",
                }}
              >
                <Title level={4}>Total Deployments</Title>
                <Title level={2} style={{ marginTop: "0" }}>
                  {surveyMatrics?.total_assigned_users
                    ? surveyMatrics?.total_assigned_users
                    : 0}
                </Title>
              </Card>
            </Col>
          </Row>
        )}

        {toggleListCardView && (
          <CommonTable
            rowKey={(data) => {
              return data?.id;
            }}
            loading={loading}
            dataSource={dataSource}
            columns={columns}
            scroll={{ x: "max-content" }}
            pagination={!!dataSource?.length && pagination}
            onChange={handleTableChange}
            className={styles.table}
            locale={locale}
            footer={() =>
              selectedRowKeys.length > 0 && (
                <div style={{ marginLeft: "-16px" }}>
                  <StyledButton
                    type="default"
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeleteSurveyChange(selectedRowKeys)}
                    bgcolor="#A68DFB"
                  >
                    Delete Surveys ({selectedRowKeys.length})
                  </StyledButton>
                </div>
              )
            }
          />
        )}
        <div className={toggleListCardView && styles.cardView}>
          {!dataSource.length ? (
            locale.emptyText
          ) : (
            <Spin spinning={loading}>
              <CardsWrap>
                <Row className={styles.cardWrap} gutter={[16, 16]}>
                  {getPaginatedData().map((record) => (
                    <Col key={record.id} xs={24} sm={24} md={12} lg={6}>
                      <div className={styles.cardBox}>
                        <Row className={styles.cardTitle}>
                          <div>{record.name}</div>
                        </Row>
                        <div>
                          {isUser && (
                            <Row className={styles.cardTitle}>
                              <span>{record.entityName}</span>
                            </Row>
                          )}
                          <Row className={styles.cardDescription}>
                            {checkRolesPermission(["role:user"]) ? (
                              <StyledTag bgcolor={getTaskColor(record.status)}>
                                {record.status}
                              </StyledTag>
                            ) : (
                              <Tag color={"#A68DFB"} className="tag-rounded">
                                {getSurveyType(record.survey_type)}
                              </Tag>
                            )}
                          </Row>
                          {isUser && (
                            <Row className={styles.cardDescription}>
                              <span>{record.metadataIdentifier || " "}</span>
                            </Row>
                          )}
                          <Row className={styles.cardDescription}>
                            <span>{record.description || " "}</span>
                          </Row>
                        </div>
                        <div className={styles.cardButtons}>
                          <StyledButton
                            type="default"
                            className={styles.cardButton}
                            onClick={() => handleViewUserFlow(record)}
                            icon={<EyeOutlined />}
                          >
                            Open survey
                          </StyledButton>
                          {checkPermissions(["manage-collect-data"]) &&
                            !checkRolesPermission(["role:elevateduser"]) && (
                              <Dropdown
                                overlay={() => menu(record)}
                                trigger={["click"]}
                              >
                                <StyledButton
                                  style={{
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                    flexGrow: "8",
                                  }}
                                  type="default"
                                  icon={<EllipsisOutlined />}
                                />
                              </Dropdown>
                            )}
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </CardsWrap>
              <Row justify="center">
                <Pagination
                  current={pagination.current}
                  total={dataSource.length}
                  pageSize={pagination.pageSize}
                  showSizeChanger
                  onChange={handlePageChange}
                />
              </Row>
            </Spin>
          )}
        </div>
      </FormWrapper>
    </>
  );
};

export default SurveysDataTable;
