import React from "react";
import { FormWrapper, StyledDivider } from "../../../shared/commonStyles";
import { Row, Col, Typography, Form, Input, Checkbox } from "antd";

interface GeneralInfoProps {
  initialValues?: any;
  sheetNames?: string[];
}

export const GeneralInfo = ({
  initialValues,
  sheetNames,
}: GeneralInfoProps) => {
  return (
    <FormWrapper>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Typography.Title level={3}>General information</Typography.Title>
          <Typography.Title level={4}>Name new KPI data sheet</Typography.Title>
          <StyledDivider />
          <Form.Item
            labelCol={{ span: 24 }}
            label="Name"
            name="sheet_name"
            rules={[
              {
                required: true,
                pattern: new RegExp("^[a-zA-Z0-9 ()]*$"),
                message:
                  "Please input unique sheet name! The name cannot include characters '.', '$'",
                validator: (_, value) => {
                  try {
                    if (
                      sheetNames?.some((v) => v === initialValues?.kpi_name)
                    ) {
                      throw new Error("Sheet name is already exist!");
                    } else {
                      return Promise.resolve(true);
                    }
                  } catch (err) {
                    throw new Error("Sheet name is required!");
                  }
                },
              },
            ]}
          >
            <Input placeholder="Enter name" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Typography.Title level={4}>Add description</Typography.Title>
          <StyledDivider />
          <Form.Item
            labelCol={{ span: 24 }}
            name="description"
            label="Description"
          >
            <Input.TextArea rows={4} placeholder="Enter description" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="enable_comments" valuePropName="checked">
            <Checkbox>Enable comments</Checkbox>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="enable_change_history" valuePropName="checked">
            <Checkbox>Enable change history</Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </FormWrapper>
  );
};
