import * as queries from "../../graphql/queries";
import { Col, Form, Progress, Row, Space, Spin, Steps, message } from "antd";
import {
  PageWrap,
  StyledButton,
  StyledSteps,
} from "../../shared/commonStyles";
import React, { useEffect, useState } from "react";
import {
  getDataSheets,
  getSheetById,
  listMetaDataRecords,
  makeQueryMetadata,
  metadataSheetTypesList,
  is_reference_sheet,
  uploadOCRFile,
  makeQuery,
  fetchRecordData,
} from "../../services/dataSheet";
import {
  fetchDataWithRetry,
  getImportDataById,
  getJobExecutionStatus,
  insertImportData,
  removeImportData,
} from "../../services/importData";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { API } from "aws-amplify";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Step1 from "./components/step1";
import Step2 from "./components/step2";
import Step3 from "./components/step3";
import { Step3PDF } from "./components/step3PDF";
import Step4 from "./components/step4";
import Step5 from "./components/step5";
import { checkPermission } from "../../services/permissions";
import {
  downloadDataSheetImportFileHandler,
  encodeUrlName,
} from "../../shared/commonFunctions";
import { getFilteredDataSheetColumns } from "./common/utils.js";
import { getSurveyById } from "../../services/surveys";
import moment from "moment";
import { produce } from "immer";
import { useCheckRolesPermissions } from "../../hooks/useCheckRolesPermissions";
import { observer } from "mobx-react-lite";
import { userInfoStore } from "../Carbon/UserInfoStore";

const { Step } = Steps;

const ImportNewData = observer(() => {
  const { checkRolesPermission } = useCheckRolesPermissions();
  const [step, setStep] = useState(checkRolesPermission(["role:user"]) ? 2 : 1);
  const [loadingImportData, setLoadingImportData] = useState(false);
  const [dataSheetsList, setDataSheetsList] = useState(null);
  const [surveyData, setSurveyData] = useState(null);
  const [initialValues, setInitialValues] = useState({
    source_file_type: "structured_file",
  });
  const [identifierValues, setIdentifierValues] = useState();
  const [identifierColumn, setIdentifierColumn] = useState();
  const [isPdfUploading, setIsPdfUploading] = useState(false);
  const [isPdfUploadFailed, setIsPdfUploadFailed] = useState(false);
  const [files, setFiles] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [dataSheet, setDataSheet] = useState({});
  const [selectedOcrProfile, setSelectedOcrProfile] = useState();
  const [promptData, setPromptData] = useState();
  const [uploadStatuses, setUploadStatuses] = useState([]);

  const navigate = useNavigate();
  const { import_id: importId } = useParams();
  const { state } = useLocation();
  const [form] = Form.useForm();

  const fileType = form.getFieldValue("source_file_type");

  useEffect(() => {
    const loadData = async () => {
      const payload = {
        filter: {
          archive: false,
          sheet_type: {
            $nin: metadataSheetTypesList,
          },
        },
      };
      const sheets = await getDataSheets(payload);
      setDataSheetsList(sheets);
    };
    loadData();
    userInfoStore.fetchUserInfo();
  }, []);

  const fetchSurveyData = async (surveyId) => {
    const sdata = await getSurveyById(surveyId);
    setSurveyData(sdata);
    const newInitValue = {
      ...initialValues,
      sheet_id: state?.dataSheet?._id?.$oid,
      import_name: sdata?.name.concat(" " + moment().format("YYYY-MM-DD HH:mm:ss A")),
      description: sdata?.description,
    };
    setInitialValues(newInitValue);
    form.setFieldsValue(newInitValue);
    setStep(2);
  };

  function selectOCRProfile(profile) {
    setSelectedOcrProfile(profile);
  }

  useEffect(() => {
    if (state?.type === "survey" && state?.surveyId && state?.dataSheet) {
      fetchSurveyData(state?.surveyId);
    }
    if ((state?.type === "dataSheet" || state?.type === "pendingKpi") && state?.dataSheet) {
      const newInitValue = {
        ...initialValues,
        sheet_id: state?.dataSheet?._id?.$oid,
        import_name:
          state?.type === "pendingKpi"
            ? `Import for KPI ${state?.kpiData?.mainKPIName} ${moment().format("YYYY-MM-DD HH:mm:ss A")}`
            : state?.dataSheet?.sheet_name.concat(
                " " + moment().format("YYYY-MM-DD HH:mm:ss A")
              ),
        description: state?.dataSheet?.description,
        source_file_type: state?.isOcrUpload ? "ocr_file" : "structured_file",
      };
      setInitialValues(newInitValue);
      form.setFieldsValue(newInitValue);
      if (state?.type === "pendingKpi" && state?.currentStep) {
        setStep(state?.currentStep);
        setFiles([state?.kpiFile]);
      } else {
        setStep(2);
      }
    }
  }, [state]);

  useEffect(() => {
    const loadSheetData = async () => {
      if (initialValues?.sheet_id) {
        const ds = await getSheetById(initialValues?.sheet_id);
        setDataSheet(ds);
      }
    };
    loadSheetData();
  }, [initialValues?.sheet_id]);

  useEffect(() => {
    if (!importId) return;
    const loadImportData = async () => {
      try {
        setLoadingImportData(true);
        const _import_data = await getImportDataById(importId);
        const import_type_list = {
          "image/png": "ocr_file",
          "image/jpg": "ocr_file",
          "image/jpeg": "ocr_file",
          "image/JPG": "ocr_file",
          "application/pdf": "ocr_file",
          "text/csv": "structured_file",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "structured_file",
        };
        let import_type = "";
        Object.keys(import_type_list).forEach((key) => {
          if (key === _import_data?.import_type) {
            import_type = import_type_list[key];
          }
        });
        const initValues = { ..._import_data, source_file_type: import_type };
        form.setFieldsValue(initValues);
        setInitialValues(initValues);
        const sheetData = await getSheetById(_import_data?.sheet_id);
        if (sheetData && state?.type !== "pendingKpi") {
          const sheetObj = sheetData?.sheet_schema;
          const _headers = [];
          sheetObj?.forEach((item) => {
            _headers.push({ item: item.entity_name });
          });
          setHeaders(_headers);
        }
        if (importId && state?.action === "review") {
          setStep(4);
        }
      } catch (error) {
        console.log("load error : ", error);
      } finally {
        setLoadingImportData(false);
      }
    };
    loadImportData();
  }, [importId, form, state?.action, state?.type]);

  const onChangeFieldsData = (data) => {
    if (data?.[0]?.name[0]) {
      setInitialValues((oldState) => ({
        ...oldState,
        [data?.[0]?.name[0]]: data?.[0]?.value,
      }));
    }
  };

  const onClickPrevious = () => {
    setStep(step - 1);
  };

  const isMetadata = is_reference_sheet(dataSheet);

  const handleGetIdentifierColumn = async () => {
    try {
      const metadataSheet = await getSheetById(dataSheet?.metadata_sheet_id);
      const identifier = metadataSheet?.sheet_schema?.find((v) => v.isIdentifier)?.entity_name;
      if (identifier) {
        const payload = {
          permissionType: "REFERENCE_DATA",
          itemId: dataSheet?.metadata_sheet_id,
        };
        const permissions = await checkPermission(payload);
        const payloadMetadata = makeQueryMetadata({
          sheetId: dataSheet?.metadata_sheet_id,
          pagination: { current: 1, pageSize: 1000 },
          ...(!!permissions?.length && {
            filter: { [`${metadataSheet.sheet_name}.id`]: { $in: permissions } },
          }),
        });
        const metaDataDocuments = await listMetaDataRecords(payloadMetadata);
        const identifierValues =
          metaDataDocuments?.data?.map((item) => item[item.sheet_name][identifier]) || [];
        const identifierField = dataSheet?.sheet_schema?.find(
          (item) => item?.metadata_entity_name === identifier
        )?.entity_name;
        setIdentifierValues(identifierValues);
        setIdentifierColumn(identifierField);
      }
    } catch (e) {
      message.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (dataSheet && (!importId || state?.action === "kpiMapping")) {
      handleGetIdentifierColumn();
    }
  }, [dataSheet]);

  useEffect(() => {
    if (dataSheet && fileType === "ocr_file") {
      const filteredDataSheetColumns = getFilteredDataSheetColumns(
        dataSheet,
        identifierColumn,
        state
      );
      const initialPromptState = filteredDataSheetColumns?.map((item, index) => {
        return {
          id: index,
          key: item.display_name,
          value: "",
        };
      });
      setPromptData(initialPromptState);
    }
  }, [dataSheet, fileType, identifierColumn, state]);

  const onRestartUploading = async (isUndo) => {
    setFiles([]);
    const payload = {
      _id: initialValues["_id"],
      sheet_name: dataSheet.sheet_name,
      is_metadata: isMetadata,
    };
    await removeImportData(payload);
    if (isUndo) {
      navigate(`/data/import-data`);
    } else {
      setInitialValues((prev) => {
        const newData = {
          ...prev,
          import_name: state?.dataSheet?.sheet_name.concat(
            " " + moment().format("YYYY-MM-DD HH:mm:ss A")
          ),
        };
        delete newData._id;
        return newData;
      });
      setStep(2);
    }
  };

  const handleBack = () => {
    if (state?.type === "dataSheet") {
      dataSheet?.sheet_name &&
        navigate(
          `/data/data-sheets/${initialValues?.sheet_id}/${encodeUrlName(
            dataSheet?.sheet_name?.replace("/", " ")
          )}/documents`
        );
    } else if (state?.type === "survey") {
      navigate(
        `/management/collect-data/${surveyData?.id}/${encodeUrlName(
          surveyData?.name.replace("/", " ")
        )}/view-survey`,
        {
          state: {
            currentStep: state?.currentStep,
            ...(state?.taskDeploymentId && { taskDeploymentId: state?.taskDeploymentId }),
            ...(state?.defaultMetaDataRecord && {
              metadataRecordId: state?.defaultMetaDataRecord.id,
            }),
          },
        }
      );
    } else if (state?.type === "pendingKpi") {
      localStorage.setItem(
        "pendingKpiImport",
        JSON.stringify({
          dataSheetId: state?.dataSheet?._id?.$oid,
          actionType: "data_imported",
          kpiId: state?.kpiData?._id?.$oid,
        })
      );
      navigate(`/data/pending-kpis`);
    } else {
      navigate("/data/import-data");
    }
  };

  const downloadFileHandler = async () => {
    try {
      const dataId = initialValues.data_id || initialValues.sheet_id;
      const response = await API.graphql({
        query: queries["dataSheetImport"],
        variables: {
          request_type: "DATASHEET_LIST",
          data_id: dataId,
        },
      });
      const filesList = response["data"]["dataSheetImport"];
      if (filesList) {
        const list = filesList.replace(/['"]+/g, "").replace(/^\[(.+)\]$/, "$1").split(",");
        const key = list[list?.length - 1];
        await downloadDataSheetImportFileHandler(key, dataId);
      }
    } catch (error) {
      console.log("Error while fetching data lake files", error);
    }
  };

  const uploadOCRBeforeStep3 = async () => {
    if (files.length === 0) {
      setStep(step + 1);
      return;
    }
    setIsPdfUploading(true);
    const statuses = [];
    try {
      let importRecordId = importId;
      let singleDataId = initialValues.data_id;
      // If we have no import record yet, create one
      if (!importRecordId) {
        const newDataId = uuidv4();
        const payloadInsert = {
          import_name: initialValues.import_name,
          import_type: files[0].type, // just the first file's type
          import_status: "PENDING_REVIEW",
          description: initialValues.description,
          sheet_id: initialValues.sheet_id,
          data_id: newDataId,
        };
        const inserted = await insertImportData(payloadInsert);
        importRecordId = inserted.$oid;
        singleDataId = newDataId;
        setInitialValues((prev) => ({
          ...prev,
          _id: { $oid: importRecordId },
          data_id: newDataId,
        }));
      }
      // Loop each file
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        try {
          const presignPayload = {
            file_name: file.name,
            file_category: "DATASHEET",
            version: 1,
            data_id: singleDataId,
          };
          const presignedUrlRes = await API.graphql({
            query: queries["dataSheetFiles"],
            variables: {
              request_type: "UPLOAD_FILE",
              request_payload: JSON.stringify(presignPayload),
              data_id: initialValues.sheet_id,
              import_id: importRecordId,
            },
          });
          const preSignedUrl = JSON.parse(presignedUrlRes.data.dataSheetFiles);
          await uploadOCRFile(preSignedUrl.url, [file]);
          const response = await API.graphql({
            query: queries.datalakeImportData_job,
            variables: {
              request_type: "PROCESS_FILE_OCR",
              import_id: importRecordId,
              import_status: "PENDING_REVIEW",
              sheet_id: state.dataSheet._id.$oid,
              sheet_name: state.dataSheet.sheet_name,
              version: 1,
              file_name: file.name,
              field_mapping: `{"ocr_profile":"${selectedOcrProfile?.profile_name}"}`,
            },
          });
          const jobMatch = response.data.datalakeImportData_job.match(/job_id=([^;\n]+)/);
          if (jobMatch && jobMatch[1]) {
            const job_id = jobMatch[1].replace(/}/g, "");
            const jobResponse = await fetchDataWithRetry(job_id);
          
            if (jobResponse.status === "SUCCESS") {
              try {
                const summary = JSON.parse(jobResponse.resultSummary);
                const messages = summary?.messages || [];
                if (
                  messages.some((m) => m.messageType === "success" && m.message.includes("successfully"))
                ) {
                  statuses.push({ fileName: file.name, status: "Success" });
                } else {
                  statuses.push({
                    fileName: file.name,
                    status: "Failed",
                    error: summary?.body || "Error returned in resultSummary",
                  });
                }
              } catch (err) {
                statuses.push({
                  fileName: file.name,
                  status: "Failed",
                  error: err.message,
                });
              }
            } else {
              statuses.push({
                fileName: file.name,
                status: "Failed",
                error: "Job not successful",
              });
            }
          } else {
            statuses.push({
              fileName: file.name,
              status: "Failed",
              error: "No job_id returned",
            });
          }
          
        } catch (err) {
          statuses.push({
            fileName: file.name,
            status: "Failed",
            error: err?.message || "An error occurred",
          });
        }
      }
    } catch (e) {
      setIsPdfUploadFailed(true);
    } finally {
      setUploadStatuses(statuses);
      setIsPdfUploading(false);
      setStep(step + 1);
    }
  };

  const onClickNext = async () => {
    try {
      await form.validateFields();
      if (step === 2 && state?.isOcrUpload) {
        await uploadOCRBeforeStep3();
      } else {
        setStep(step + 1);
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const handleNextClickOCR = () => {
    setStep(step + 1);
  };

  const onStepChange = async (selectedStep) => {
    try {
      if (step <= selectedStep) {
        await form.validateFields();
      }
      const stepForSteps = checkRolesPermission(["role:user"])
        ? selectedStep + 2
        : selectedStep + 1;
      setStep(stepForSteps);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const showSectionsAccordingSteps = (currentStep) => {
    if (currentStep === 1) {
      return (
        !checkRolesPermission(["role:user"]) && (
          <Step1
            onClickNext={onClickNext}
            onClickPrevious={handleBack}
            initialValues={initialValues}
            dataSheetsList={dataSheetsList}
            state={state}
          />
        )
      );
    } else if (currentStep === 2) {
      return (
        <Step2
          isUser={checkRolesPermission(["role:user"])}
          selectOCRProfile={selectOCRProfile}
          ocrProfile={selectedOcrProfile}
          files={files}
          setFiles={setFiles}
          onClickNext={onClickNext}
          onClickPrevious={() =>
            checkRolesPermission(["role:user"]) ? handleBack() : onClickPrevious()
          }
          initialValues={initialValues}
          state={state}
          isUploading={isPdfUploading}
          downloadFileHandler={downloadFileHandler}
        />
      );
    } else if (currentStep === 3) {
      if (state?.isOcrUpload) {
        return (
          <Step4
            files={files}
            isMetadata={isMetadata}
            onClickNext={onClickNext}
            onClickPrevious={onClickPrevious}
            onRestartUploading={onRestartUploading}
            initialValues={initialValues}
            dataSheet={dataSheet}
            state={state}
            downloadFileHandler={downloadFileHandler}
            surveyData={surveyData}
            importId={importId}
            uploadStatuses={uploadStatuses}
          />
        );
      } else {
        if (fileType === "structured_file") {
          return (
            <Step3
              files={files}
              isMetadata={isMetadata}
              headers={headers}
              setHeaders={setHeaders}
              onClickNext={onClickNext}
              onClickPrevious={onClickPrevious}
              dataSheetsList={dataSheetsList}
              identifierColumn={identifierColumn}
              initialValues={initialValues}
              setInitialValues={setInitialValues}
              dataSheet={dataSheet}
              setDataSheet={setDataSheet}
              state={state}
              currentStep={currentStep}
              identifierValues={identifierValues}
            />
          );
        } else {
          return (
            promptData &&
            promptData.length > 0 && (
              <Step3PDF
                promptData={promptData}
                onPromptValueChange={(id, value) => {
                  const updatedData = produce(promptData, (draft) => {
                    const promptToUpdate = draft.find((d) => d.id === id);
                    if (promptToUpdate) {
                      promptToUpdate.value = value;
                    }
                  });
                  setPromptData(updatedData);
                }}
                onClickPrevious={onClickPrevious}
                onClickNext={handleNextClickOCR}
              />
            )
          );
        }
      }
    } else if (currentStep === 4 && !state?.isOcrUpload) {
      return (
        <Step4
          files={files}
          isMetadata={isMetadata}
          onClickNext={onClickNext}
          onClickPrevious={onClickPrevious}
          onRestartUploading={onRestartUploading}
          initialValues={initialValues}
          dataSheet={dataSheet}
          state={state}
          downloadFileHandler={downloadFileHandler}
          surveyData={surveyData}
          uploadStatuses={uploadStatuses}
        />
      );
    } else if (currentStep === 5 && !state?.isOcrUpload) {
      return <Step5 />;
    }
  };

  return (
    <PageWrap>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Spin spinning={loadingImportData}>
          <div style={{ width: "100%", display: "flex" }}>
            <div style={{ width: "20%" }}>
              <StyledButton
                type="custom"
                onClick={handleBack}
                hovercolor="#A68DFB"
                hoverbgcolor="transparent"
                bgcolor="transparent"
                bordercolor="transparent"
                color="#fff"
              >
                <ArrowLeftOutlined /> <span>Exit</span>
              </StyledButton>
            </div>
            <div
              className="user-flow-steps-wrap color-white import-data-steps"
              style={{ width: "60%", margin: "auto 0px" }}
            >
              <StyledSteps
                current={checkRolesPermission(["role:user"]) ? step - 2 : step - 1}
                onChange={onStepChange}
              >
                {!checkRolesPermission(["role:user"]) && (
                  <Step
                    className="stepper-wrapper"
                    key={1}
                    title={<span>General information</span>}
                  />
                )}
                <Step
                  className="stepper-wrapper"
                  key={2}
                  title={<span>Data upload</span>}
                />
                {state?.isOcrUpload ? (
                  <Step
                    className="stepper-wrapper"
                    key={3}
                    title={<span>Data review</span>}
                  />
                ) : (
                  <>
                    <Step
                      className="stepper-wrapper"
                      key={3}
                      title={<span>Data mapping</span>}
                    />
                    <Step
                      className="stepper-wrapper"
                      key={4}
                      title={<span>Data review</span>}
                    />
                  </>
                )}
              </StyledSteps>
            </div>
          </div>
          <Progress percent={step * 24} showInfo={false} strokeColor={"#A68DFB"} />
          <Row>
            <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: 20 }}>
              <div className="form-wrap">
                <Form
                  form={form}
                  initialValues={initialValues}
                  onFieldsChange={(data) => onChangeFieldsData(data)}
                >
                  {showSectionsAccordingSteps(step)}
                </Form>
              </div>
            </Col>
          </Row>
        </Spin>
      </Space>
    </PageWrap>
  );
});

export default ImportNewData;
