import React, { useState } from "react";
import moment from "moment";
import { FileSearchOutlined, EyeOutlined } from "@ant-design/icons";

import { message } from "antd";
import { getFirstAndLastNameByUsername } from "../../../../shared/commonFunctions";
import {
  StyledButton,
  CommonModal,
  CommonTable,
} from "../../../../shared/commonStyles";
import { getHistoryForDataSheet } from "../../../../services/dataSheet";
import { YearMonthDayFormat } from "../../../../shared/constants";

export const HistoryDataSection = ({
  onChangeHistoryHandler,
  rowData,
  buttonText,
  badgeComponent,
  setIsOtherButtonsDisabled = () => {},
}) => {
  const [historyData, setHistoryData] = useState(null);
  const [loadingHistoryData, setLoadingHistoryData] = useState(false);
  const [visible, setVisible] = useState(false);

  const getHistoryData = async (recordId) => {
    try {
      setLoadingHistoryData(true);
      setIsOtherButtonsDisabled(true);
      const historyData = await getHistoryForDataSheet(recordId);

      if (historyData.data.length > 0) {
        const newHistoryData = await Promise.all(
          historyData.data.map(async (item) => {
            const firstLastName = await getFirstAndLastNameByUsername(
              item.username
            );
            return {
              ...item,
              first_last_name: firstLastName,
            };
          })
        );

        setHistoryData(newHistoryData);
        setVisible(true);
      }
    } catch (error) {
      message.error("Error while loading history data!");
      console.log("Error while loading history data!", error);
    } finally {
      setLoadingHistoryData(false);
      setIsOtherButtonsDisabled(false);
    }
  };

  const getHistoryDataByVersionId = (versionId) => {
    return (
      historyData && historyData.find((item) => item.revision === versionId)
    );
  };
  const onClose = () => {
    setVisible(false);
  };
  const onChangeHistoryVersion = (version) => {
    onClose();
    const historyVersionData = getHistoryDataByVersionId(version);
    onChangeHistoryHandler(rowData, historyVersionData);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "first_last_name",
      width: "23%",
      render: (_, record) => {
        return `Revision ${record.revision}: ${record.first_last_name}`;
      },
    },
    {
      title: "Date",
      dataIndex: "last_modified",
      width: "19%",
      render: (_, record) => {
        const endDate = record.last_modified?.$date
          ? moment(record.last_modified?.$date).format(YearMonthDayFormat)
          : "";
        return endDate;
      },
    },
    {
      title: "Operation",
      dataIndex: "operation",
      width: "18%",
      render: (_, record) => {
        return <>{record.operation}</>;
      },
    },    {
      title: "Source",
      dataIndex: "Source",
      width: "15%",
      render: (_, record) => {
        return record.source ? <>{record.source}</> : null;

    
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      width: "15%",
      render: (_, record) => {
        return (
          <StyledButton
            style={{ color: "#7F5FEE", borderRadius: "2px" }}
            onClick={() => onChangeHistoryVersion(record.revision)}
            icon={<EyeOutlined />}
          >
            View Changes
          </StyledButton>
        );
      },
    },
  ];

  return (
    <>
      <StyledButton
        size="small"
        type="default"
        disabled={!rowData?.revision}
        icon={<FileSearchOutlined />}
        loading={loadingHistoryData}
        onClick={() => getHistoryData(rowData?._id.$oid)}
      >
        {buttonText}
        {badgeComponent}
      </StyledButton>
      {historyData && (
        <CommonModal
          title={"Revisions History"}
          visible={visible}
          onCancel={onClose}
          width={800}
          footer={null}
          maskClosable={false}
          destroyOnClose
          centered
        >
          <CommonTable
            loading={false}
            rowKey="id"
            dataSource={historyData}
            columns={columns}
            scroll={{ x: true }}
          />
        </CommonModal>
      )}
    </>
  );
};
