import React, { useEffect, useState } from "react";
import {
  FormWrap,
  FormWrapper,
  StyledButton,
  StyledDivider,
} from "../../../shared/commonStyles";
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  InputNumber,
  Popover,
  Row,
  Space,
  Spin,
  Typography,
  Upload,
  UploadFile,
  message,
  notification,
} from "antd";
import {
  CheckCircleOutlined,
  CommentOutlined,
  DeleteOutlined,
  PaperClipOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
  WarningOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { surveyStore } from "../../../stores/SurveyStore";
import styles from "./SurveyView.module.scss";
import { useBoolean, useSize } from "ahooks";
import { useNavigate } from "react-router";
import {
  getTaskDeploymentById,
  updateTaskDeployment,
} from "../../../services/taskDeployment";
import moment from "moment";
import { SubmitConfirmationModal } from "./SubmitConfirmationModal";
import { SurveyNotesDrawer } from "./SurveyNotesDrawer";
import { userInfoStore } from "../../Carbon/UserInfoStore";

export const KPIsForm = observer(() => {
  const [form] = Form.useForm();
  const [isWarningVisible, setWarningVisible] = useState(false);
  const size = useSize(document.querySelector("body"));
  const [showInformationDrawer, setShowInformationDrawer] = useState(false);
  const navigate = useNavigate();
  const [finishMessage, setFinishMessage] = useState(false);
  const [isSubmitModal, setSubmitModal] = useState(false);
  const [isAllCommentsShown, { toggle: toggleAllComments }] = useBoolean(false);
  const [currentNote, setCurrentNote] = useState<any>(null);

  const [noteVisible, setNoteVisible] = useState(false);
  const [
    isFieldNotesDrawerShown,
    { setTrue: showFieldNotesDrawer, setFalse: hideFieldNotesDrawer },
  ] = useBoolean(false);
  const [currentData, setCurrentData] = useState<SurveyKPI>({} as SurveyKPI);

  useEffect(() => {
    if (surveyStore.surveySteps?.length) {
      if (
        (surveyStore.surveySteps[0] as KPISurveyStep)?.dataToSave?.some(
          (v) => v.note
        )
      )
        setWarningVisible(true);
      form.setFieldsValue({
        kpiEntries: (surveyStore.surveySteps[0] as KPISurveyStep).dataToSave,
      });
    }
    userInfoStore.fetchUserInfo();
  }, [surveyStore.surveySteps?.length]);

  const handleFormChange = () => {
    const fieldsValues = form.getFieldsValue(true);
    surveyStore.changeSurveyData(fieldsValues.kpiEntries, "dataToSave");
  };
  const handleSaveEnteredData = async (status?: RecordStatus) => {
    await surveyStore.saveKPIsEntries(status);
  };
  const handleChangeEditableData = (key: number) => {
    const fieldsValues = form.getFieldsValue(true);
    const newData = fieldsValues.kpiEntries?.map(
      (v: SurveyKPI, index: number) =>
        key === index ? { ...v, type: "edited" } : v
    );
    surveyStore.changeSurveyData(newData, "dataToSave");
    form.setFieldsValue({ kpiEntries: newData });
  };

  const handleUpdateTaskDeployment = async (taskStatus?: string) => {
    const currentTask = await getTaskDeploymentById(
      surveyStore.taskDeploymentId
    );
    if (currentTask) {
      const newStatus =
        currentTask.status === "Pending" || currentTask.status === "Completed"
          ? "Pending"
          : taskStatus;
      const dateFormat = "YYYY-MM-DD";
      const completedDate = moment().format(dateFormat);
      const payload = {
        id: surveyStore.taskDeploymentId,
        email: currentTask.email,
        status: newStatus,
        ...(taskStatus === "Pending" && { completion_date: completedDate }),
        ...(currentTask.assignedGroup && {
          assignedGroup: currentTask.assignedGroup,
        }),
      };
      await updateTaskDeployment(payload);
    }
  };

  const handleSubmit = async () => {
    try {
      try {
        await form.validateFields();
      } catch (error) {
        return;
      }
      const areAllFieldsAcknowledged = (
        surveyStore.dataToSave as SurveyKPI[]
      )?.every((item) =>
        item.notes?.filter((v) => v.isAddedByAdmin)?.length
          ? item.notes
              ?.filter((v) => v.isAddedByAdmin)
              .every((v) => v.isAcknowledged)
          : item
      );
      const areRequiredFieldsCompleted = (
        surveyStore.dataToSave as SurveyKPI[]
      )?.every(
        (item) =>
          !!item.files?.length ||
          !!item.notes?.filter((v) => !v.isAddedByAdmin)?.length ||
          !!item.uploadedFiles?.length
      );
      if (areRequiredFieldsCompleted && areAllFieldsAcknowledged) {
        setWarningVisible(false);
        setFinishMessage(true);
        await handleSaveEnteredData(RecordStatus.PENDING).then(async () => {
          if (
            surveyStore.taskDeploymentId &&
            !surveyStore.dataToSave?.some(
              (v) =>
                v.reviewStatus === RecordStatus.IN_PROGRESS ||
                v.reviewStatus === RecordStatus.DENIED
            )
          ) {
            await handleUpdateTaskDeployment("Pending");
          }
        });
      } else {
        message.warning(
          "Please complete all the required fields and acknowledge all admin notes in order to successfully submit the survey"
        );
        await handleSaveEnteredData(RecordStatus.IN_PROGRESS).then(() => {
          if (surveyStore.taskDeploymentId) {
            handleUpdateTaskDeployment("InProgress");
          }
          form.setFieldsValue({
            kpiEntries: (surveyStore.surveySteps[0] as KPISurveyStep)
              .dataToSave,
          });
        });

        if (!areAllFieldsAcknowledged) {
          const kpi = (surveyStore.dataToSave as SurveyKPI[])?.find((item) =>
            item.notes?.some((v) => v.isAddedByAdmin && !v.isAcknowledged)
          );
          const note = kpi?.notes?.find(
            (v) => v.isAddedByAdmin && !v.isAcknowledged
          );
          handleClickNote({
            ...note,
            isActive: true,
            kpiName: kpi?.name,
            kpiId: kpi?._id?.$oid,
          });
        }
        if (!areRequiredFieldsCompleted) {
          setWarningVisible(true);
        }
      }
    } catch (e) {
      message.error("Something went wrong");
    }
  };

  const handleNotification = () => {
    let isConfirm = true;
    const key = "exitNotification";
    const btn = (
      <Space>
        <StyledButton
          onClick={() => {
            isConfirm = false;
            notification.destroy();
            setWarningVisible(true);
          }}
        >
          Cancel
        </StyledButton>
        <StyledButton
          onClick={() => {
            notification.close(key);
          }}
        >
          Dismiss and skip
        </StyledButton>
      </Space>
    );
    notification.open({
      message: (
        <Col span={24}>
          <b>Warning:</b> Are you sure you want to leave the survey? Your data
          may not be saved.
        </Col>
      ),
      key,
      type: "warning",
      placement: "top",
      closeIcon: btn,
      className: styles.exitNotification,
      duration: 5,
      onClose: async () => {
        if (isConfirm) {
          navigate("/management/collect-data");
        }
      },
    });
  };

  const handleAutosave = () => {
    message.info("Autosaving your current progress...");
    handleSaveEnteredData()
      .then(() => {
        if (surveyStore.taskDeploymentId) {
          handleUpdateTaskDeployment("In Progress");
        }
        navigate("/management/collect-data");
      })
      .catch(() => handleNotification());
  };

  const rightPartContent = (
    <Space
      direction="vertical"
      style={{
        height: "100%",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <div>
        <Typography.Title className="color-white " level={4}>
          Overview
        </Typography.Title>

        <div
          className="color-white"
          style={{
            lineHeight: "1.5",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxHeight: "30%",
            wordWrap: "normal",
          }}
          dangerouslySetInnerHTML={{
            __html: surveyStore.surveyInfo.description,
          }}
        ></div>
      </div>

      <Row justify="start" align="middle">
        <Button type="text" onClick={() => handleAutosave()}>
          Exit
        </Button>
        <StyledButton type="custom" onClick={() => setSubmitModal(true)}>
          Submit survey
        </StyledButton>
        <Button type="text" onClick={() => handleAutosave()}>
          Save & Exit
        </Button>
      </Row>
    </Space>
  );

  const beforeUpload = (file: UploadFile, key: number) => {
    const formData: SurveyKPI[] = form.getFieldValue("kpiEntries");
    const newData = formData.map((v, index) =>
      key === index
        ? {
            ...v,
            type: "edited",
            uploadedFiles: [...(v.uploadedFiles || []), file],
          }
        : v
    );
    form.setFieldsValue({
      kpiEntries: newData,
    });
    handleFormChange();
    return false;
  };
  const handleRemoveFile = (file: UploadFile, key: number) => {
    const formData: SurveyKPI[] = form.getFieldValue("kpiEntries");
    const newData = formData.map((v, index) =>
      key === index
        ? {
            ...v,
            type: "edited",
            uploadedFiles: v.uploadedFiles?.filter((v) => v.uid !== file.uid),
          }
        : v
    );
    form.setFieldsValue({
      kpiEntries: newData,
    });
    handleFormChange();
  };

  const handleCloseNotesDrawer = () => {
    hideFieldNotesDrawer();
    setCurrentData({} as SurveyKPI);
  };

  const handleAddNote = (value: string) => {
    const formData: SurveyKPI[] = form.getFieldValue("kpiEntries");
    const newData = formData.map((v) =>
      currentData.mainKpiId === v.mainKpiId
        ? {
            ...v,
            type: "edited",
            notes: [
              ...(v.notes || []),
              {
                text: value,
                user: userInfoStore.userName,
                date: moment().format("YYYY-MM-DD HH:mm:ss"),
              },
            ],
          }
        : v
    );
    form.setFieldsValue({
      kpiEntries: newData,
    });
    handleFormChange();
    handleCloseNotesDrawer();
  };

  const getCommentsTitle = () => {
    const formData = form.getFieldValue("kpiEntries");
    const notes = formData
      ?.map((v: SurveyKPI) => {
        const notes = v.notes?.filter((v) => v.isAddedByAdmin && !v.isDeleted);
        return notes?.length
          ? notes?.map((item) => ({
              ...item,
              kpiName: v.name,
              kpiId: v._id?.$oid,
            }))
          : [];
      })
      ?.flat()?.length;
    const result = notes ? (
      <span className="text-align-center">
        <span className="color-white">You have </span>
        <span className="color-white">
          {notes + " comment" + (notes > 1 ? "s" : "")}
        </span>{" "}
      </span>
    ) : (
      <></>
    );
    return result;
  };

  const handleScrollToField = () => {
    const activeRefElement = document.getElementById("activeRef");
    const scrolledDivElement = document.querySelector(".scrolled-form");

    if (activeRefElement && scrolledDivElement) {
      const elementRect = activeRefElement.getBoundingClientRect();
      const containerRect = scrolledDivElement.getBoundingClientRect();
      const scrollOffset =
        elementRect.top -
        containerRect.top -
        (containerRect.height - elementRect.height) / 2;

      scrolledDivElement.scrollTop += scrollOffset;
    }
  };

  const handleClickNote = (note: any) => {
    setCurrentNote(note);
    handleScrollToField();
    setNoteVisible(true);
  };

  useEffect(() => {
    if (currentNote && currentNote?.isActive) {
      handleScrollToField();
    }
  }, [currentNote]);
  useEffect(() => {
    !noteVisible && setCurrentNote(null);
  }, [noteVisible]);

  const getKpisNotes = () => {
    const formData = form.getFieldValue("kpiEntries");

    const notes = formData
      ?.map((v: SurveyKPI) => {
        const notes = v.notes?.filter((v) => v.isAddedByAdmin && !v.isDeleted);
        return notes?.length
          ? notes?.map((item) => ({
              ...item,
              kpiName: v.name,
              kpiId: v._id?.$oid,
              isActive: true,
            }))
          : [];
      })
      ?.flat();
    return (
      <>
        {!!notes?.length && (
          <Row className={styles.notesBlock}>
            <Col span={24}>
              {notes?.map((note: any) => (
                <Row
                  className={styles.commentsText}
                  key={note.key}
                  onClick={() => handleClickNote(note)}
                  style={{ cursor: "pointer" }}
                >
                  <u>
                    <b>{note.kpiName}:</b> {note?.text}
                  </u>
                </Row>
              ))}
            </Col>
          </Row>
        )}
      </>
    );
  };
  const getKpisReasons = () => {
    const formData = form.getFieldValue("kpiEntries");
    const reviews = formData
      ?.map((v: SurveyKPI) =>
        v.statusReasons
          ? v.statusReasons?.map((item) => ({
              ...item,
              kpiName: v.name,
              kpiId: v._id?.$oid,
            }))
          : []
      )
      .flat();

    return (
      <>
        {!!reviews?.length && (
          <Row>
            <Col span={24} className={styles.warning}>
              {reviews
                ?.slice()
                ?.sort(
                  (a: any, b: any) =>
                    new Date(b.timestamp).getTime() -
                    new Date(a.timestamp).getTime()
                )
                .map((note: any, index: number) => (
                  <Row
                    className="color-white text-align-center"
                    justify="center"
                    key={note.key}
                  >
                    <div>
                      <b>{note?.kpiName}:</b>{" "}
                      {note.comments?.length
                        ? note.comments
                        : "No reason specified"}
                    </div>
                  </Row>
                ))}
            </Col>
          </Row>
        )}
      </>
    );
  };

  const handleAcknowledgedNote = (
    checked: boolean,
    note: Note,
    key: number
  ) => {
    const formData: SurveyKPI[] = form.getFieldValue("kpiEntries");
    const newData = formData.map((v, index) =>
      key === index
        ? {
            ...v,
            type: "edited",
            notes: v.notes?.map((v) =>
              v.key === note.key ? { ...v, isAcknowledged: checked } : v
            ),
          }
        : v
    );
    form.setFieldsValue({
      kpiEntries: newData,
    });
    handleFormChange();
  };

  const handleOpenDrawer = (key: number) => {
    showFieldNotesDrawer();
    const formData: SurveyKPI[] = form.getFieldValue("kpiEntries");
    const data = formData[key];
    setCurrentData(data);
  };

  return (
    <>
      <SubmitConfirmationModal
        visible={isSubmitModal}
        onClose={() => setSubmitModal(false)}
        onExit={() => handleAutosave()}
        onComplete={() => handleSubmit()}
      />
      <Spin spinning={surveyStore.surveySubmitting}>
        {finishMessage ? (
          <FormWrap width="500px">
            <Row justify="space-between">
              <Col lg={24} md={24} sm={24} xs={24}>
                <div style={{ textAlign: "center", justifyContent: "center" }}>
                  <CheckCircleOutlined style={{ fontSize: "40px" }} />
                  <Typography.Title className="color-white" level={4}>
                    Form data was saved successfully!
                  </Typography.Title>
                  <p>
                    <span>Form data was saved successfully!</span>
                  </p>
                  <Form.Item>
                    <Row justify="center">
                      <StyledButton
                        type="custom"
                        key={1}
                        onClick={() => navigate("/management/collect-data")}
                      >
                        Go to All Surveys
                      </StyledButton>
                    </Row>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </FormWrap>
        ) : (
          <FormWrap width="100%" style={{ padding: 0 }}>
            <SurveyNotesDrawer
              visible={isFieldNotesDrawerShown}
              notes={currentData.notes || []}
              onClose={handleCloseNotesDrawer}
              onSubmit={handleAddNote}
              name={currentData.name}
            />
            <FormWrapper>
              <Drawer
                className={`drawerCustom ${styles.drawer}`}
                onClose={() => setShowInformationDrawer(false)}
                visible={showInformationDrawer}
                placement="right"
              >
                {rightPartContent}
              </Drawer>
              <Row>
                <Col
                  span={size?.width && size?.width >= 900 ? 17 : 24}
                  style={{ padding: 10, backgroundColor: "#3C3453" }}
                  className={`preview-left-part scrolled-form ${styles.kpiForm}`}
                >
                  <div>
                    <Row justify="center">
                      <Typography.Title className="color-white" level={3}>
                        {surveyStore.surveyInfo.name}
                      </Typography.Title>
                    </Row>
                    {size && size?.width <= 900 && (
                      <Col flex={"100%"}>
                        <Button
                          style={{
                            color: " #fff",
                            borderColor: "#fff",
                            width: "100%",
                            height: "40px",
                          }}
                          type="ghost"
                          onClick={() => setShowInformationDrawer(true)}
                          className={`margin-5 ${styles.addNewButton}`}
                        >
                          <QuestionCircleOutlined /> View Information & Progress
                        </Button>
                      </Col>
                    )}
                    {surveyStore.dataToSave?.some(
                      (v) =>
                        v.statusReasons?.length ||
                        v.notes?.some((note) => note.isAddedByAdmin)
                    ) && (
                      <Row
                        justify="center"
                        align="middle"
                        className={styles.commentsBox}
                      >
                        <Col
                          className={styles.warning}
                          onClick={() => toggleAllComments()}
                        >
                          {getKpisReasons()}
                          {!!(surveyStore.dataToSave as any)
                            ?.map((v: SurveyKPI) => {
                              const notes = v.notes?.filter(
                                (v) => v.isAddedByAdmin && !v.isDeleted
                              );
                              return notes?.length
                                ? notes?.map((item) => ({
                                    ...item,
                                    kpiName: v.name,
                                    kpiId: v._id?.$oid,
                                  }))
                                : [];
                            })
                            ?.flat()?.length && (
                            <div className="text-align-center">
                              {getCommentsTitle()}
                              {isAllCommentsShown ? (
                                <UpOutlined className={styles.warning} />
                              ) : (
                                <DownOutlined className={styles.warning} />
                              )}
                            </div>
                          )}
                        </Col>
                        {isAllCommentsShown && getKpisNotes()}
                      </Row>
                    )}
                    <Form
                      form={form}
                      layout="vertical"
                      onFieldsChange={handleFormChange}
                    >
                      <Form.List name="kpiEntries" key="kpiEntries">
                        {(fields) => {
                          return (
                            <>
                              {fields.map(({ key, name, ...rest }) => {
                                const isDisabled =
                                  surveyStore.dataToSave[key]?.reviewStatus ===
                                  RecordStatus.APPROVED;

                                return (
                                  <Row
                                    key={key}
                                    className={
                                      surveyStore.dataToSave[key]?._id?.$oid ===
                                        currentNote?.kpiId &&
                                      currentNote?.isActive
                                        ? styles.fieldComment
                                        : ""
                                    }
                                  >
                                    <Col span={24}>
                                      <Form.Item
                                        name={[name, "enteredValue"]}
                                        label={
                                          <Col
                                            span={24}
                                            style={{ width: "100%" }}
                                          >
                                            <Row>
                                              <Col span={24}>
                                                {
                                                  form.getFieldValue(
                                                    "kpiEntries"
                                                  )[key].name
                                                }
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col span={24}>
                                                <StyledDivider />
                                              </Col>
                                            </Row>
                                          </Col>
                                        }
                                        rules={[
                                          {
                                            required:
                                              surveyStore.dataToSave[key]
                                                ?.required === true,
                                            message: "This KPI is required",
                                          },
                                          {
                                            validator: async (
                                              _: any,
                                              value: any
                                            ) => {
                                              if (
                                                surveyStore.dataToSave[key]
                                                  ?.required &&
                                                !value
                                              ) {
                                                return Promise.reject(
                                                  "This KPI is required"
                                                );
                                              }
                                              return Promise.resolve();
                                            },
                                          },
                                        ]}
                                      >
                                        <InputNumber
                                          disabled={isDisabled}
                                          onBlur={() =>
                                            handleChangeEditableData(key)
                                          }
                                          onPressEnter={() =>
                                            handleChangeEditableData(key)
                                          }
                                        />
                                      </Form.Item>
                                      <Row>
                                        <Col span={24}>
                                          <span className="color-grey font-14">
                                            Attach files that are related to
                                            this KPI
                                          </span>
                                        </Col>
                                      </Row>
                                      <Row justify="start" align="middle">
                                        <Upload
                                          customRequest={({
                                            file,
                                            onSuccess,
                                          }) => {
                                            onSuccess && onSuccess("ok");
                                          }}
                                          beforeUpload={(file) =>
                                            beforeUpload(file, key)
                                          }
                                          fileList={[]}
                                          className={`upload-list-inline ${
                                            size &&
                                            size?.width <= 900 &&
                                            styles.mobileView
                                          }`}
                                          listType="text"
                                          multiple
                                        >
                                          <StyledButton disabled={isDisabled}>
                                            <UploadOutlined /> Upload
                                          </StyledButton>
                                        </Upload>
                                        {isWarningVisible &&
                                          !form.getFieldValue("kpiEntries")[key]
                                            .files?.length &&
                                          !form.getFieldValue("kpiEntries")[key]
                                            .uploadedFiles?.length &&
                                          !form
                                            .getFieldValue("kpiEntries")
                                            [key].notes?.filter(
                                              (v: Note) => !v.isAddedByAdmin
                                            )?.length && (
                                            <Popover
                                              overlayClassName={`popoverContent ${styles.warningPopover}`}
                                              autoAdjustOverflow
                                              arrowPointAtCenter
                                              content={
                                                <Row justify="center">
                                                  <Col span={24}>
                                                    <Row
                                                      justify="center"
                                                      className={
                                                        styles.warningPopoverText
                                                      }
                                                    >
                                                      You have not uploaded any
                                                      supporting files for this
                                                      KPI. Please confirm your
                                                      intention or go back and
                                                      upload files and/or leave
                                                      a note
                                                    </Row>
                                                    <Row justify="center">
                                                      <StyledButton
                                                        onClick={() =>
                                                          handleOpenDrawer(key)
                                                        }
                                                      >
                                                        Add Note
                                                      </StyledButton>
                                                    </Row>
                                                  </Col>
                                                </Row>
                                              }
                                            >
                                              <WarningOutlined
                                                style={{
                                                  fontSize: 20,
                                                  marginLeft: 20,
                                                  color: "yellow",
                                                }}
                                              />
                                            </Popover>
                                          )}
                                        <CommentOutlined
                                          onClick={() => handleOpenDrawer(key)}
                                          style={{
                                            fontSize: 20,
                                            marginLeft: 20,
                                            color: "#a68dfb",
                                          }}
                                        />
                                        {!!form
                                          .getFieldValue("kpiEntries")
                                          [key].notes?.filter(
                                            (v: Note) => v.isAddedByAdmin
                                          )?.length && (
                                          <Row
                                            id={
                                              surveyStore.dataToSave[key]?._id
                                                ?.$oid === currentNote?.kpiId &&
                                              currentNote?.isActive
                                                ? "activeRef"
                                                : ""
                                            }
                                          >
                                            <Popover
                                              overlayClassName="popoverContent"
                                              visible={
                                                noteVisible &&
                                                surveyStore.dataToSave[key]?._id
                                                  ?.$oid === currentNote?.kpiId
                                              }
                                              onVisibleChange={setNoteVisible}
                                              trigger={["click"]}
                                              title={
                                                <FormWrapper>
                                                  {form
                                                    .getFieldValue("kpiEntries")
                                                    [key].notes?.filter(
                                                      (v: Note) =>
                                                        currentNote?.isActive
                                                          ? v.key ===
                                                            currentNote?.key
                                                          : v.isAddedByAdmin
                                                    )
                                                    ?.map((v: Note) => (
                                                      <div
                                                        className="margin-5"
                                                        key={v.key}
                                                      >
                                                        <Row
                                                          align="middle"
                                                          justify="space-between"
                                                        >
                                                          <Col span={18}>
                                                            <Row
                                                              className="font-16"
                                                              justify="space-between"
                                                            >
                                                              <Col className="margin-right-5">
                                                                {v.user}
                                                              </Col>
                                                              <Col>
                                                                {v.date}
                                                              </Col>
                                                            </Row>
                                                            <Row>{v?.text}</Row>
                                                          </Col>
                                                          <Col span={4}>
                                                            <Checkbox
                                                              disabled={
                                                                v?.isAcknowledged
                                                              }
                                                              checked={
                                                                v?.isAcknowledged
                                                              }
                                                              onChange={(e) => {
                                                                handleAcknowledgedNote(
                                                                  e.target
                                                                    .checked,
                                                                  v,
                                                                  key
                                                                );
                                                              }}
                                                            />
                                                          </Col>
                                                        </Row>
                                                        <StyledDivider />
                                                      </div>
                                                    ))}
                                                </FormWrapper>
                                              }
                                            >
                                              <CommentOutlined
                                                onClick={() => {
                                                  setCurrentNote({
                                                    kpiId:
                                                      surveyStore.dataToSave[
                                                        key
                                                      ]?._id?.$oid,
                                                  });
                                                  setNoteVisible(
                                                    (prev) => !prev
                                                  );
                                                }}
                                                style={{
                                                  fontSize: 20,
                                                  marginLeft: 20,
                                                }}
                                              />
                                            </Popover>
                                          </Row>
                                        )}
                                      </Row>

                                      {!!form.getFieldValue("kpiEntries")[key]
                                        ?.files?.length && (
                                        <Row>
                                          <div className="data-lake-files-wrap">
                                            {form
                                              .getFieldValue("kpiEntries")
                                              [key]?.files?.map(
                                                (item: any, index: number) => {
                                                  const fileName = item.name;
                                                  return (
                                                    <Row
                                                      key={index}
                                                      className={
                                                        styles.fileItem
                                                      }
                                                    >
                                                      <PaperClipOutlined />
                                                      {fileName}
                                                    </Row>
                                                  );
                                                }
                                              )}
                                          </div>
                                        </Row>
                                      )}
                                      {!!form.getFieldValue("kpiEntries")[key]
                                        ?.uploadedFiles?.length && (
                                        <Row>
                                          <div className="data-lake-files-wrap">
                                            {form
                                              .getFieldValue("kpiEntries")
                                              [key]?.uploadedFiles?.map(
                                                (
                                                  item: UploadFile,
                                                  index: number
                                                ) => {
                                                  const fileName = item.name;
                                                  return (
                                                    <Row
                                                      key={index}
                                                      align="middle"
                                                      className={
                                                        styles.fileItem
                                                      }
                                                    >
                                                      <PaperClipOutlined />
                                                      {fileName}
                                                      <DeleteOutlined
                                                        onClick={() =>
                                                          handleRemoveFile(
                                                            item,
                                                            key
                                                          )
                                                        }
                                                      />
                                                    </Row>
                                                  );
                                                }
                                              )}
                                          </div>
                                        </Row>
                                      )}
                                    </Col>
                                  </Row>
                                );
                              })}
                            </>
                          );
                        }}
                      </Form.List>
                    </Form>
                  </div>
                </Col>
                <Col
                  className="preview-right-part"
                  span={size?.width && size?.width >= 900 ? 7 : 0}
                >
                  {rightPartContent}
                </Col>
              </Row>
            </FormWrapper>
          </FormWrap>
        )}
      </Spin>
    </>
  );
});
